import { Maybe } from './../../types';
import ky from "ky";
import { useCactusChain } from "../hooks/ChainUtils";
import { useMultiChainUtils } from "./multiChainUtils";

const ROOT_API = process.env.REACT_APP_ROOT_BLOCKONE_API!;

const GENERATIVE_ART_ROOT_API = process.env.REACT_APP_GENERATIVE_ART_ROOT_API;

const timeout = 60000;
let apiKey: string;
const setApiKey = (newKey: string) => {
  apiKey = newKey;
};
const useGenerativeArtEndoints = () => {
  const generateAndUploadArt = async (data: string, NFTCollectionId: string, emailTo: string) => {
    const jsonData = JSON.parse(data);
    console.log("NFTCollectionId " + NFTCollectionId);
    jsonData.NFTCollectionId = NFTCollectionId;
    jsonData.emailTo = emailTo;
    console.log("jsonData.NFTCollectionId " + jsonData.NFTCollectionId);

    const endopint = "api/generative-art/generate";
    const endpointURL = `${GENERATIVE_ART_ROOT_API}${endopint}`;
    const json = await ky
      .post(endpointURL, {
        json: jsonData,
        timeout: false,
      })
      .json();
    return json;
  };

  return { generateAndUploadArt };
};
const useBlockOneEndpoint = () => {
 
  const { chainId } = useCactusChain();
  const { getChainInTatumNaming } = useMultiChainUtils();

 
  
  const kyCall = (endpointURL:string, data: EndpointPayload, timeout:number, apiKey: string, method?: string) => {
    if (method === "GET") {
      return ky
        .get(endpointURL, {
          timeout: timeout,
          headers: {
            "x-api-key": apiKey,
          },
        })
        .json();
    }
    return ky
      .post(endpointURL, {
        json: data,
        timeout: timeout,
        headers: {
          "x-api-key": apiKey,
        },
      })
      .json();
  };
  type EndpointPayload  = {
    tatumChain?: string,
    chainId?: string,
    [key:string]: number | string | object | undefined | Maybe<any>,
  }
  const invokeGetEndpoint = async (endpoint:String, callback?:(json: string | Object | null| JSON| any) => void,
    ) => {
      return invokeEndpoint(endpoint,{}, callback, "GET")
    }
  const invokeEndpoint = async (endpoint:String, data: EndpointPayload, 
    
    callback?:(json: string | Object | null| JSON| any) => void,
    method?:string) => {
    if(chainId === null){
      throw new Error("InvokeEndpoint Chain id is null");
    }
    if(!apiKey){
      console.log(`useBlockOneEndpoint apiKey is not set`);
      return {}
    }
    data.tatumChain = getChainInTatumNaming();
    data.chainId = chainId;

    const endpointURL = ROOT_API + endpoint;

    const json:any = await kyCall(endpointURL, data, timeout, apiKey, method);

    if (json.txId) {
      await checkTransactionStatus(json.txId, callback);
    } else {
      if (callback) {
        callback(json);
      }
    }

    return json;
  };

  const checkTransactionStatus = async (txId: string,     callback?:(json: string | Object | null|any) => void,
  ) => {
    const endpoint = "info/transaction/transaction?hash=";
    const endpointURL = ROOT_API + endpoint + txId;
    const maxRetries = 40;
    let counter = 0;
    const delay = 6000;
    let intervalId: NodeJS.Timer;

    intervalId = setInterval(async () => {
      let json: any;
      try {
        json = await ky
          .post(endpointURL, {
            headers:{
              "Content-Type": "application/json",
            },
            json: { chainId }, timeout: timeout })
          .json();
      } catch (error) {
        console.warn(error);
      }

      counter++;
      json = JSON.parse(JSON.stringify(json));
      // json =  JSON.parse(json)
      json = json || {};
      const logs = json.logs;
      const isLogs = logs != null;
      console.log(`isLogs ${isLogs}`)
      
      const contractAddress: string | null = json.contractAddress
        ? json.contractAddress
        : isLogs
        ? (logs.shift() || {}).address
        : null;

      console.log("json response" + JSON.stringify(json));
      console.log("address " + contractAddress);
      console.log("json.logs " + JSON.stringify(json.logs));
      // console.log("json.logs[0].address " + json.logs[0].address)

      //counter > 4 since sometimes logs take longer to populate
      if (contractAddress || (json.status && counter > 4)) {
        console.log(`contract created ${contractAddress}`);

        clearInterval(intervalId);
        if(callback){
          await callback({ contractAddress });
        }
      } else {
        console.log(
          "checkTransactionStatus no contract address " + JSON.stringify(json)
        );
        if (counter >= maxRetries) {
          clearInterval(intervalId);
          if(callback){          
            callback(null);
          }
        }
      }
    }, delay);
  };

  return { invokeEndpoint, invokeGetEndpoint };
};

export { useBlockOneEndpoint, setApiKey, useGenerativeArtEndoints };
