import React, { useState, useEffect, useContext } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Label, Input } from "reactstrap";
import { useFaunaUtils } from "../../utils/faunaClient";

import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries";

import { useValidateFormValues } from "../../utils/validation";
import { useHistory } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { useCactusChain } from "../../hooks/ChainUtils";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNFTActions } from "../../hooks/NFTActions";
import { useGasCheckedComponents } from "../../components/GasCheckedButton";
import { BlockchainAction } from "../../types";
import { useRichEditorComponent } from "../../components/RichEditor";

export const CreateBasicNFTComponent = () => {
  const userContext = useContext(UserContext);

  const {
    ERC20Tokens,
    NFTAddressCreated,
    persistedNFTId,
    createNFTCollection,
  } = useNFTActions();

  const { GasCheckedButton } = useGasCheckedComponents(BlockchainAction.CreateMarketplace)

  const { listAllForOwner } = useFaunaUtils();
  const { validateFormValues } = useValidateFormValues();
  const history = useHistory();
  const { chain, chainId } = useCactusChain();

  const { findGasEstimateByActionAndBlockchain } = useFaunaGQLUtils();
  const {RichEditor, content: richEditorContent} = useRichEditorComponent();

  const baseTokenURI =
    "https://block-one-asset-properties.vercel.app/api/nft/defintions/";

  const [formData, updateFormData] = useState({
    name: undefined,
    symbol: undefined,
    publicMint: true,
    ERC20MintToken: undefined,
    baseTokenURI: baseTokenURI,
    mintPrice: 0,
    adminAddress: userContext.gasAddress,
    collectionImage: "https://www.blockonelabs.com/assets/images/demo/questionmark.jpg",
    description: "description for collection",
    recipient: userContext.gasAddress,
  });
  const [loading, setLoading] = useState({
    loading: true,
    loadingMessage: "",
    loadingData: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const getERC20Tokens = async () => {

      setLoading((loading) => ({
        ...loading,
        loading: false,
        loadingData: false,
        loadedData: true,
      }));
    };

    if (
      chainId &&
      loading.loading &&
      !loading.loadingData &&
      !loading.loadedData
    ) {
      setLoading((loading) => ({
        ...loading,
        loadingData: true,
      }));
      getERC20Tokens();
    }
  }, [
    listAllForOwner,
    chainId,
    loading,
    findGasEstimateByActionAndBlockchain,
    chain?.name,
  ]);

  const renderERC20Tokens = () => {
    return ERC20Tokens.map((token) => {
      return <option value={token.contractAddress}>{token.name}</option>;
    });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    updateFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const udpatedAndRedirect = async () => {
   
      history.push(`/admin/nft/view/${persistedNFTId}`);
    };
    if (NFTAddressCreated && persistedNFTId) {
        udpatedAndRedirect()
    }

   
  }, [
    NFTAddressCreated,
    formData.baseTokenURI,
    history,
    persistedNFTId,
  ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = validateFormValues(formData);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    createNFTCollection({
      ...formData,
      description: richEditorContent,
    });

    setLoading((loading) => ({
      ...loading,
      loading: true,
      loadingMessage:
        "Deploying NFT Smart Contract, this could take a few minutes",
    }));
  };

  const isFieldInvalid = (fieldName) => {
    return errors[fieldName] != null;
  };

  if (loading.loading) {
    return <Loading message={loading.loadingMessage}></Loading>;
  }
  return (
    <div className="mb-5">
      <form onSubmit={handleSubmit}>
        <div className="grid gap-5 grid-cols-2 align-items-center profile-header mb-5 text-center text-md-left">
          <div>
            <Label for="tokenName">Collection Name</Label>
            <Input
              invalid={isFieldInvalid("name")}
              onChange={handleChange}
              type="text"
              name="name"
              placeholder="Token Name"
            />
          </div>

          <div>
            <Label for="tokenSymbol">
              Collection Symbol (4 Characteres Max)
            </Label>
            <Input
              onChange={handleChange}
              maxlength="4"
              invalid={isFieldInvalid("symbol")}
              name="symbol"
              type="text"
              placeholder="TKN"
            />
          </div>

          <div className="col-span-2">
            <Label for="banner">Collection Banner</Label>
            <Input
              onChange={handleChange}
              invalid={isFieldInvalid("banner")}
              name="banner"
              type="text"
            />
          </div>

          <div className="col-span-2">
            <Label for="tokenSymbol">Base URL</Label>
            <Input
              onChange={handleChange}
              name="baseTokenURI"
              type="text"
              disabled
              value={formData.baseTokenURI}
            />
          </div>

          <div>
            <Label for="tokenSymbol">Mint Price</Label>
            <Input
              onChange={handleChange}
              name="mintPrice"
              type="text"
              value={formData.mintPrice}
            />
          </div>

          <div>
            <Label for="tokenSymbol">Mint Token Currency</Label>

            <Input
              invalid={isFieldInvalid("ERC20MintToken")}
              type={"select"}
              name="ERC20MintToken"
              onChange={handleChange}
            >
              <option selected="true" disabled>
                Select Mint Token{" "}
              </option>
              {renderERC20Tokens()}
            </Input>
          </div>
          
          <div className="col-span-2">
            <Label for="banner">Mint Token Recipient Address</Label>
            <Input
              onChange={handleChange}
              invalid={isFieldInvalid("recipient")}
              name="recipient"
              type="text"
            />
          </div>

          <div className="hidden">
            <Label for="tokenSymbol">Admin Address</Label>
            <Input
              invalid={isFieldInvalid("adminAddress")}
              onChange={handleChange}
              name="adminAddress"
              type="text"
              value={formData.adminAddress}
            />
          </div>

          <div>
            <Label for="tokenSymbol">Collection Profile Image URL</Label>
            <Input
              onChange={handleChange}
              name="collectionImage"
              type="text"
              value={formData.collectionImage}
            />
          </div>
          <div>
            {formData.collectionImage ? (
              <img
                className=" w-36 h-36 ml-60"
                src={formData.collectionImage}
                alt="collection"
              />
            ) : (
              ""
            )}
          </div>

          <div className="col-span-2">
            <Label for="tokenSymbol">NFT Collection Description</Label>

            {/* <Input
              type="textarea"
              invalid={isFieldInvalid("description")}
              onChange={handleChange}
              name="description"
              value={formData.description}
            /> */}
          </div>
          <div className="col-span-2">
            <RichEditor></RichEditor>
          </div>

          <div check className="ml-4 col-span-2">
            <Label check>
              <Input
                name="publicMint"
                checked={formData.publicMint}
                type="checkbox"
                onClick={() => {
                  updateFormData({
                    ...formData,
                    publicMint: !formData.publicMint,
                  });
                }}
              />
              Public Mint
            </Label>
          </div>
          <div className="w-full text-center col-span-2 my-3">
            <div>
            <GasCheckedButton
            blockchainAction={BlockchainAction.CreateNftCollection}
            buttonText="Create NFT Collection"
            >
                
            </GasCheckedButton>

            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withAuthenticationRequired(CreateBasicNFTComponent, {
  onRedirecting: () => <Loading />,
});
