import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
export const Backoffice = () => {
    const {deleteAllDocuments} = useFaunaUtils();
    // const {logout} = useMoralis()
    // deleteAllDocuments(faunaCollections.Onboarding)


    return(
        <div>
            bakcoffice
            {
                
                // JSON.parse(JSON.stringify(faunaCollections))
            }
            <button onClick={() => 
            
            Object.keys(faunaCollections).forEach(collection => {
                if(collection !== faunaCollections.Organization){
                deleteAllDocuments(collection)
                // logout()
            }

            })
                
                }>Delete all</button>
        </div>
    )

}
export default withAuthenticationRequired(Backoffice, {
    onRedirecting: () => <Loading />,
  });
  