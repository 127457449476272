import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useNFTDropDownUtils } from "../../components/NFTsDropdown";
import { PrimaryButton } from "../../components/Buttons";
import { useEffect, useState } from "react";
import { NftCollection } from "../../types";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { useHistory } from "react-router-dom";

import { useRichEditorComponent } from "../../components/RichEditor";

type DropDownProps = {
  callback: any;
};

const FirstDropDown = (props: DropDownProps) => {
  const { NFTCollectionsDropdown, selectedNFTCollection } =
    useNFTDropDownUtils();


  useEffect(() => {
    if (selectedNFTCollection) {
      props.callback(selectedNFTCollection);
    }
  }, [props, selectedNFTCollection]);

  return (
    <div>
      <NFTCollectionsDropdown></NFTCollectionsDropdown>
    </div>
  );
};

const SecondDropDown = (props: DropDownProps) => {
  const { NFTCollectionsDropdown, selectedNFTCollection } =
    useNFTDropDownUtils();

  useEffect(() => {
    if (selectedNFTCollection) {
      props.callback(selectedNFTCollection);
    }
  }, [selectedNFTCollection, props]);

  return (
    <div>
      <NFTCollectionsDropdown></NFTCollectionsDropdown>
    </div>
  );
};
export const CreateVaultComponent = () => {
  const [depositNFT, setDepositNFT] = useState<NftCollection>();
  const [creatingVault, setCreatingVault] = useState(false);

  const [burnNFT, setBurnNFT] = useState<NftCollection>();
  const { invokeEndpoint } = useBlockOneEndpoint();
  const { createDocument, createRefForDocument } = useFaunaUtils();
  const history = useHistory();
  const [inputData, setInputData] = useState({
    name: ""
  });

  const {RichEditor, content } = useRichEditorComponent();

  if (creatingVault) {
    return <Loading />;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, name:string) => {
    const value = event.target.value.trim();
    setInputData({
      ...inputData,
      [name]: value,
    })
  }
  return (
    <div>
      <h1>Create Vault</h1>
      <div className="grid grid-cols-2">
      <div>
          Name
        </div>
        <div>
        <input 
        onChange={(e) => {
          handleInputChange(e, "name")

        }}
        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" placeholder="Vault"/>
        </div>

        <div>NFT To Deposit</div>
        <div>
          <FirstDropDown
            callback={(selectedNFTCollection: NftCollection) => {
              setDepositNFT(selectedNFTCollection);
            }}
          ></FirstDropDown>
        </div>
        <div>NFT To Deposit</div>
        <div>
          <SecondDropDown
            callback={(selectedNFTCollection: NftCollection) => {
              setBurnNFT(selectedNFTCollection);
            }}
          ></SecondDropDown>
        </div>
      </div>
      <div>
        description
      </div>
      <div>
        <RichEditor></RichEditor>
      </div>
      <div className="flex justify-center">
        <PrimaryButton
          onClick={() => {
            setCreatingVault(true);
            const vaultName = inputData.name;
            invokeEndpoint(
              "vaults/create",
              {
                name:vaultName,
                symbol:vaultName.substring(0,3),
                depositTokenAddress: depositNFT?.contractAddress,
                proofOfBurnTokenAddress: burnNFT?.contractAddress,
              },
              async (result: { contractAddress: string }) => {
                console.log("done");
                if (depositNFT && burnNFT) {
                  const docData = {
                    name: vaultName,
                    image: "https://img.favpng.com/16/23/18/safe-deposit-box-royalty-free-clip-art-png-favpng-hnTD7tLghBCvPvuJr4jNQ9nF6_t.jpg",
                    description: content,
                    contractAddress: result.contractAddress,
                    depositToken: createRefForDocument(
                      faunaCollections.NFTCollection,
                      depositNFT._id
                    ),
                    proofOfBurnToken: createRefForDocument(
                      faunaCollections.NFTCollection,
                      burnNFT._id
                    ),
                  };
                  const createdVault = await createDocument(
                    faunaCollections.Vault,
                    docData
                  );
                  console.log(`createdVault ${JSON.stringify(createdVault)}`);
                  history.push(`/admin/vaults/view/${createdVault._id}`);
                }
              }
            );
          }}
        >
          Create Vault
        </PrimaryButton>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(CreateVaultComponent, {
  onRedirecting: () => <Loading />,
});
