import React, { useState, useEffect } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import {
  useParams
} from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons";

import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries"
import { useCactusChain } from "../../hooks/ChainUtils";
import htmr from 'htmr';

export const NFTViewComponent = () => {

  const {chain} = useCactusChain()
  let { NFTCollectionId } = useParams();

  console.log("NFTCollectionId " + NFTCollectionId)

  const {findNFTCollectionByID} = useFaunaGQLUtils()

  const [loading, setLoading] = useState(true)
  const [NFTDetails, setNFTDetails] = useState([
    {
      address: "myName"
    }
  ])

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      // const mintToken = await findDocumentById(NFTDetails)

      const NFTDetails = await findNFTCollectionByID(NFTCollectionId)
      console.log("mintToken2 " + JSON.stringify(NFTDetails.mintToken))
      console.log("NFTDetails " + JSON.stringify(NFTDetails))

      setNFTDetails(NFTDetails)
      setLoading(false)
    }
    getMarketPlacesFauna()
  }, [NFTCollectionId, findNFTCollectionByID])


  if (loading) {
    return <Loading></Loading>
  }

  return (
    <div className="mb-5">

      <div className="align-items-center profile-header mb-5 text-center text-md-left">

        <div>


<div className="text-center mt-4">
<img className="w-40 inline mr-4 rounded-md" src={NFTDetails.collectionImage} alt="token Logo"/>

</div>
<div className="font-semibold	 text-center my-4	w-full text-lg">
  


  {' '}

  {NFTDetails.name}
  
  </div>



<a     className="    float-right             mb-3"
 href={`/admin/nft/edit/${NFTCollectionId}`}>
        <PrimaryButton
        >Edit NFT Collection</PrimaryButton>

        </a>

</div>
       
        <Table responsive>

          <tbody>
            <tr>
              <td>Name</td>
              <td>{NFTDetails.name}</td>
            </tr>
            <tr>
              <td>Symbol</td>
              <td>{NFTDetails.symbol}</td>
            </tr>
            <tr>
              <td>Collection Banner</td>
              <td>{NFTDetails.banner}</td>
            </tr>
            <tr>
              <td>Collection Image</td>
              <td>{NFTDetails.collectionImage}</td>
            </tr>

            <tr>
              <td>Contract Address</td>
              <td>{NFTDetails.contractAddress}</td>
            </tr>

            
            <tr>
              <td>Public Mint</td>
              <td>{String(NFTDetails.publicMint)}</td>
            </tr>


            <tr>
              <td>Mint Price</td>
              <td>
                {
                Number(NFTDetails.mintPrice) / (10 **NFTDetails.mintDecimals)
                }
                {' '}
                {NFTDetails.mintToken?.name || chain.nativeCurrency.symbol}
                </td>
            </tr>


            <tr>
              <td>NFT Description </td>
              <td>
                {
                htmr(NFTDetails.description.replaceAll(", \"",",'").replaceAll("\",","',"))}
                </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(NFTViewComponent, {
  onRedirecting: () => <Loading />,
});
