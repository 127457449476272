import { OnboardingStatus } from "../types";
import PolgygonLogo from "../assets/polygon-matic-logo.png"
const Constants  = {
    POLYGON_LOGO_IMG_URL: PolgygonLogo,
    BSC_LOGO_IMG_URL: "https://www.pinksale.finance/static/media/ic-bsc.419dfaf2.png",
    MARKETPLACE_BUILD_TAG: "001",
    POLYGON_MAINNET_BLOCKCHAIN_ID: "0x89",
    POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID: "0x13881",
    TESTNET_CHAIN_IDS: ["0x61","0x13881"],
    BINANCE_MAINNET_BLOCKCHAIN_ID: "0x38",
    BINANCE_TESTNET_BLOCKCHAIN_ID: "0x61",
    MINIMUN_TREASURY_FOR_TUTORIAL: {
        "0x61": 0.2,
        "0x13881": 0.2
    },
    ERC20_DEFAULT_DECIMALS: 18,
    ERC20_LIQUIDITY_TOKEN_DECIMALS: 9,
    ERC20TokenTye: {
        LIQUIDITY: "LIQUIDITY",
        BASIC: "BASIC"
    },
    TESNSET_TREASURY_FUND_LINK: {
        "0x61": "https://testnet.binance.org/faucet-smart",
        "0x13881": "https://faucet.polygon.technology/"
    },
    ONBOARDING_SAAS_USER_STEPS: [
        OnboardingStatus.DemoSaasGetCoins,
        OnboardingStatus.DemoSaasCheckGas,
        OnboardingStatus.DemoSaasBuyNft,
        OnboardingStatus.DemoSaasTryApplication,
        OnboardingStatus.DemoSaasApplicationAuthSuccess,
      ]

}

export default Constants;