import React from "react";
import metamask from "../assets/metamask.svg";

import { useMoralis, useChain } from "../hooks/ChainUtils";
// import Loading from "./Loading";


type Web3AuthenticateInput = {
  requiredChain: string
}
const Web3Authenticate = (input: Web3AuthenticateInput) => {
  const { authenticate, enableWeb3 } =
    useMoralis();
    const {switchNetwork } = useChain()


  return (
    <div>
      <div className="mb-24 mt-4">

        <div className="text-xl"></div>
      </div>

      <div
        className="w-full grid cursor-pointer justify-items-center"
        onClick={async () => {
          await authenticate({ signingMessage: "Authentication" });
          enableWeb3();
          switchNetwork(input.requiredChain!)
        }}
      >
        <div className="text-xl">
          Please connect with your web3 wallet to continue {input.requiredChain}
        </div>
        <div className="w-40 my-5">
          <img src={metamask} alt="Loading" />
        </div>
      </div>
    </div>
  );
};

export default Web3Authenticate;
