import { MarketplaceInput } from "../../types/index"
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";

import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { Vault } from "../../types/index";

function disconnectMarketplaceNFTInput(name :string,
     symbol: string, customStyleSheetURL:string,
     webhookURL:string, disconnect:Array<string>) : MarketplaceInput {
 
    return {name,
    symbol,
    customStyleSheetURL,
    webhookURL,
    marketplaceNFTCollections: {
        disconnect
    }
}};

const useCreateMarketplace = (setLoading:any = null) => {
    const { invokeEndpoint } = useBlockOneEndpoint();
    const { createDocument, createRefForDocument } = useFaunaUtils();
    interface createdCallBackData {
      contractAddress?: any;
      error?: any;

    }

    const createMarketplace = async (data:any, formData: any,
      callback: any) => {
      invokeEndpoint(
            "nft/createNFTMarketplace",
            data,
            async (data: createdCallBackData) => {
              if (data.contractAddress) {
                const createdDoc = await persistCreatedMarketplace(data.contractAddress, formData);
                if(callback){
                  callback(createdDoc)
                }
              } else {
                if(setLoading){
                  setLoading(false);
                }
                alert(data.error);
              }
          }
          );

    }

    const persistCreatedMarketplace = async (contractAddress:any, formData:any) => {

      let submitData:{marketplaceNFTCollections?:any, vaults?:any} = {};
      Object.assign(submitData, formData);
  
      delete submitData.marketplaceNFTCollections;
      delete submitData.vaults;


  
      const createdDoc = await createDocument("Marketplace", {
        ...submitData,
        contractAddress: contractAddress,
      });


      const vaults = formData.vaults
  
      
      vaults?.forEach((v: Vault) => {
        createDocument(faunaCollections.MarketplaceVault,{
          vault: createRefForDocument(faunaCollections.Vault, v._id),
          marketplace: createRefForDocument(
            faunaCollections.Marketplace,
            createdDoc.id
          )
        })
        return createRefForDocument(faunaCollections.MarketplaceVault, v._id)
      })
      

  
      const persistedNFTPromises = formData.marketplaceNFTCollections.map(
        async (NFT:any) => {
          const created = await createDocument(
            faunaCollections.MarketplaceNFTCollection,
            {
              contractAddress: NFT.contractAddress,
              status: NFT.name,
              marketplace: createRefForDocument(
                faunaCollections.Marketplace,
                createdDoc.id
              ),
              NFTCollection: createRefForDocument(
                faunaCollections.NFTCollection,
                NFT.id
              ),
            }
          );
          
          console.log("created " + JSON.stringify(created));
          return created;
        }
      );
      const persistedNFTCollections = await Promise.all(persistedNFTPromises);
  
      console.log("persistedNFTCollections " + JSON.stringify(persistedNFTCollections));
  
      return createdDoc;

    }
    return {createMarketplace}


}

export {disconnectMarketplaceNFTInput, useCreateMarketplace} ;