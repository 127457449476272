import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useContext, useEffect } from "react";
import { UserContext } from "../context/userContext";
import { useCactusNativeBalance } from "../hooks/ChainUtils";
import { useCactusChain } from "../hooks/ChainUtils";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import { Modal, ModalBody } from "reactstrap";

import { faCoins, faWindowClose } from "@fortawesome/free-solid-svg-icons";

const TreasuryNavBar = () => {
  const userContext = useContext(UserContext);

  const gasAddress = userContext.gasAddress;
  const { chainId } = useCactusChain();

  console.log(`TreasuryNavBar ${chainId}`)
  

  const [gasStationPopupModal, setGasStationPopupModal] = useState(false);

  const { balance, getBalance } = useCactusNativeBalance({
    chain: chainId,
    address: gasAddress,
  });


  useEffect(() => {
    const intervalId = setInterval(async () => {
      console.log(`getting updated balance chainId ${chainId}`)
      getBalance()
    }, 8000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId]);

  return (
    <div>
      <div className="cursor-pointer mr-4">
        <div
          onClick={() => {
            setGasStationPopupModal(!gasStationPopupModal);
          }}
        >
          <FontAwesomeIcon icon={faCoins} />
          {"  "}
          Treasury
          {"  "}
          {"  "}
          {balance}
          {"  "}
        </div>

        <Modal isOpen={gasStationPopupModal}>
          <ModalBody>
            <div className="w-full">
              
              <div className="float-right">
                <FontAwesomeIcon
                  className="cursor-pointer"
                  onClick={() => {
                    setGasStationPopupModal(!gasStationPopupModal);
                  }}
                  icon={faWindowClose}
                />
              </div>
              
            </div>
            <div className="mt-4 flex justify-center">
              Treasury Address:
              </div>
            <div className="flex justify-center mt-4">

            {`${gasAddress || "".substring(0, 25) + "..."}`}
            <div>
              <FontAwesomeIcon
                className="cursor-pointer ml-3"
                onClick={() => {
                  navigator.clipboard.writeText(userContext.gasAddress);
                }}
                icon={faCopy}
              />
            </div>

            </div>

          </ModalBody>
        </Modal>
      </div>
    </div>
  );
};

export default TreasuryNavBar;
