import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { useCactusChain } from "../../hooks/ChainUtils";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { useMultiChainUtils } from "../../utils/multiChainUtils";
export const ListERC20TokensComponent = () => {
  // const { fetch } = useWeb3ExecuteFunction();

  const { chainId, chain } = useCactusChain();
  const { invokeEndpoint } = useBlockOneEndpoint();

  const { isMainnetConnected } = useMultiChainUtils();

  const { listAllForOwner } = useFaunaUtils();

  const [loading, setLoading] = useState({
    loading: true,
    loadingPrice: false,
  });
  const [ERC20Tokens, setERC20Tokens] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      // console.log("ERC20Tokens supply isAuthenticated " + isAuthenticated);

      console.log("loadingprice");
      setLoading((loading) => ({
        ...loading,
        loadingPrice: true,
      }));
      ERC20Tokens.filter((token) => !token.supplyLoaded).forEach(async (token) => {
        // const options = {
        //   chain: chainId,
        //   contractAddress: token.contractAddress,
        //   functionName: "totalSupply",
        //   abi: ERC20MinterPauserABI,
        //   params: {},
        // };

        // console.log("supply token show" + JSON.stringify(token));
        // const filteredTokens = ERC20Tokens.filter((f) => f.id !== token.id);

        token.supplyLoaded = true;
            const price = await invokeEndpoint(
              `ERC20/price?tokenAddress=${token.contractAddress}`,
              {},
              null,
              "GET"
            );
            console.log("price " + JSON.stringify(price));
            if (price.usdPrice) {
              token.usdPrice = parseFloat(price.usdPrice).toFixed(5);
            }

        // fetch({
        //   params: options,
        //   onSuccess: async (success) => {
        //     console.log(
        //       "success  supply token" +
        //         +String(token.name) +
        //         " " +
        //         JSON.stringify(success)
        //     );
        //     token.supply = success;
            

        //     setERC20Tokens((oldTokens) => [...filteredTokens, token]);
        //   },
        //   onError: (error) => {
        //     token.supplyLoaded = true;

        //     setERC20Tokens((oldTokens) => [...filteredTokens, token]);

        //     //alert("error " + error )
        //   },
        // });


      });
    };

    if (!loading.loadingPrice && ERC20Tokens.length > 0) {
      fetchData();
    }
  }, [loading, ERC20Tokens, chainId, invokeEndpoint]);

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      const mkts = await listAllForOwner(faunaCollections.ERC20Token);
      console.log("mkts " + JSON.stringify(mkts));
      setERC20Tokens(mkts);
      setLoading((oldLoading) => ({
        ...oldLoading,
        loading: false,
      }));
    };
    if (loading.loading) {
      getMarketPlacesFauna();
    }
  }, [listAllForOwner, chain?.name, loading]);

  const renderERC20List = () => {
    const result = ERC20Tokens.map((token, idx) => {
      const link = `/admin/erc20/view/${token.id}`;
      return (
        <tr
          key={idx}
          onClick={() => {
            console.log("lets go to id");
          }}
        >
          <td className="px-4 py-3 border-b-1 font-semibold		">
            <div class="flex items-center">
              {token.logoURL ? (
                <img
                  src={token.logoURL}
                  class="h-12 w-12 bg-white rounded-full border p-1"
                  alt="..."
                />
              ) : (
                <></>
              )}

              <a className="ml-3 font-bold NaN" href={link}>
                {token.name}
              </a>
            </div>
          </td>
          <td className="px-4 py-3 border-b-1	">
            {Number(token.supply) / 10 ** token.decimals}
          </td>

          <td className="px-4 py-3 border-b-1	">{token.contractAddress}</td>
          <td className="px-4 py-3 border-b-1	">{token.type}</td>
          <td className="px-4 py-3 border-b-1	">
            {isMainnetConnected() ? token.usdPrice : "Not Available On testnet"}
          </td>
        </tr>
      );
    });
    return result;
  };

  if (loading.loading) {
    return <Loading></Loading>;
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <table className="w-full">
          <thead
            className=" text-center 
                           
                           
                           "
          >
            <tr className="border-b-2 bg-gray-50  text-gray-500		">
              <th className="p-2">Name</th>
              <th>Supply</th>
              <th>Contract Address</th>
              <th>Type</th>
              <th>USD Price</th>
            </tr>
          </thead>
          <tbody>{renderERC20List()}</tbody>
        </table>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ListERC20TokensComponent, {
  onRedirecting: () => <Loading />,
});
