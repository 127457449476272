import { useState, useEffect } from "react";

import { faunaCollections } from "./../utils/faunaCollections";
import { useFaunaUtils } from "./../utils/faunaClient";
import { useCactusChain } from "../hooks/ChainUtils";
import { useBlockOneEndpoint } from "./../utils/blockOneAPI";

const useNFTActions = () => {
  const [ERC20Tokens, setERC20Tokens] = useState<any>([]);
  const [persistedNFTId, setPersistedNFTId] = useState();
  const [NFTAddressCreated, setNFTAddressCreated] = useState<string>();
  const [creatingNFTCollection, setCreatingNFTCollection] = useState(false);

  const {
    createDocument,
    listAllForOwner,
    createRefForDocument,
    updateDocument,
  } = useFaunaUtils();

  const { chain, chainId } = useCactusChain();

  const { invokeEndpoint } = useBlockOneEndpoint();

  useEffect(() => {
    const getERC20Tokens = async () => {
      const mkts = await listAllForOwner(faunaCollections.ERC20Token);
      mkts.push({
        contractAddress: "0x0000000000000000000000000000000000000000",
        name: chain?.nativeCurrency?.symbol,
      });
      console.log("mkts " + JSON.stringify(mkts));
      setERC20Tokens(mkts);
    };
    if (chainId && chain?.nativeCurrency) {
      getERC20Tokens();
    }
  }, [listAllForOwner, chainId, chain?.name, chain?.nativeCurrency]);

  const createNFTCollection = async (formData: any) => {
    setCreatingNFTCollection(true);
    try {
      const mintToken = ERC20Tokens.find(
        (token: { contractAddress: any }) =>
          token.contractAddress === formData.ERC20MintToken
      );
      const decimals = mintToken.decimals || 18;
      const adjustedPrice = formData.mintPrice * 10 ** decimals;
      formData.mintPrice = adjustedPrice.toLocaleString("fullwide", {
        useGrouping: false,
      });
      formData.mintDecimals = decimals;
      formData.mintTokenName = mintToken.symbol || chain?.nativeCurrency?.symbol;

      const createdDoc = await persistNFTContract(mintToken.id, formData);
      const persistedNFTId = createdDoc.id;
      setPersistedNFTId(persistedNFTId);
      formData.baseTokenURI = formData.baseTokenURI.concat(
        `${persistedNFTId}/`
      );

      const smartContractParams = [
        formData.name,
        formData.symbol,
        formData.baseTokenURI,
        formData.publicMint,
        formData.adminAddress,
        formData.mintPrice,
        formData.ERC20MintToken,
        formData.recipient,
      ];

      invokeEndpoint(
        "smartcontract/invokemethod",
        {
          ...formData,
          smartContractParams,
          smartContractMethod: "deploy721Contract",
        },
        async (result: { contractAddress: string; error: string }) => {
          console.log("contractAddress setlaoding " + result.contractAddress);
          if (result.contractAddress) {
            const data = {
              contractAddress: result.contractAddress,
            };

            await updateDocument(
              faunaCollections.NFTCollection,
              data,
              persistedNFTId
            );

            setNFTAddressCreated(result.contractAddress);
            setCreatingNFTCollection(false);
          } else {
            alert(result.error);
          }
        }
      );
    } catch (error) {
      console.warn(error);
      setCreatingNFTCollection(false);
    }
  };

  const persistNFTContract = async (mintTokenId: string, formData: any) => {
    // we dont want to persist initial mint price since it might change
    // formData.baseTokenURI += formData.baseTokenURI.concat(contractAddress)

    if (mintTokenId) {
      formData.mintToken = createRefForDocument(
        faunaCollections.ERC20Token,
        mintTokenId
      );
    }

    const createdDoc = await createDocument(faunaCollections.NFTCollection, {
      ...formData,
      // contractAddress: contractAddress,
    });
    return createdDoc;
    // history.push(`/admin/nft/view/${createdDoc.id}`)
  };

  return {
    createNFTCollection,
    creatingNFTCollection,
    ERC20Tokens,
    NFTAddressCreated,
    persistedNFTId,
  };
};

export { useNFTActions };
