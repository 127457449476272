import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { UserContext } from "../../context/userContext";
import { useCactusNativeBalance } from "../../hooks/ChainUtils";
import { PrimaryButton } from "../../components/Buttons";
import { faCoins } from "@fortawesome/free-solid-svg-icons";

import Constants from "../../utils/constants";
import { BSC_TESNET } from "../../hooks/ChainContextUtils/BlockchainsSupported";

export const TreasurySetup = ({ fundedCallback }) => {
  const  chain  = BSC_TESNET

  const userContext = useContext(UserContext);


  const { balance, getBalance } = useCactusNativeBalance({
    chain: chain.chainId,
    address: userContext.gasAddress,
  });

  const [treasuryBalance, setTreasuryBalance] = useState();
  const minTreasury =
    Constants.MINIMUN_TREASURY_FOR_TUTORIAL[chain.chainId];

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const  latestBalance = await getBalance()
      // const latestBalance = await Moralis.Web3API.account.getNativeBalance({
      //   chain: chainId,
      //   address: userContext.gasAddress,
      // });
      if (latestBalance !== treasuryBalance) {
        // latestBalance.formatted = `${
        //   latestBalance.balance / 10 ** chain.nativeCurrency.decimals
        // } ${chain.nativeCurrency.symbol}`;

        setTreasuryBalance(Number(latestBalance));
      }
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!treasuryBalance && balance) {
      setTreasuryBalance(balance);
    }

  },[treasuryBalance, balance])

  const fundTreasury = () => {
    return (
      <div>
        <div className="text-xl font-bold mb-2">Fund your Treasury</div>
        <div>
          Each account has a treasury wallet used to pay for certain actions
          that require blockchain fees
        </div>
        <div>
          During the setup only testnet tokens are deposited there is no need to
          spend any mainnet tokens, this setup has no monetary cost.
        </div>

        <div className="m-3">
          <div className="font-bold">Treasury wallet address:</div>
          {userContext.gasAddress}

          <FontAwesomeIcon
            className="cursor-pointer ml-3"
            onClick={() => {
              navigator.clipboard.writeText(userContext.gasAddress);
            }}
            icon={faCopy}
          />
        </div>

       
        
        <div>
        <FontAwesomeIcon
        className="mr-2"
           
           icon={faCoins}
         />
          Treasury {
          `${treasuryBalance}   ${chain.nativeCurrency.symbol}`
        

          }</div>
        <div>
          Balance Required {Constants.MINIMUN_TREASURY_FOR_TUTORIAL[chain.chainId]}
          {` ${chain.nativeCurrency.symbol}`}
        </div>
        <div>{renderWaitingForFunds()}</div>
      </div>
    );
  };

  const renderWaitingForFunds = () => {
    if (!treasuryBalance || treasuryBalance < minTreasury) {
      return (
        <div className="m-2">
          <div>You can fund your treasury wallet using the faucet:</div>
          <div className="m-1">
            <a
            target="_blank" rel="noopener noreferrer"
              href={Constants.TESNSET_TREASURY_FUND_LINK[chain.chainId]}
              alt="treasury wallet funding"
            >
              {Constants.TESNSET_TREASURY_FUND_LINK[chain.chainId]}
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div>you have enough funds in your Treasury!</div>
          <div className="mt-4">
            <PrimaryButton
              onClick={() => {
                fundedCallback();
              }}
            >
              Continue
            </PrimaryButton>
          </div>
        </div>
      );
    }
  };

  if(!balance || !treasuryBalance ) {
    return <Loading />;
  }

  return <div>{fundTreasury()}</div>;
};

export default withAuthenticationRequired(TreasurySetup, {
  onRedirecting: () => <Loading />,
});
