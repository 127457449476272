import React from "react";

// import { useFaunaGQLUtils } from "../utils/faunaGraphQLQueries";
import { useGasStationUtils } from "../utils/gasStation";
import { PrimaryButton } from "./Buttons";
import Loading from "./Loading";
const useGasCheckedComponents = (blockchainAction) => {
  // const { findGasEstimateByActionAndBlockchain } = useFaunaGQLUtils();
  const { gasStationStatus } = useGasStationUtils(blockchainAction);

  const GasCheckedButton = ({
    disabled,
    buttonText,
    noGasCallToAction,
    loading,
    loadingText = "Performing Blockchain Transaction",
    ...props
  }) => {

    if(!gasStationStatus){
      return <Loading className="h-4"/>

    }
    if(loading === true) {
      return <div>
        <div>
        <Loading className="h-14 w-14"/>
        </div>
        <div>
          {loadingText}
        </div>
      </div>
    }

    
    const formatedGasRequired = gasStationStatus?.formatedGasRequired
    const gasEstimate = ` Estimated gas required
    ${String(Math.round(formatedGasRequired * 10000) / 10000) }
     ${gasStationStatus?.nativeToken}`

    if (!gasStationStatus?.hasEnoughGas) {
      // console.log(JSON.stringify(gasStationStatus));
      const callToAction = noGasCallToAction ? noGasCallToAction() : null;
      return (
        <div>
          <div className="flex justify-center">
          <PrimaryButton
          {...props}
          disabled={true}>{buttonText}</PrimaryButton>
          </div>

          <div className="text-sm text-red-600 flex  mt-2">
            Not enough gas in treasury             {gasEstimate}

          </div>
          <div className="text-sm mt-2">
          </div>
          <div>{callToAction}</div>
        </div>
      );
    }
    // const enabledClass = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`
    // const disabledClass = `bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed`
    // const buttonClass = props.disabled ? disabledClass : enabledClass
    return (
      <div>
        <div className="flex justify-center">

        <PrimaryButton
        {...props}
        disabled={disabled}>{buttonText}</PrimaryButton>


        </div>

        {/* {JSON.stringify(gasStationStatus)} */}
       
<div className="text-sm mt-2">
            {gasEstimate}
          </div>
      </div>
    );
  };

  return { GasCheckedButton };
};

export { useGasCheckedComponents };
