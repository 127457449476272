import React from "react";

import Loading from "./components/Loading";
import Home from "./views/Home";

import { useAuth0 } from "@auth0/auth0-react";
import 'react-pro-sidebar/dist/css/styles.css';
import { MoralisProvider } from "react-moralis";
import {UserProvider} from "./context/userContext"

// styles
import "./App.css";
import "./App.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
import LoginComponent from "./views/login/Login";
initFontAwesome();

const App = () => {

  const { isLoading, error, isAuthenticated, loginWithRedirect } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {

    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(urlSearchParams.entries());
    if(params.loginNow) {
      loginWithRedirect()
      return <Loading />;
    }

    return (

      
      <div>

<LoginComponent>

</LoginComponent>
        
      </div>
    )
  }


  const moralisServerUrl = process.env.REACT_APP_MORALIS_SERVER_URL
  const moralisAppId = process.env.REACT_APP_MORALIS_APP_ID

  return (
    <MoralisProvider appId={moralisAppId} serverUrl={moralisServerUrl}>

<UserProvider>
<Home></Home>
</UserProvider>
  
    </MoralisProvider>
    

  );
};

export default App;
