import React, { useEffect, useState } from "react";
import { useFaunaUtils } from "../utils/faunaClient";

import Loading from "../components/Loading";
import { useFaunaGQLUtils } from "../utils/faunaGraphQLQueries";

import { faunaCollections } from "../utils/faunaCollections";

import { setApiKey } from "../utils/blockOneAPI";

import { useBlockOneEndpoint } from "../utils/blockOneAPI";
import { useAuth0 } from "@auth0/auth0-react";


export const UserContext = React.createContext();

export const UserProvider = ({ children }) => {
  const { invokeEndpoint } = useBlockOneEndpoint();

  const { user: auth0User } = useAuth0();

  const [currentChain, setCurrentChain] = useState(null);

  const { findUserById } = useFaunaGQLUtils();
  const { listAllForOwner, createDocument, createRefForDocument } =
    useFaunaUtils();
  const [organizationId, setOrganizationId] = useState();
  const [gasAddress, setGasAddress] = useState();
  const [onboarding, setOnboarding] = useState();

  const [loadingUser, setLoadingUser] = useState(false);


  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetch = async () => {
      let users = await listAllForOwner(faunaCollections.User);

      console.log(`users ${users.length}` + JSON.stringify(users));
      let user = users[0];
      console.log(`user.organization.id ${JSON.stringify(user?.organization)}`);
      const organizations = await listAllForOwner(
        faunaCollections.Organization
      );
      // console.log(`organizations ${organizations.length}` + JSON.stringify(organizations))
      if (organizations.length > 1) {
        console.warn(`too many orgs ${organizations}`);
        throw Error(`too many orgs ${JSON.stringify(organizations)}`);
      }
      const organization = organizations[0];

      if (user && organization?.secretKey) {
        user.organization = organization;
        // user = await findUserById(user.id)
        setApiKey(user.organization.secretKey);

        setGasAddress(user.organization.gasAddress);
        setOrganizationId(user.organization.id);

        let onboarding = await listAllForOwner(faunaCollections.Onboarding);
        if (onboarding && onboarding.length > 0) {
          console.log("got onboarding " + JSON.stringify(onboarding[0]));
          setOnboarding(onboarding[0]);
        } else {
          console.log("no onboarding creating");
          onboarding = await createDocument(faunaCollections.Onboarding, {
            skipped: true,
            organizationId: user.organization.id,
          });
          setOnboarding(onboarding);
        }

        setLoading(false);
      } else {
        console.log("lets create org");
        const secretKey = Array.from(Array(40), () =>
          Math.floor(Math.random() * 36).toString(36)
        ).join("");

        const domain = (auth0User.email || "").split("@").pop();

        const createdOrg = await createDocument(faunaCollections.Organization, {
          name: domain,
          secretKey,
        });

        console.log("createdOrg.id " + JSON.stringify(createdOrg));
        setOrganizationId(createdOrg.id);
        setApiKey(secretKey);

        console.log("lets create user");

        user = await createDocument(faunaCollections.User, {
          name: auth0User?.name,
          email: auth0User.email,
          organization: createRefForDocument(
            faunaCollections.Organization,
            createdOrg.id
          ),
        });
        console.log(`
        created user ${JSON.stringify(user)}`);
        const createdGasAddress = await invokeEndpoint("gas/create", {});
        console.log("created gas address " + JSON.stringify(createdGasAddress));
        setGasAddress(createdGasAddress.gasAddress);

        const createdOnboarding = await createDocument(
          faunaCollections.Onboarding,
          {
            skipped: false,
            organizationId: createdOrg.id,
          }
        );
        setOnboarding(createdOnboarding);

        setLoading(false);
      }

      console.log("onboarding organizationId  " + organizationId);
    };
    if (!loadingUser && auth0User) {
      setLoadingUser(true);
      fetch();
    }
  }, [
    listAllForOwner,
    auth0User,
    organizationId,
    createDocument,
    createRefForDocument,
    invokeEndpoint,
    loadingUser,
    findUserById,
  ]);

  if (loading) {
    return <Loading></Loading>;
  }


  return (
    <div>
      <UserContext.Provider value={{ organizationId, gasAddress, onboarding,setCurrentChain, currentChain }}>
        {children}
      </UserContext.Provider>
    </div>
  );
};
