import { PrimaryButton } from "../../../../../components/Buttons";
import { useEffect, useState } from "react";
export const VideoIntro = ({setNextStatus}) => {

  const [showNextButton, setShowNextButton] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowNextButton(true);
    }, 10000);

  }, [])
  
    
  

  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Video Intro
          </div>
          <div class="text-center">
Please take a few seconds and watch this quick video to understand the steps that we will follow during the setup

          </div>
          <div className="flex justify-center">

          <iframe title="intro video"  width="480" height="240" 
          src="https://www.youtube.com/embed/QYMIfwR4cz0" fs="1"
           frameborder="0" 
           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
           allowfullscreen></iframe>

          </div>
          <div className="flex justify-center mt-2">
           {showNextButton && <PrimaryButton onClick={() => {setNextStatus()}}>Next</PrimaryButton>}
          </div>
        </div>
      </div>
    </div>
  );
};
