import { useCactusChain } from "../hooks/ChainUtils";

export function useMultiChainUtils(){
    const { chainId } = useCactusChain();

    const getLiquidityTokenRouter = () => {
        switch(chainId) {
            case "0x38": // bsc mainnet
                return "0x10ED43C718714eb63d5aA57B78B54704E256024E";
            case "0x61": // bsc testnet
                return "0xD99D1c33F9fC3444f8101754aBC46c52416550D1";
                
            case "0x89": //polygon mainnet
                return "0xa5E0829CaCEd8fFDD4De3c43696c57F7D7A678ff";

        }
        return undefined;

    }

    const getChainInTatumNaming = () => {
        console.log("getChainInTatumNaming chainId " + chainId)
        switch(chainId) {
            case "0x38": // bsc mainnet
                return "BSC";
            case "0x61": // bsc testnet
                return "BSC";
                
            case "0x89": //polygon mainnet
                return "MATIC";

            case "0x13881": //mumbai testnet
                return "MATIC"; 
        }
    }
    


    const getExplorerURLForContract = (contractAddress: string) => {
    switch(chainId) {
        case "0x38":
            return `https://testnet.bscscan.com/address/${contractAddress}`;
        case "0x61":
            return `https://testnet.bscscan.com/address/${contractAddress}`;
        case "0x13881":
            return `https://mumbai.polygonscan.com/address/${contractAddress}`;
        case "0x89":
            return `https://polygonscan.com/address/${contractAddress}`;
    }
}

const isMainnetConnected = () => {

    switch(chainId) {
        case "0x38":
            return true;
        case "0x89":
            return true;
    }
    return false
}

return {getLiquidityTokenRouter, isMainnetConnected,  getExplorerURLForContract, getChainInTatumNaming}


}

