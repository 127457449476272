import React, { useState} from "react";

import { EditorState, convertToRaw } from "draft-js";

import draftToHtml from "draftjs-to-html";


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";


export const useRichEditorComponent = () => {

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState("");

  const onEditorStateChange = (editorState) => {
    const description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );

    setContent(description);

    setEditorState(editorState);
  };
  const RichEditor= () => {
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor border border-sky-500"
          onEditorStateChange={onEditorStateChange}
        />
      </div>
    );

  }

  return {RichEditor, content};
    

}