import TreasurySetup from "../../../TreasurySetup";
export const FundTreasury = ({setNextStatus}) => {
  
    
  

  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="text-center">
            <TreasurySetup
fundedCallback={() => {setNextStatus()}}
            ></TreasurySetup>

          </div>
        </div>
      </div>
    </div>
  );
};
