export const faunaCollections = {
    NFTCollection:"NFTCollection",
    ERC20Token: "ERC20Token",
    Marketplace: "Marketplace",
    MarketplaceNFTCollection: "MarketplaceNFTCollection",
    User: "User",
    Organization: "Organization",
    Onboarding: "Onboarding",
    Vault:"Vault",
    MarketplaceVault: "MarketplaceVault"
}

export const ERC20TokenTye = {
    LIQUIDITY: "LIQUIDITY",
    BASIC: "BASIC"
}