import { GraphQLClient, gql } from 'graphql-request'
import { useAuth0 } from "@auth0/auth0-react";
import { MarketplaceInput, NftCollectionInput, BlockchainAction } from "../types/index"
import { useCallback } from "react";

const FAUNA_GRAPHQL_BASE_URL = 'https://graphql.us.fauna.com/graphql'

  const useFaunaGQLUtils = () => {

    const {  getAccessTokenSilently } = useAuth0();


    const findGasEstimateByActionAndBlockchain = useCallback(
      async (blockchain: String, action: BlockchainAction) => {
      const token = await getAccessTokenSilently();
      const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
        headers: {
          authorization: `Bearer ${token}`,
        },
      })


 const query = gql`
 query FindGasEstimateByActionAndBlockchain($blockchain: String!, $action: BlockchainAction!) {

  findGasEstimateByActionAndBlockchain(blockchain: $blockchain, action: $action) {
     _id
     gasRequired


}}
`

return graphQLClient
   .request(query,{blockchain, action})
   .then(data => {
     return data.findGasEstimateByActionAndBlockchain;
   })


    },[getAccessTokenSilently])



 const findNFTCollectionByID = useCallback(async (id: String) => {
  const token = await getAccessTokenSilently();
  console.log("token " + token)
 const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
   headers: {
     authorization: `Bearer ${token}`,
   },
 })

 const query = gql`
 query FindNFTCollectionByID($id: ID!) {

  findNFTCollectionByID(id: $id) {
     _id
     name
     collectionImage
     symbol
     contractAddress
     publicMint
     mintPrice
     mintDecimals
     description
     mintTokenName
     banner
     owner
     mintToken { 
       name
       _id
       symbol
       decimals
     }
     


}}
`

return graphQLClient
   .request(query,{id})
   .then(data => {
     return data.findNFTCollectionByID;
   })
},[getAccessTokenSilently])



 const findMarketplaceByID = useCallback(async (marketPlaceId: String) => {
   const token = await getAccessTokenSilently();
   console.log("token " + token)
  const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  })

  const query = gql`
  query FindMarketplaceByID($id: ID!) {

    findMarketplaceByID(id: $id) {
      _id
      name
    customStyleSheetURL
    webhookURL
    marketplaceNFTCollections {
      data {
        _id
        NFTCollection {
          contractAddress
          name
          collectionImage
        }
      }
    }
}}
`

return graphQLClient
    .request(query,{id: marketPlaceId})
    .then(data => {
      return data.findMarketplaceByID;
    })
},[getAccessTokenSilently])


const updateMarketplace = useCallback(async (
  id: String, data: MarketplaceInput) => {
  const token = await getAccessTokenSilently();
  console.log("token " + token)
 const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
   headers: {
     authorization: `Bearer ${token}`,
   },
 })

 const query = gql`
 mutation UpdateMarketplace($id: ID!, $data: MarketplaceInput!) {
  updateMarketplace(id: $id, data: $data) {
    _id
  }
}
`

return graphQLClient
   .request(query,{id, data})
   .then(data => {
     return data;
   })
},[getAccessTokenSilently])


const updateNFTCollection = useCallback(async (
  id: String, data: NftCollectionInput) => {
  const token = await getAccessTokenSilently();
 const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
   headers: {
     authorization: `Bearer ${token}`,
   },
 })

 const query = gql`
 mutation UpdateNFTCollection($id: ID!, $data: NFTCollectionInput!) {
  updateNFTCollection(id: $id, data: $data) {
    _id
  }
}
`

return graphQLClient
   .request(query,{id, data})
   .then(data => {
     return data;
   })
},[getAccessTokenSilently])


const findUserById = useCallback(async (userId: String) => {
  const token = await getAccessTokenSilently();
  console.log("token " + token)
 const graphQLClient = new GraphQLClient(FAUNA_GRAPHQL_BASE_URL, {
   headers: {
     authorization: `Bearer ${token}`,
   },
 })

 const query = gql`
 query FindUserByID($id: ID!) {

  findUserByID(id: $id) {
     _id
     organization {
       _id
      secretKey
      gasAddress
    }
   }
}
`

return graphQLClient
   .request(query,{id: userId})
   .then(data => {
     return data.findUserByID;
   })
},[getAccessTokenSilently])


return { findMarketplaceByID,
  findGasEstimateByActionAndBlockchain,
  findNFTCollectionByID, findUserById, updateMarketplace, updateNFTCollection }
}

export {useFaunaGQLUtils}