import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCactusChain } from "../hooks/ChainUtils"
import Constants from "../utils/constants";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  Button,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import TreasuryNavBar from "./TreasuryNavBar";
import { useAuth0 } from "@auth0/auth0-react";
import { useOnboardingActions } from "../hooks/OnboardingActions";
import { OnboardingStatus } from "../types";
const NavBar = ({ topLeftTitle = "" }) => {
  const { status } = useOnboardingActions();

  const [isOpen, setIsOpen] = useState(false);
  const [chooseBlockchainModal, setChooseBlockchainModal] = useState(false);


  const { switchNetwork, chain } = useCactusChain();

  // const {switchNetwork: switchNetworkCactus} = useCactusChain();



  const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const toggle = () => setIsOpen(!isOpen);

  const switchBlockchainNetwork = async (networkHexValue) => {
    // switchNetworkCactus(networkHexValue);
    switchNetwork(networkHexValue);
    setChooseBlockchainModal(false);
  };

  const logoutWithRedirect = () =>
    logout({
      returnTo: window.location.origin,
    });

  const renderTreasury = () => {
    return <TreasuryNavBar></TreasuryNavBar>;

  };

  const renderMainnetBlockchains = () => {
    return (
      <div className="flex justify-evenly">
        <div>
          <div
            color="primary"
            className="flex justify-center "
            onClick={async () => {
              switchBlockchainNetwork(Constants.BINANCE_MAINNET_BLOCKCHAIN_ID);
            }}
          >
            <img
              className="h-20"
              src={Constants.BSC_LOGO_IMG_URL}
              alt="binance logo"
            ></img>
          </div>
          <div className="flex justify-center">Binance Smart Chain</div>
        </div>

        <div className="cursor-pointer">
          <div
            color="primary"
            className="flex justify-center "
            onClick={async () => {
              switchBlockchainNetwork(Constants.POLYGON_MAINNET_BLOCKCHAIN_ID);
            }}
          >
            <img
              className="h-20"
              src={Constants.POLYGON_LOGO_IMG_URL}
              alt="binance logo"
            ></img>
          </div>
          <div className="flex justify-center">Polygon</div>
        </div>
      </div>
    );
  };

  const renderTestnetBlockchains = () => {
    return (
      <div className="flex justify-evenly">
        <div className="cursor-pointer">
          <div
            color="primary"
            className="flex justify-center "
            onClick={async () => {
              switchBlockchainNetwork(Constants.BINANCE_TESTNET_BLOCKCHAIN_ID);
            }}
          >
            <img
              className="h-20"
              src={Constants.BSC_LOGO_IMG_URL}
              alt="binance logo"
            ></img>
          </div>
          <div className="flex justify-center">Binance Smart Chain Testnet</div>
        </div>

        <div className="cursor-pointer">
          <div
            color="primary"
            className="flex justify-center "
            onClick={async () => {
              switchBlockchainNetwork(
                Constants.POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID
              );
            }}
          >
            <img
              className="h-20"
              src={Constants.POLYGON_LOGO_IMG_URL}
              alt="binance logo"
            ></img>
          </div>
          <div className="flex justify-center">Polygon Mumbai Testnet</div>
        </div>
      </div>
    );
  };
  return (
    <div className="">
      <Modal isOpen={chooseBlockchainModal}>
        <ModalHeader>Change Network</ModalHeader>
        <ModalBody>
          <div className="grid grid-cols-1 divide-y">
            {status === OnboardingStatus.Done && renderMainnetBlockchains()}
            {renderTestnetBlockchains()}
          </div>

          <Button
            color="secondary"
            onClick={() => {
              setChooseBlockchainModal(false);
            }}
          >
            Cancel
          </Button>
        </ModalBody>
      </Modal>

      <div className="nav-container grid justify-items-start  	">
        <Navbar className="h-20 w-full" light expand="md">
          <Container>
            <span>{topLeftTitle}</span>

            <div className="nav-container grid justify-items-end 	">
              <NavbarBrand className="logo" />
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                {renderTreasury()}

                <Nav>
                  <NavItem>
                    <Button
                      className="btn-margin"
                      outline
                      color="secondary"
                      onClick={() => {
                        setChooseBlockchainModal(true);
                      }}
                    >
                      {(chain || {}).name}
                    </Button>
                  </NavItem>
                </Nav>

                <Nav className="d-none d-md-block mr-20 ml-4" navbar>
                  {isAuthenticated && (
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav id="profileDropDown">
                        <img
                          src={user.picture}
                          alt="Profile"
                          className="nav-user-profile rounded-circle"
                          width="50"
                        />
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem header>{user.name}</DropdownItem>
                        <DropdownItem header>{user.email}</DropdownItem>

                        <DropdownItem
                          tag={RouterNavLink}
                          to="/profile"
                          className="dropdown-profile"
                          activeClassName="router-link-exact-active"
                        >
                          <FontAwesomeIcon icon="user" className="mr-3" />{" "}
                          Profile
                        </DropdownItem>
                        <DropdownItem
                          id="qsLogoutBtn"
                          onClick={() => logoutWithRedirect()}
                        >
                          <FontAwesomeIcon icon="power-off" className="mr-3" />{" "}
                          Log out
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  )}
                </Nav>
                {!isAuthenticated && (
                  <Nav className="d-md-none" navbar>
                    <NavItem>
                      <Button
                        id="qsLoginBtn"
                        color="primary"
                        block
                        onClick={() => loginWithRedirect({})}
                      >
                        Log in
                      </Button>
                    </NavItem>
                  </Nav>
                )}
                {isAuthenticated && (
                  <Nav
                    className="d-md-none justify-content-between"
                    navbar
                    style={{ minHeight: 170 }}
                  >
                    <NavItem>
                      <span className="user-info">
                        <img
                          src={user.picture}
                          alt="Profile"
                          className="nav-user-profile d-inline-block rounded-circle mr-3"
                          width="50"
                        />
                        <h6 className="d-inline-block">{user.name}</h6>
                      </span>
                    </NavItem>
                    <NavItem>
                      <FontAwesomeIcon icon="user" className="mr-3" />
                      <RouterNavLink
                        to="/profile"
                        activeClassName="router-link-exact-active"
                      >
                        Profile
                      </RouterNavLink>
                    </NavItem>
                    <NavItem>
                      <FontAwesomeIcon icon="power-off" className="mr-3" />
                      <RouterNavLink
                        to="#"
                        id="qsLogoutBtn"
                        onClick={() => logoutWithRedirect()}
                      >
                        Log out
                      </RouterNavLink>
                    </NavItem>
                  </Nav>
                )}
              </Collapse>
            </div>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

export default NavBar;
