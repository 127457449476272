import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Label, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from "../../components/Buttons";
import { useERC20TokenActions } from "../../hooks/ERC20Actions";
export const CreateBasicERC20TokenComponent = () => {

    const history = useHistory();

    const {createERC20FromTreasury, createdERC20Token } = useERC20TokenActions()


    const [formData, updateFormData] = useState({

    });
    const [loading, setLoading] = useState(false)
    const [loadingMessage, setLoadingMessage] = useState("")

    useEffect(() => {
        if(createdERC20Token?.id){
            history.push(`/admin/erc20/view/${createdERC20Token.id}`)
        }
        

    }, [createdERC20Token, createdERC20Token?.id, history])

    const handleChange = (e) => {
        updateFormData({
            ...formData,
            [e.target.name]: e.target.value.trim()
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        createBasicERC20Token()
    };

    const createBasicERC20Token = async () => {
        setLoadingMessage("please accept the transaction to continue")
        setLoading(true)
        const { name, symbol, logo } = formData
        createERC20FromTreasury({name, symbol,logo} )
    }

    if(loading) {
        return <Loading message={loadingMessage}></Loading>
    }
    return (
        <div className="mb-5">
                <form onSubmit={handleSubmit} style={{ width: "100%" }}>

            <div className="grid grid-cols-2 gap-5">

                    <div>
                        <Label for="tokenName">Token Name</Label>
                        <Input onChange={handleChange} type="text"
                            name="name" placeholder="Token Name" />
                    </div>

                    <div>
                        <Label for="tokenSymbol">Token Symbol</Label>
                        <Input onChange={handleChange} name="symbol" type="text" placeholder="TKN" />
                    </div>

                    <div className="col-span-2">
                        <Label>Token Logo URL</Label>
                        <Input onChange={handleChange} name="logoURL" type="text" />
                    </div>
                    <div className="w-full text-center col-span-2 my-3">

                    <PrimaryButton >Submit</PrimaryButton>

                    </div>

            </div>
            </form>
        </div>
    );
};

export default withAuthenticationRequired(CreateBasicERC20TokenComponent, {
    onRedirecting: () => <Loading />,
});
