import React, { useEffect, useState } from "react";
import { useFaunaUtils } from "../../../../../utils/faunaClient";
import { faunaCollections } from "../../../../../utils/faunaCollections";

import { useBlockOneEndpoint } from "../../../../../utils/blockOneAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useNFTBalances } from "../../../../../hooks/ChainUtils";
import { PrimaryButton } from "../../../../../components/Buttons";
export const BuyNFTInMarketplace = ({setNextStatus}) => {
  const { invokeEndpoint } = useBlockOneEndpoint();
  const { listAllForOwner, findDocumentById } = useFaunaUtils();
  const { user } = useAuth0();
  const { data: NFTBalanceData } = useNFTBalances();
  const [NFTTokenBalance, setNFTTokenBalance] = useState();
  const [loadingMarketplaceList, setLoadingMarketplaceList] = useState(false);

  const [marketplaceLink, setMarketplaceLink] = React.useState(null);
  const [marketplace, setMarketplace] = React.useState(null);
  const [lastNFTCollection, setLastNFTCollection] = useState();

  const hasNFTAcess = (NFTTokenBalance || 0) > 0;

  useEffect(() => {
    const setLastNFT = async () => {
      listAllForOwner(faunaCollections.NFTCollection).then(async (res) => {
        if (res.length > 0) {
          const lastNFT = res[res.length - 1];

          setLastNFTCollection(lastNFT);
        }
      });
    };
    if (!lastNFTCollection) {
      setLastNFT();
    }
  }, [listAllForOwner, lastNFTCollection, findDocumentById]);

  useEffect(() => {
    if (lastNFTCollection && NFTBalanceData && !NFTTokenBalance) {
      const NFTBalance = NFTBalanceData.result.filter((nftBalance) => {
        return nftBalance.token_address === lastNFTCollection.contractAddress;
      });
      console.log(`NFTBalance ${JSON.stringify(NFTBalance)}`);
      setNFTTokenBalance(NFTBalance?.length || 0);
    }
  }, [lastNFTCollection, NFTBalanceData, NFTTokenBalance]);

  useEffect(() => {
    listAllForOwner(faunaCollections.Marketplace).then((res) => {
      console.log(`res: ${JSON.stringify(res)}`);
      if (res.length > 0) {
        console.log(`res[0]: ${JSON.stringify(res[0])}`);
        const marketplace = res[res.length - 1];
        console.log(`marketplace: ${JSON.stringify(marketplace)}`);
        setMarketplace(marketplace);
      } else {
        console.log("not valid");
      }
    });
  }, [listAllForOwner]);

  useEffect(() => {
    const getMarketplaceLink = async () => {
      const createdMarketplaceLink = await invokeEndpoint(`marketplace/link`, {
        marketplaceId: marketplace.id,
        customerId: user.email,
        sessionInfo: [
          {
            key: "string",
            value: "string",
          },
        ],
      });
      console.log(
        `setMarketplaceLink: ${JSON.stringify(createdMarketplaceLink)}`
      );
      setMarketplaceLink(createdMarketplaceLink.link);
    };

    if (marketplace?.id && !marketplaceLink && !loadingMarketplaceList) {
      setLoadingMarketplaceList(true);
      getMarketplaceLink();
    }
  }, [
    invokeEndpoint,
    marketplace,
    marketplaceLink,
    user.email,
    loadingMarketplaceList,
  ]);

  const renderNFTStatus = () => {
    if (hasNFTAcess) {
      return (
        <div>
          You own the NFT that grants access to the application and now have
          access to the application using your email.
          <div className="flex justify-center mt-10">
            <img
              alt="NFT"
              src={lastNFTCollection?.collectionImage}
              className="h-28"
            ></img>
          </div>
          <div className="flex justify-center mt-14">
            <PrimaryButton
              onClick={() => {
                setNextStatus()
              }}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
      );
    }

    if (!hasNFTAcess) {
      return (
        <div>
          You do not own the NFT that grants access to the application, please
          buy the NFT in your marketplace
          <div className="flex justify-center mt-10">

<a
            className="
            text-lg
            hover:
             background-transparent font-bold uppercase px-3 py-1 outline-none "

              target="_blank"
              rel="noopener noreferrer"
              href={marketplaceLink}
              alt="treasury wallet funding"
            >
              <PrimaryButton>

              Buy NFT in Marketplace
              </PrimaryButton>

            </a>
            
            
          </div>
          <div className="flex justify-center mt-10">
            After buying the NFT please refresh the page
          </div>
        </div>
      );
    }
  };
  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Buy NFT to access the application
          </div>

          <div>{renderNFTStatus()}</div>
        </div>
      </div>
    </div>
  );
};
