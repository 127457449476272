import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import { useFaunaUtils } from '../../utils/faunaClient';
import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries"
import { faunaCollections } from "../../utils/faunaCollections";
import {
  useParams
} from "react-router-dom";
import { disconnectMarketplaceNFTInput } from "./marketplaceActions"
import {
  Input
} from 'reactstrap';
import { PrimaryButton } from "../../components/Buttons";
import { useHistory } from 'react-router-dom';

export const MarketplaceEditComponent = () => {
  const history = useHistory();


  const addOrRemoveStyle = `
  inline-block bg-gray-200 cursor-pointer 
  w-40 text-center	 
  rounded-full py-1 text-sm font-semibold text-gray-700 mr-2 mb-2`

  let { marketplaceId } = useParams();

  const { listAllForOwner, createRefForDocument, createDocument } = useFaunaUtils()
  const { findMarketplaceByID, updateMarketplace } = useFaunaGQLUtils()

  const [loading, setLoading] = useState(true)
  const [marketplaceDetails, setMarketplaceDetails] = useState()
  // const [marketplaceNFTsDetails, setMarketplaceNFTsDetails] = useState([])

  const [allNFTs, setAllNFTs] = useState([])

  const [marketplaceNFTIdsToRemove, setMarketplaceNFTIdsToRemove] = useState([])

  const [NFTsToAdd, setNFTsToAdd] = useState([])
  const [showingNFTsNotInMarketplace, setShowingNFTsNotInMarketplace] = useState(false)

  const [formData, updateFormData] = useState({})
  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      console.log("getMarketPlacesFauna start")
      const marketplaceDetails = await findMarketplaceByID(marketplaceId)

      setMarketplaceDetails(marketplaceDetails)

      const allNFTs = await listAllForOwner(faunaCollections.NFTCollection)
      console.log("allNFTs " + JSON.stringify(allNFTs))
      setAllNFTs(allNFTs)
      setLoading(false)


    }
    if(loading){
      getMarketPlacesFauna()

    }
  }, [marketplaceId, listAllForOwner, findMarketplaceByID, loading])

  const renderRemoveNFTButton = (NFTId) => {

    if (marketplaceNFTIdsToRemove.includes(NFTId)) {
      return (
        <div
        onClick={() => {
          setMarketplaceNFTIdsToRemove(oldArray =>
            oldArray.filter(id => id !== NFTId)
          )
        }}
        >
          <span className={addOrRemoveStyle}>
    
          Marked for Removal

          </span>
       
        </div>
      )

    } else {
      return (
        <span className={addOrRemoveStyle}
        onClick={() => {
          setMarketplaceNFTIdsToRemove(oldArray => [
            ...oldArray,
            NFTId
          ])
        }}
        >

          remove
          
        </span>
      )
    }

  }

  const renderAddNFTButton = (NFTId) => {

    if (NFTsToAdd.includes(NFTId)) {

      return (
        <div
        onClick={() => {
          setNFTsToAdd(oldArray =>
            oldArray.filter(id => id !== NFTId)
          )
        }}
        >
                  <span className={addOrRemoveStyle}>

          
              Marked for Addition
            </span>
       
        </div>
      )
    } else {
      return (
        <span className={addOrRemoveStyle}
        onClick={() => {
          setNFTsToAdd(oldArray => [
            ...oldArray,
            NFTId
          ])
        }}
        >
add

        </span>
      )
    }
  }

  const renderNFT = (name, collectionImage, id, remove) => {
    return (
      <div >
        <div class="h-96 w-52 rounded shadow-lg m-5">
          <img
            class="w-52 h-52" src={collectionImage} alt="NFT" />
          <div class="px-6 py-4 h-24">
            <div class="font-bold text-md mb-2">    {name}
            </div>
          </div>
          <div class="px-6 pt-4 h-20">


              {remove ?
                renderRemoveNFTButton(id)

                : renderAddNFTButton(id)
              }
          </div>
        </div>
      </div>
    )

  }

  const handleChange = async (e) => {

    marketplaceDetails[e.target.name] = e.target.value.trim()
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim()
    });
  };
  const renderMarketplaceNFTs = (marketplaceNFTsToRender, remove) => {
    console.log("marketplaceNFTsToRender " + JSON.stringify(marketplaceNFTsToRender))
    const currentNFTs = marketplaceNFTsToRender.map(marketplaceNFT => {
      console.log("marketplaceNFT " + JSON.stringify(marketplaceNFT))
      return renderNFT(marketplaceNFT.NFTCollection.name, marketplaceNFT.NFTCollection.collectionImage,
        marketplaceNFT._id, remove)
    })

    return (
      <div className="grid grid-cols-3"

      >
        {currentNFTs}
       
      </div>
    )

  }

  const updateMarketPlaceSubmit = async () => {
    setLoading(true)

    const promises = NFTsToAdd.map(async nftToAdd => {
      return createDocument(faunaCollections.MarketplaceNFTCollection, {
        NFTCollection: createRefForDocument(faunaCollections.NFTCollection, nftToAdd),
        marketplace: createRefForDocument(faunaCollections.Marketplace, marketplaceId)
      })
    })

    const marketplaceNFTsCreatedIds = await Promise.all(promises)
    console.log("marketplaceNFTsCreatedIds " + marketplaceNFTsCreatedIds)

    console.log("should update " + JSON.stringify(marketplaceDetails))

    const disconnectInput = disconnectMarketplaceNFTInput
      (marketplaceDetails.name, "there should be no symbol here",
      marketplaceDetails.customStyleSheetURL,
      marketplaceDetails.webhookURL,
        marketplaceNFTIdsToRemove)
    await updateMarketplace(marketplaceId, disconnectInput)

    history.push(`/admin/marketplace/view/${marketplaceId}`)

  }


  const renderNFTsNotInMarketplace = () => {
    const marketplaceNFTs = getMarketplaceNFTs()
    const otherNFTs = allNFTs.filter(nft => {
      return marketplaceNFTs.map(x => x.NFTCollection).find(mktNFT => {
        return mktNFT.contractAddress === nft.contractAddress
      }) === undefined
    })
    console.log("otherNFTs " + JSON.stringify(otherNFTs))


    const render = otherNFTs.map(nft => {

      return renderNFT(nft.name, nft.collectionImage,
        nft.id, false)
    })

    return (
      <div className="grid grid-cols-3">
        {render}
      </div>
    )

    // return renderMarketplaceNFTs(otherNFTs)

  }

  const getMarketplaceNFTs = () => {

    return marketplaceDetails.marketplaceNFTCollections.data

  }


  if (loading) {
    return <Loading></Loading>
  }


  return (
    <Container className="mb-5">

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">


        <div className="text-center	w-screen text-lg	m-2">
        Editing {marketplaceDetails.name}
        </div>

        <Table responsive>

          <tbody>
            <tr>
              <td>Name</td>
              <td>{marketplaceDetails.name}</td>
            </tr>

            <tr>
              <td>Marketplace Online at</td>
              <td>
                <a
                  href={`https://marketplace.cactusfam.com/${marketplaceId}`}
                >
                  {`https://marketplace.cactusfam.com/${marketplaceId}`}

                </a>
              </td>
            </tr>
            <tr>
              <td>Webhook URL</td>
              <td>
                <Input
                  name="webhookURL"
                  onChange={handleChange}
                  value={marketplaceDetails.webhookURL} />
              </td>
            </tr>
            <tr>
              <td>Custom CSS</td>
              <td>
                <Input
                  name="customStyleSheetURL"
                  onChange={handleChange}
                  value={marketplaceDetails.customStyleSheetURL} />
              </td>
            </tr>
            <tr>
              <td>NFTs</td>
              <td>

                <div>

                {renderMarketplaceNFTs(
                    getMarketplaceNFTs(),true)}
                </div>
                <div>
                <div>
          <PrimaryButton
          onClick= {() => {
            setShowingNFTsNotInMarketplace(!showingNFTsNotInMarketplace)
          }}
          > 
          {
            showingNFTsNotInMarketplace ?
            "Hide NFTs not in Marketplace" :
            "Show NFTs not in Marketplace"
          }
          </PrimaryButton>
        </div>
        <div className={showingNFTsNotInMarketplace ? "" : "hidden"}>
        {renderNFTsNotInMarketplace()}
        </div>
               

                </div>





              </td>
            </tr>

            <tr>
              <td>
                <PrimaryButton
                className="w-40"
                  onClick={() => {
                    updateMarketPlaceSubmit()
                  }}>
                  save changes
                </PrimaryButton>

              </td>
            </tr>

          </tbody>
        </Table>


      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MarketplaceEditComponent, {
  onRedirecting: () => <Loading />,
});
