import React, { useContext, useEffect, useState } from "react";
import { useNFTActions } from "../../../../../hooks/NFTActions";
import { UserContext } from "../../../../../context/userContext";
import { useGasCheckedComponents } from "../../../../../components/GasCheckedButton";
import { BlockchainAction } from "../../../../../types";
import TreasurySetup from "../../../TreasurySetup";

export const CreateNonFungibleToken = ({ setNextStatus }) => {
  const userContext = useContext(UserContext);

  const {
    ERC20Tokens,
    NFTAddressCreated,
    createNFTCollection,
    creatingNFTCollection,
  } = useNFTActions();

  const { GasCheckedButton } = useGasCheckedComponents(
    BlockchainAction.CreateNftCollection
  );

  const [showChangeImageURL, setShowChangeImageURL] = useState(false);

  const [showFundTreasury, setShowFundTreasury] = useState(false);

  const defaultNFTCollectionData = {
    name: "SaaS Subscription",
    symbol: "ACCS",
    publicMint: true,
    ERC20MintToken: ERC20Tokens[0]?.contractAddress,
    baseTokenURI:
      "https://block-one-asset-properties.vercel.app/api/nft/defintions/",
    mintPrice: 1,
    adminAddress: userContext?.gasAddress,
    collectionImage:
      "https://www.blockonelabs.com/assets/images/demo/purple-ticket.png",
    description: "This NFT grants access to the SaaS Subscription",
  };

  const [NFTCollectionData, setNFTCollectionData] = useState({
    ...defaultNFTCollectionData,
  });

  const updateTokenDetails = async (e) => {
    setNFTCollectionData({
      ...NFTCollectionData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  useEffect(() => {
    if (!NFTCollectionData.ERC20MintToken && ERC20Tokens?.length > 0) {
      setNFTCollectionData({
        ...NFTCollectionData,
        ERC20MintToken: ERC20Tokens[0]?.contractAddress,
      });
    }
  }, [ERC20Tokens, setNFTCollectionData, NFTCollectionData]);

  useEffect(() => {
    if (NFTAddressCreated) {
      setNextStatus();
    }
  }, [setNextStatus, NFTAddressCreated]);

  const renderCreateButton = () => {
    return (
      <div className=" justify-center  text-center -mt-3">
        <GasCheckedButton
          loading={creatingNFTCollection}
          className=""
          onClick={() => {
            createNFTCollection(NFTCollectionData);
          }}
          buttonText={`Create NFT Collection`}
          noGasCallToAction={() => {
            return (
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => {
                  setShowFundTreasury(true);
                }}
              >
                Fund your treasury
              </div>
            );
          }}
        ></GasCheckedButton>
      </div>
    );
  };

  if (showFundTreasury) {
    return (
      <div>
        <div class="w-full py-6 mt-14"></div>
        <div className=" flex place-content-center">
          <div className="border w-1/2 rounded-lg h-96 text-center p-2">
            <TreasurySetup></TreasurySetup>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Create Non Fungible Token Collection
          </div>
          <p class="text-gray-700 text-base">
            <p>
              Create an asset type and set their price to grant access to our
              SaaS, these assets are grouped into <b>collections</b>
            </p>
          </p>

          <div>
            <div className=" mt-3 grid grid-flow-col cols-4 gap-20 pt-2">
              <div class="mb-4">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="name"
                >
                  Collection name
                </label>
                <input
                  onChange={updateTokenDetails}
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  name="name"
                  type="text"
                  placeholder="SaaS Subscription"
                />
              </div>
              <div class="mb-6">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="password"
                >
                  Mint Price
                </label>
                <input
                  onChange={updateTokenDetails}
                  class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                  type={`number`}
                  name="mintPrice"
                  placeholder="1"
                />
              </div>
              <div class="mb-6">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="password"
                >
                  Currency (From previous step)
                </label>
                <div className="mt-2" />
                {ERC20Tokens[0]?.name}
              </div>

              <div class="mb-6">
                <label
                  class="block text-gray-700 text-sm font-bold mb-2"
                  for="password"
                >
                  Collection Image{" "}
                </label>
                <div className="mt-2" />

                {/*  */}
                <div className="flex justify-center">
                  <img
                    className=" w-20 h-20"
                    src={NFTCollectionData.collectionImage}
                    alt="collection"
                  />
                </div>
                <div>
                  {!showChangeImageURL && (
                    <div
                      className="cursor-pointer text-xs flex justify-center"
                      onClick={() => {
                        setShowChangeImageURL(true);
                      }}
                    >
                      Change image
                    </div>
                  )}

                  {showChangeImageURL && (
                    <input
                      onChange={updateTokenDetails}
                      class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                      name="collectionImage"
                      placeholder={NFTCollectionData.collectionImage}
                    />
                  )}
                </div>
              </div>
            </div>

            <div class="flex items-center justify-between"></div>

            <div>{/* {JSON.stringify(createdERC20Token)} */}</div>
          </div>
          {renderCreateButton()}
        </div>
      </div>
    </div>
    //         <div >

    //             <div class="flex flex-row min-h-screen justify-center items-center">

    //             <div class="h-96 rounded overflow-hidden shadow-lg ">
    //   <div class="px-6 py-4">
    //     <div class="font-bold text-xl mb-2">Create NON Fungible Token</div>
    //     <p class="text-gray-700 text-base">
    //

    //     </p>
    //     <div>
    //         <div>
    //         <div>

    // <div className=" mt-3 grid grid-flow-col auto-cols-max gap-20">

    // <div class="mb-4">
    // <label class="block text-gray-700 text-sm font-bold mb-2" for="name">
    // Name
    // </label>
    // <input
    // onChange={updateTokenDetails}
    // class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="name" type="text" placeholder="Note Token"/>
    // </div>
    // <div class="mb-6">
    // <label class="block text-gray-700 text-sm font-bold mb-2" for="password">
    // Symbol
    // </label>
    // <input
    //     onChange={updateTokenDetails}

    // class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline" id="password"  placeholder="NOTE"/>
    // </div>
    // </div>

    // <div class="flex items-center justify-between">

    // </div>

    // <div>
    // {/* {JSON.stringify(createdERC20Token)} */}

    // </div>
    // </div>
    //         </div>
    //         <div>

    //         </div>
    //     </div>
    //     {renderCreateButton()}
    //   </div>

    // </div>
    // </div>

    //         </div>
  );
};
