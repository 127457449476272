import { NftCollectionInput, NftType } from "../../types/index"


function createNftInput (
     contractAddress: string, owner: string,
     description: string, mintDecimals: number, mintTokenName: string,
     publicMint :boolean, collectionImage: string,banner: string, mintPrice:number) : NftCollectionInput {

    console.log("createNftInput collectionImage " + collectionImage)

    console.log("createNftInput banner " + banner)

    return {publicMint, contractAddress, owner, description,
        mintDecimals,mintTokenName, type: NftType.Common, collectionImage, banner,mintPrice: String(mintPrice)}
};

export {createNftInput} ;