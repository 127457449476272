import React, { useEffect, useState } from "react";
import { useFaunaUtils } from "../../../../../utils/faunaClient";
import { faunaCollections } from "../../../../../utils/faunaCollections";

import { useBlockOneEndpoint } from "../../../../../utils/blockOneAPI";
import { useNFTBalances, useERC20Balances, useMoralis } from "../../../../../hooks/ChainUtils";
import { PrimaryButton } from "../../../../../components/Buttons";
import Loading from "../../../../../components/Loading";
import { useGasCheckedComponents } from "../../../../../components/GasCheckedButton";
import { BlockchainAction } from "../../../../../types";
export const GetCoins = ({ setNextStatus }) => {
  const { invokeEndpoint } = useBlockOneEndpoint();
  const { listAllForOwner, findDocumentById } = useFaunaUtils();
  const { data: NFTBalanceData } = useNFTBalances();
  const { data: erc20BalanceData, fetchERC20Balances } = useERC20Balances();
  const { account } = useMoralis();
  const [mintToken, setMintToken] = useState();
  const [mintTokenBalance, setMintTokenBalance] = useState();
  const [NFTTokenBalance, setNFTTokenBalance] = useState();

  const { GasCheckedButton } = useGasCheckedComponents(
    BlockchainAction.MintTokens
  );
  const [lastNFTCollection, setLastNFTCollection] = useState();
  const [reloadBalances, setReloadBalances] = useState(false);
  const [mintingCoins, setMintingCoins] = useState(false);

  const formattedNFTPrice =
    lastNFTCollection?.mintPrice / 10 ** (mintToken?.decimals || 0);
  const formattedMintTokenBalance =
    (mintTokenBalance || 0) / 10 ** (mintToken?.decimals || 0);

  useEffect(() => {
    const setLastNFT = async () => {
      listAllForOwner(faunaCollections.NFTCollection).then(async (res) => {
        if (res.length > 0) {
          const lastNFT = res[res.length - 1];
          console.log(`lastNFT: ${JSON.stringify(lastNFT)}`);
          const mintTokenId = lastNFT.mintToken["@ref"]["id"];
          const mintToken = await findDocumentById(
            faunaCollections.ERC20Token,
            mintTokenId
          );
          console.log(
            `mintToken1 ${JSON.stringify(mintToken.contractAddress)}`
          );
          setMintToken(mintToken);

          setLastNFTCollection(lastNFT);
        }
      });
    };
    if (!lastNFTCollection) {
      setLastNFT();
    }
  }, [listAllForOwner, lastNFTCollection, findDocumentById]);

  useEffect(() => {
    if (
      (lastNFTCollection &&
        mintToken &&
        NFTBalanceData &&
        erc20BalanceData &&
        !mintTokenBalance &&
        !NFTTokenBalance) ||
      reloadBalances
    ) {
      console.log(`GETTING BALANCES`);
      erc20BalanceData.forEach((erc20Balance) => {
        if (erc20Balance.token_address === mintToken.contractAddress) {
          setMintTokenBalance(erc20Balance.balance);
        }
      });
      const NFTBalance = NFTBalanceData.result.filter((nftBalance) => {
        return nftBalance.token_address === lastNFTCollection.contractAddress;
      });
      console.log(`NFTBalance ${JSON.stringify(NFTBalance)}`);
      setNFTTokenBalance(NFTBalance?.length || 0);
    }
  }, [
    lastNFTCollection,
    mintToken,
    NFTBalanceData,
    erc20BalanceData,
    mintTokenBalance,
    NFTTokenBalance,
    reloadBalances,
  ]);

  const renderMintCoinsButton = () => {

    const diffToMintFormat = formattedNFTPrice - formattedMintTokenBalance;
    const enoughCoins = diffToMintFormat <= 0;
    const mintTokensCallback =  async() => {
      await fetchERC20Balances();
        setReloadBalances(true)
      setTimeout(() => {;
        setMintingCoins(false);

      }, 5000)
      
    };
    if (enoughCoins) {
      return (
        <div>
          You have enough coins to buy the NFT
          <div>
            <PrimaryButton
            className=" inset-x-0 bottom-0 mt-32"
              onClick={() => {
                setNextStatus();
              }}
            >
              Next
            </PrimaryButton>
          </div>
        </div>
      );
    }
    return (
      <div className="flex justify-center mt-4">
        <GasCheckedButton
        loading={mintingCoins}
          onClick={() => {
            setMintingCoins(true);
            invokeEndpoint(
              "ERC20/mint",
              {
                tokenAddress: mintToken?.contractAddress,
                toWallet: account,
                amount: (
                  diffToMintFormat *
                  10 ** (mintToken?.decimals || 0)
                ).toString(),
              },
              mintTokensCallback
            );
          }}
          buttonText=
          {`Mint ${diffToMintFormat} Coin${diffToMintFormat > 1 ? "s" : ""}`}

          noGasCallToAction={() => {
            return (
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => {
                  // setShowFundTreasury(true);
                }}
              >
                Fund your treasury
              </div>
            );
          }}

        />
      </div>
    );
  };

  if(!erc20BalanceData || !NFTBalanceData) {
    return <Loading />;
  }


  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Get Coins to Buy the NFT
          </div>
          <div>
            Coins are usually bought in the open market, your treasury can also
            create coins, lets create and send you some coins.
          </div>
          <div class="text-gray-700 text-base text-center mt-10">
            You curently have {formattedMintTokenBalance}
            <span className="font-bold"> {mintToken?.symbol}</span> tokens and
            need {formattedNFTPrice}{" "}
            <span className="font-bold">{mintToken?.symbol}</span> tokens to buy
            the NFT
            {renderMintCoinsButton()}
          </div>
        </div>
      </div>
    </div>
  );
};
