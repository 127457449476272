import React from "react";

const PrimaryButton = (props) => {
  const enabledClass = `bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded`;
  const disabledClass = `bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed`;
  const disabledSuccessClass = `bg-green-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed`;
  const buttonClass = props.disabled ? disabledClass : props.disabledSuccess ? disabledSuccessClass : enabledClass;
  return (
    <button {...props} className={`${buttonClass} ${props.className}`}></button>
  );
};

const InfoButton = (props) => {
  const enabledClass = `bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow`;
  return (
    <button
      {...props}
      className={`${enabledClass} ${props.className}`}
    ></button>
  );
};

const LinkButton = (props) => {
    const enabledClass = `no-underline hover:underline cursor-pointer`;
    return (

        <div
      {...props}
      className={`${enabledClass} ${props.className}`}
    ></div>

        // <button
        // className={`${enabledClass} ${props.className}`}>
        //      {...props}
        // </button>

        


    );
  };

export { PrimaryButton, InfoButton, LinkButton };
