import React, { useState } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useNFTDropDownUtils } from "../../components/NFTsDropdown";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { Input } from "reactstrap";
import { useGasCheckedComponents } from "../../components/GasCheckedButton";
import { BlockchainAction } from "../../types";
export const NFTMintComponent = () => {
  const { invokeEndpoint } = useBlockOneEndpoint();


  const {GasCheckedButton} = useGasCheckedComponents(BlockchainAction.CreateMarketplace)
  const { NFTCollectionsDropdown, selectedNFTCollection } =
    useNFTDropDownUtils();
  const [mintData, setMintData] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value;
    setMintData({ ...mintData, [targetName]: targetValue });
  };

  return (
    <div>
      <NFTCollectionsDropdown />
      <div>
        <div className="grid grid-cols-2 mt-4 mb-4">
          <div>To Wallet</div>
          <div>Amount</div>
          <Input
            name="to"
            className="w-10/12"
            value={mintData.to}
            onChange={handleChange}
            // value={NFdivetails.banner}
          ></Input>
          <Input
            name="amount"
            className="w-10/12"
            value={mintData?.amount}
            onChange={handleChange}
            // value={NFdivetails.banner}
          ></Input>
        </div>
      </div>
      <div className="flex justify-center">
        <GasCheckedButton
        loading={loading}
        disabled={!selectedNFTCollection || !mintData.to || !mintData.amount}
          onClick={() => {
            setLoading(true);
            invokeEndpoint("nft/mint", {
              to: mintData.to,
              NFTCollectionAddress: selectedNFTCollection?.contractAddress,
              amount: mintData.amount,
            },() => {
                setLoading(false)

            });
          }}
          buttonText={`Mint ${mintData.amount || 0} NFT(s)`}
        >
          
        </GasCheckedButton>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(NFTMintComponent, {
  onRedirecting: () => <Loading />,
});
