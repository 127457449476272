// import React, { useContext, useEffect, useState } from "react";
import React from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PrimaryButton, LinkButton } from "../../components/Buttons";
import { useCactusChain } from "../../hooks/ChainUtils";
import NavBar from "./../../components/NavBar";
import { Link } from "react-router-dom";
import Constants from "../../utils/constants";
import OnboardingChooseNetwork from "./OnboardingChooseNetwork";
import { useOnboardingActions } from "../../hooks/OnboardingActions";
import { OnboardingStatus } from "../../types";
export const Onboarding = ({setOnboardingDone}) => {

  const { chain } = useCactusChain();

  const { updateOnboardingStatus } = useOnboardingActions();

  const renderActionPanel = () => {
    if (!chain) {
      return <OnboardingChooseNetwork />;
    }
    console.log(`chain is ${chain}`);
    if (
      chain.chainId !== Constants.BINANCE_TESTNET_BLOCKCHAIN_ID &&
      chain.chainId !== Constants.POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID
    ) {
      return <OnboardingChooseNetwork />;
    }

    return deployTemplates();
  };

  const deployTemplates = () => {
    return (
      <div className="grid grid-cols-3 gap-20">
        <div>
          <div>
            <img
              className="h-40 w-full mb-4"
              alt="Saas"
              src="
              
              https://www.fossmint.com/wp-content/uploads/2020/07/Best-Cross-Platform-Note-Taking-Apps.png
              "
            ></img>
          </div>

          <Link to="/demosaas">
            <PrimaryButton>Build Saas</PrimaryButton>
          </Link>
        </div>
        <div>
          <div className="flex justify-center">
            <img
              className="h-40  mb-4"
              alt="Saas"
              src="
              https://cdn-icons-png.flaticon.com/512/3515/3515402.png
"
            ></img>
          </div>

          <PrimaryButton
          disabled={true}>
            NFT Access
          </PrimaryButton>
          <div
          className="text-red-800	"
          >
            Coming soon
          </div>
        </div>
        
        <div>
          <div className="flex justify-center">
            <img
              className="h-40 w-full mb-4"
              alt="Saas"
              src="
              https://images-na.ssl-images-amazon.com/images/I/91dxwP71DZL.png
          "
            ></img>
          </div>

          <PrimaryButton
          disabled={true}
          >Build Game</PrimaryButton>
          <div
          className="text-red-800	"
          >
            Coming soon
          </div>
        </div>
        <div className="col-span-3 flex justify-end mt-2 mr-2">
          <LinkButton
          onClick={async () => {
            await updateOnboardingStatus(OnboardingStatus.Done);
            setOnboardingDone()
          }}
          >Skip Tutorial</LinkButton>
        </div>
      </div>
    );
  };

  return (
    <div>
      <NavBar />

      <div className="flex justify-center text-3xl">
          Follow along with our tutorials
      </div>

      <div class="w-full py-6"></div>
      <div className=" flex place-content-center">
        <div className="border w-10/12 rounded-lg h-96 text-center p-2">
          {renderActionPanel()}
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(Onboarding, {
  onRedirecting: () => <Loading />,
});
