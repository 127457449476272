import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries"
import {
  useParams
} from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons";
import { Marketplace } from "../../types";


interface RouteParams {
  marketplaceId: string;
}
export const MarketplaceViewComponent = () => {

  let { marketplaceId } = useParams<RouteParams>();

  const history = useHistory();

  const { findMarketplaceByID } = useFaunaGQLUtils()

  const [loading, setLoading] = useState(true)
  const [marketplaceDetails, setMarketplaceDetails] = useState<Marketplace>()
  // const [marketplaceNFTsDetails, setMarketplaceNFTsDetails] = useState([])

  useEffect(() => {
    const getMarketPlacesFauna = async () => {

      const mkts = await findMarketplaceByID(marketplaceId)
      setMarketplaceDetails(mkts)
      setLoading(false)
    }
    getMarketPlacesFauna()
  }, [marketplaceId, findMarketplaceByID, setMarketplaceDetails])

  const renderMarketplaceNFTs = () => {
    return marketplaceDetails?.marketplaceNFTCollections?.data.map(NFTDetails => {
      console.log(" NFTDetails " + JSON.stringify(NFTDetails))
      return (

        <span >
          <div className="h-80 w-52 rounded shadow-lg m-5">
            <img
              className="w-52 h-52" src={NFTDetails?.NFTCollection?.collectionImage || ""} alt="NFT" />

            <div className="px-6 py-4 h-24">
              <div className="font-bold text-md mb-2">    {NFTDetails?.NFTCollection?.name}
              </div>
            </div>

          </div>
        </span>

      )
    })

  }

  if (loading || !marketplaceDetails) {
    return <Loading></Loading>
  }


  return (
    <Container className="mb-5">

      <Row className="align-items-center profile-header mb-5 text-center text-md-left">

        <div className="w-screen">
          <h2> Marketplace View</h2>
          <PrimaryButton
          className="float-right"
          onClick={() => {
            history.push(`/admin/marketplace/edit/${marketplaceId}`)
          }}> Edit Marketplace</PrimaryButton>
        </div>

        <Table responsive>

          <tbody>
            <tr>
              <td>Name</td>
              <td>{marketplaceDetails.name}</td>
            </tr>

            <tr>
              <td>Marketplace Online at</td>
              <td>
                <a
                  href={`https://marketplace.cactusfam.com/${marketplaceId}/`}
                >
                  {`https://marketplace.cactusfam.com/${marketplaceId}/`}

                </a>
              </td>
            </tr>

            <tr>
              <td>NFTs</td>
              <td>

                <div className="grid grid-cols-3 gap-4">


                  {renderMarketplaceNFTs()}

                </div>

              </td>

            </tr>






          </tbody>
        </Table>


      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(MarketplaceViewComponent, {
  onRedirecting: () => <Loading />,
});
