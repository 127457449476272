
import { ethers } from "ethers"
const useValidateFormValues = () => {



    const validateFormValues = (formData: any) => {
        const formErrors: any = {}

        Object.keys(formData).forEach(key => {
            if (formData[key] == null) {
                formErrors[key] = "cant be empty";
            }
            if (key.toLocaleLowerCase().indexOf("address") > -1 && ethers) {
                const isAddress = ethers.utils.isAddress(formData[key]);
                console.log("isAddress " + isAddress)
                if (!isAddress) {
                    console.log("invalid Adress format  " + formData[key])
                    formErrors[key] = "invalid Adress format";

                }
                console.log("web3 validation required")
            }
        })

        return formErrors;

    }

      
  function IsJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

    return { validateFormValues, IsJsonString }

    

}

export { useValidateFormValues }