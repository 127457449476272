import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Link } from "react-router-dom";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import CactusTransparentLogo from "../../assets/transparent-cactus-logo.png"
export const SideMenu = () => {
  return (
    <>
      <ProSidebar>
        <div className="flex items-center justify-center h-20 shadow-md">
          <Link to="/" className=" hover:no-underline">
            <div className="w-20 h-20">
              <img alt="logo of a cactus" src={CactusTransparentLogo}></img>
              

            </div>
          </Link>
        </div>
        <Menu iconShape="square">
          <SubMenu title="Tokens">
            <SubMenu title="ERC20">
              <MenuItem>
                {" "}
                Create Liquidity Token
                <Link to="/admin/createLiquidityToken" />
              </MenuItem>
              <MenuItem>
                {" "}
                Create Fungible Token
                <Link to="/admin/createBasicERC20Token" />
              </MenuItem>

              <MenuItem>
                ERC20 List
                <Link to="/admin/erc20/list" />
              </MenuItem>

              <MenuItem>
                Import ERC20
                <Link to="/admin/erc20/import" />
              </MenuItem>
            </SubMenu>
            <SubMenu title="NFTs">
              <MenuItem>
                Create NFT Collection
                <Link to="/admin/createNFT" />
              </MenuItem>
              <MenuItem>
                NFT List
                <Link to="/admin/nft/list" />
              </MenuItem>

              <MenuItem>
              NFT Traits
                <Link to="/admin/nft/addImages" />
              </MenuItem>

              <MenuItem>
                Mint NFTs
                <Link to="/admin/nft/mint" />
              </MenuItem>

              <MenuItem>
                Generative Art
                <Link to="/admin/GenerateArt" />
              </MenuItem>
            </SubMenu>
          </SubMenu>
        
          {/* <SubMenu title="Vaults">
            <MenuItem>
              Create Vault
              <Link to="/admin/vaults/create" />
            </MenuItem>

            
            <MenuItem>
               Vault List
              <Link to="admin/vaults/list" />
            </MenuItem>

            
            </SubMenu> */}

          <SubMenu title="Marketplaces">
            <MenuItem>
              Create Marketplace
              <Link to="/admin/createMarketplace" />
            </MenuItem>
            <MenuItem>
              Marketplace Lists
              <Link to="/admin/marketplace/list" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="APIs">
            <MenuItem>
              API Spec
              <Link to="/admin/apiSpec" />
            </MenuItem>
          </SubMenu>

          <SubMenu title="Organization ">
            <MenuItem>
              Organization Details
              <Link to={`/admin/organization`} />
            </MenuItem>
          </SubMenu>
        </Menu>
      </ProSidebar>
    </>
  );
};

export default withAuthenticationRequired(SideMenu, {
  onRedirecting: () => <Loading />,
});
