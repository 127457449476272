import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../../context/userContext";
import { useCreateMarketplace } from "../../../../marketplace/marketplaceActions";
import { useFaunaUtils } from "../../../../../utils/faunaClient";
import { faunaCollections } from "../../../../../utils/faunaCollections";
import Constants from "../../../../../utils/constants";
import { useGasCheckedComponents } from "../../../../../components/GasCheckedButton";
import { BlockchainAction } from "../../../../../types";
export const CreateMarketplace = ({ setNextStatus }) => {
  const { listAllForOwner } = useFaunaUtils();
  const [lastNFTCollection, setLastNFTCollection] = useState();
  const [createdMarketplace, setCreatedMarketplace] = useState();
  const { GasCheckedButton } = useGasCheckedComponents(BlockchainAction.CreateMarketplace)


  const userContext = useContext(UserContext);

  const { createMarketplace } = useCreateMarketplace();
  const [creatingMarketplace, setCreatingMarketplace] = useState(false);

  useEffect(() => {
    const setLastNFT = async () => {
      listAllForOwner(faunaCollections.NFTCollection).then((res) => {
        if (res.length > 0) {
          setLastNFTCollection(res[res.length - 1]);
        }
      });
    };
    if (!lastNFTCollection) {
      setLastNFT();
    }
  }, [listAllForOwner, lastNFTCollection]);

  const renderCreateButton = () => {

    const data = {
      feeRecipient: userContext?.gasAddress,
      marketplaceFee: 0,
    };

    const callback = (createdDoc) => {
      setCreatedMarketplace(createdDoc);
      setCreatingMarketplace(false);
      console.log(`createdDoc ${JSON.stringify(createdDoc)}`);
    };

    const formData = {
      name: "Application Marketplace",
      bannerImageURL: "",
      marketplaceFee: 0,
      feeRecipientAddress: userContext?.gasAddress,
      marketplaceNFTCollections: [lastNFTCollection],
      buildTag: Constants.MARKETPLACE_BUILD_TAG,
    };
    if (!createdMarketplace) {
      return (
        <div className="flex place-content-center mt-2	">
          <GasCheckedButton
          loading={creatingMarketplace}
            onClick={() => {
              setCreatingMarketplace(true);
              createMarketplace(data, formData, callback);
            }}
            buttonText={`Create Marketplace`}
            noGasCallToAction={() => {
              return (
                <div
                  className="flex justify-center cursor-pointer"
                  onClick={() => {
                    // setShowFundTreasury(true);
                  }}
                >
                  Fund your treasury
                </div>
              );
            }}
          >
            
          </GasCheckedButton>
        </div>
      );
    }
    setNextStatus();
  };

  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-3">
          <div class="font-bold text-xl mb-2 text-center">
            Create your marketplace
          </div>
          <p class="text-gray-700 text-base">
            The marketplace is public site where customers will be able to buy the NFTs that grant access to the application.
          </p>
          <div>
            <div className="text-center justify-center content-center place-content-center		">
              <div>
                <div class="text-lg mb-2 text-center mt-2">
                  NFT Collections Listed In Marketplace
                </div>
              </div>

              <div className="flex content-center	place-content-center">
                <div className="shadow-lg drop-shadow	 w-32 p-2">
                  <div className="flex content-center	place-content-center	">
                    <img
                      className="h-20"
                      src={lastNFTCollection?.collectionImage}
                      alt={lastNFTCollection?.name}
                    ></img>
                  </div>
                  <div>{lastNFTCollection?.name}</div>
                </div>
              </div>
            </div>
          </div>
          {renderCreateButton()}
        </div>
      </div>
    </div>
  );
};
