export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Long: any;
  Time: any;
};

export enum BlockchainAction {
  CreateMarketplace = 'CREATE_MARKETPLACE',
  CreateNftCollection = 'CREATE_NFT_COLLECTION',
  MintTokens = 'MINT_TOKENS',
  TransferTokens = 'TRANSFER_TOKENS'
}

export type CollectionNftAttribute = {
  __typename?: 'CollectionNFTAttribute';
  NFTCollection?: Maybe<NftCollection>;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type CollectionNftAttributeInput = {
  NFTCollection?: InputMaybe<CollectionNftAttributeNftCollectionRelation>;
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type CollectionNftAttributeNftCollectionRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftCollectionInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type CollectionNftAttributePage = {
  __typename?: 'CollectionNFTAttributePage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<CollectionNftAttribute>>;
};

export type Erc20Token = {
  __typename?: 'ERC20Token';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  contractAddress: Scalars['String'];
  creator: Scalars['String'];
  decimals?: Maybe<Scalars['Int']>;
  imported?: Maybe<Scalars['Boolean']>;
  logoURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['Int']>;
  type: Erc20TokenType;
};

export type Erc20TokenInput = {
  contractAddress: Scalars['String'];
  creator: Scalars['String'];
  decimals?: InputMaybe<Scalars['Int']>;
  imported?: InputMaybe<Scalars['Boolean']>;
  logoURL?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner: Scalars['String'];
  symbol?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['Int']>;
  type: Erc20TokenType;
};

export enum Erc20TokenType {
  Basic = 'BASIC',
  Liquidity = 'LIQUIDITY'
}

export type GasEstimate = {
  __typename?: 'GasEstimate';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  action: BlockchainAction;
  blockchain: Scalars['String'];
  gasRequired: Scalars['Int'];
};

export type GasEstimateInput = {
  action: BlockchainAction;
  blockchain: Scalars['String'];
  gasRequired: Scalars['Int'];
};

export type Marketplace = {
  __typename?: 'Marketplace';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  blockchain?: Maybe<Scalars['String']>;
  blockchainId?: Maybe<Scalars['String']>;
  buildTag?: Maybe<Scalars['String']>;
  contractAddres?: Maybe<Scalars['String']>;
  customStyleSheetURL?: Maybe<Scalars['String']>;
  feeRecipient?: Maybe<Scalars['String']>;
  marketplaceFee?: Maybe<Scalars['String']>;
  marketplaceNFTCollections: MarketplaceNftCollectionPage;
  name: Scalars['String'];
  organization?: Maybe<Organization>;
  owner?: Maybe<Scalars['String']>;
  symbol: Scalars['String'];
  vaults: MarketplaceVaultPage;
  webhookURL?: Maybe<Scalars['String']>;
};


export type MarketplaceMarketplaceNftCollectionsArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};


export type MarketplaceVaultsArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};

export type MarketplaceInput = {
  blockchain?: InputMaybe<Scalars['String']>;
  blockchainId?: InputMaybe<Scalars['String']>;
  buildTag?: InputMaybe<Scalars['String']>;
  contractAddres?: InputMaybe<Scalars['String']>;
  customStyleSheetURL?: InputMaybe<Scalars['String']>;
  feeRecipient?: InputMaybe<Scalars['String']>;
  marketplaceFee?: InputMaybe<Scalars['String']>;
  marketplaceNFTCollections?: InputMaybe<MarketplaceMarketplaceNftCollectionsRelation>;
  name: Scalars['String'];
  organization?: InputMaybe<MarketplaceOrganizationRelation>;
  owner?: InputMaybe<Scalars['String']>;
  symbol: Scalars['String'];
  vaults?: InputMaybe<MarketplaceVaultsRelation>;
  webhookURL?: InputMaybe<Scalars['String']>;
};

export type MarketplaceMarketplaceNftCollectionsRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<MarketplaceNftCollectionInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MarketplaceNftCollection = {
  __typename?: 'MarketplaceNFTCollection';
  NFTCollection?: Maybe<NftCollection>;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  marketplace?: Maybe<Marketplace>;
  status?: Maybe<Scalars['String']>;
};

export type MarketplaceNftCollectionInput = {
  NFTCollection?: InputMaybe<MarketplaceNftCollectionNftCollectionRelation>;
  marketplace?: InputMaybe<MarketplaceNftCollectionMarketplaceRelation>;
  status?: InputMaybe<Scalars['String']>;
};

export type MarketplaceNftCollectionMarketplaceRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<MarketplaceInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceNftCollectionNftCollectionRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftCollectionInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceNftCollectionPage = {
  __typename?: 'MarketplaceNFTCollectionPage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<MarketplaceNftCollection>>;
};

export type MarketplaceOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceVault = {
  __typename?: 'MarketplaceVault';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  marketplace?: Maybe<Marketplace>;
  vault?: Maybe<Vault>;
};

export type MarketplaceVaultInput = {
  marketplace?: InputMaybe<MarketplaceVaultMarketplaceRelation>;
  vault?: InputMaybe<MarketplaceVaultVaultRelation>;
};

export type MarketplaceVaultMarketplaceRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<MarketplaceInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceVaultPage = {
  __typename?: 'MarketplaceVaultPage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<MarketplaceVault>>;
};

export type MarketplaceVaultVaultRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<VaultInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type MarketplaceVaultsRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<MarketplaceVaultInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createCollectionNFTAttribute: CollectionNftAttribute;
  createERC20Token: Erc20Token;
  createGasEstimate: GasEstimate;
  createMarketplace: Marketplace;
  createMarketplaceNFTCollection: MarketplaceNftCollection;
  createMarketplaceVault: MarketplaceVault;
  createNFT: Nft;
  createNFTAttribute: NftAttribute;
  createNFTCollection: NftCollection;
  createOnboarding: Onboarding;
  createOrganization: Organization;
  createOrganizationCustomer: OrganizationCustomer;
  createOrganizationGasAccount: OrganizationGasAccount;
  createSessionNonce: SessionNonce;
  createUser: User;
  createVault: Vault;
  createVaultProfile: VaultProfile;
  deleteCollectionNFTAttribute?: Maybe<CollectionNftAttribute>;
  deleteERC20Token?: Maybe<Erc20Token>;
  deleteGasEstimate?: Maybe<GasEstimate>;
  deleteMarketplace?: Maybe<Marketplace>;
  deleteMarketplaceNFTCollection?: Maybe<MarketplaceNftCollection>;
  deleteMarketplaceVault?: Maybe<MarketplaceVault>;
  deleteNFT?: Maybe<Nft>;
  deleteNFTAttribute?: Maybe<NftAttribute>;
  deleteNFTCollection?: Maybe<NftCollection>;
  deleteOnboarding?: Maybe<Onboarding>;
  deleteOrganization?: Maybe<Organization>;
  deleteOrganizationCustomer?: Maybe<OrganizationCustomer>;
  deleteOrganizationGasAccount?: Maybe<OrganizationGasAccount>;
  deleteSessionNonce?: Maybe<SessionNonce>;
  deleteUser?: Maybe<User>;
  deleteVault?: Maybe<Vault>;
  deleteVaultProfile?: Maybe<VaultProfile>;
  partialUpdateCollectionNFTAttribute?: Maybe<CollectionNftAttribute>;
  partialUpdateERC20Token?: Maybe<Erc20Token>;
  partialUpdateGasEstimate?: Maybe<GasEstimate>;
  partialUpdateMarketplace?: Maybe<Marketplace>;
  partialUpdateMarketplaceNFTCollection?: Maybe<MarketplaceNftCollection>;
  partialUpdateMarketplaceVault?: Maybe<MarketplaceVault>;
  partialUpdateNFT?: Maybe<Nft>;
  partialUpdateNFTAttribute?: Maybe<NftAttribute>;
  partialUpdateNFTCollection?: Maybe<NftCollection>;
  partialUpdateOnboarding?: Maybe<Onboarding>;
  partialUpdateOrganization?: Maybe<Organization>;
  partialUpdateOrganizationCustomer?: Maybe<OrganizationCustomer>;
  partialUpdateOrganizationGasAccount?: Maybe<OrganizationGasAccount>;
  partialUpdateSessionNonce?: Maybe<SessionNonce>;
  partialUpdateUser?: Maybe<User>;
  partialUpdateVault?: Maybe<Vault>;
  partialUpdateVaultProfile?: Maybe<VaultProfile>;
  updateCollectionNFTAttribute?: Maybe<CollectionNftAttribute>;
  updateERC20Token?: Maybe<Erc20Token>;
  updateGasEstimate?: Maybe<GasEstimate>;
  updateMarketplace?: Maybe<Marketplace>;
  updateMarketplaceNFTCollection?: Maybe<MarketplaceNftCollection>;
  updateMarketplaceVault?: Maybe<MarketplaceVault>;
  updateNFT?: Maybe<Nft>;
  updateNFTAttribute?: Maybe<NftAttribute>;
  updateNFTCollection?: Maybe<NftCollection>;
  updateOnboarding?: Maybe<Onboarding>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationCustomer?: Maybe<OrganizationCustomer>;
  updateOrganizationGasAccount?: Maybe<OrganizationGasAccount>;
  updateSessionNonce?: Maybe<SessionNonce>;
  updateUser?: Maybe<User>;
  updateVault?: Maybe<Vault>;
  updateVaultProfile?: Maybe<VaultProfile>;
};


export type MutationCreateCollectionNftAttributeArgs = {
  data: CollectionNftAttributeInput;
};


export type MutationCreateErc20TokenArgs = {
  data: Erc20TokenInput;
};


export type MutationCreateGasEstimateArgs = {
  data: GasEstimateInput;
};


export type MutationCreateMarketplaceArgs = {
  data: MarketplaceInput;
};


export type MutationCreateMarketplaceNftCollectionArgs = {
  data: MarketplaceNftCollectionInput;
};


export type MutationCreateMarketplaceVaultArgs = {
  data: MarketplaceVaultInput;
};


export type MutationCreateNftArgs = {
  data: NftInput;
};


export type MutationCreateNftAttributeArgs = {
  data: NftAttributeInput;
};


export type MutationCreateNftCollectionArgs = {
  data: NftCollectionInput;
};


export type MutationCreateOnboardingArgs = {
  data: OnboardingInput;
};


export type MutationCreateOrganizationArgs = {
  data: OrganizationInput;
};


export type MutationCreateOrganizationCustomerArgs = {
  data: OrganizationCustomerInput;
};


export type MutationCreateOrganizationGasAccountArgs = {
  data: OrganizationGasAccountInput;
};


export type MutationCreateSessionNonceArgs = {
  data: SessionNonceInput;
};


export type MutationCreateUserArgs = {
  data: UserInput;
};


export type MutationCreateVaultArgs = {
  data: VaultInput;
};


export type MutationCreateVaultProfileArgs = {
  data: VaultProfileInput;
};


export type MutationDeleteCollectionNftAttributeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteErc20TokenArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteGasEstimateArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketplaceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketplaceNftCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMarketplaceVaultArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNftArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNftAttributeArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteNftCollectionArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOnboardingArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationCustomerArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteOrganizationGasAccountArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteSessionNonceArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVaultArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVaultProfileArgs = {
  id: Scalars['ID'];
};


export type MutationPartialUpdateCollectionNftAttributeArgs = {
  data: PartialUpdateCollectionNftAttributeInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateErc20TokenArgs = {
  data: PartialUpdateErc20TokenInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateGasEstimateArgs = {
  data: PartialUpdateGasEstimateInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateMarketplaceArgs = {
  data: PartialUpdateMarketplaceInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateMarketplaceNftCollectionArgs = {
  data: PartialUpdateMarketplaceNftCollectionInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateMarketplaceVaultArgs = {
  data: PartialUpdateMarketplaceVaultInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateNftArgs = {
  data: PartialUpdateNftInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateNftAttributeArgs = {
  data: PartialUpdateNftAttributeInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateNftCollectionArgs = {
  data: PartialUpdateNftCollectionInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateOnboardingArgs = {
  data: PartialUpdateOnboardingInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateOrganizationArgs = {
  data: PartialUpdateOrganizationInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateOrganizationCustomerArgs = {
  data: PartialUpdateOrganizationCustomerInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateOrganizationGasAccountArgs = {
  data: PartialUpdateOrganizationGasAccountInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateSessionNonceArgs = {
  data: PartialUpdateSessionNonceInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateUserArgs = {
  data: PartialUpdateUserInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateVaultArgs = {
  data: PartialUpdateVaultInput;
  id: Scalars['ID'];
};


export type MutationPartialUpdateVaultProfileArgs = {
  data: PartialUpdateVaultProfileInput;
  id: Scalars['ID'];
};


export type MutationUpdateCollectionNftAttributeArgs = {
  data: CollectionNftAttributeInput;
  id: Scalars['ID'];
};


export type MutationUpdateErc20TokenArgs = {
  data: Erc20TokenInput;
  id: Scalars['ID'];
};


export type MutationUpdateGasEstimateArgs = {
  data: GasEstimateInput;
  id: Scalars['ID'];
};


export type MutationUpdateMarketplaceArgs = {
  data: MarketplaceInput;
  id: Scalars['ID'];
};


export type MutationUpdateMarketplaceNftCollectionArgs = {
  data: MarketplaceNftCollectionInput;
  id: Scalars['ID'];
};


export type MutationUpdateMarketplaceVaultArgs = {
  data: MarketplaceVaultInput;
  id: Scalars['ID'];
};


export type MutationUpdateNftArgs = {
  data: NftInput;
  id: Scalars['ID'];
};


export type MutationUpdateNftAttributeArgs = {
  data: NftAttributeInput;
  id: Scalars['ID'];
};


export type MutationUpdateNftCollectionArgs = {
  data: NftCollectionInput;
  id: Scalars['ID'];
};


export type MutationUpdateOnboardingArgs = {
  data: OnboardingInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrganizationCustomerArgs = {
  data: OrganizationCustomerInput;
  id: Scalars['ID'];
};


export type MutationUpdateOrganizationGasAccountArgs = {
  data: OrganizationGasAccountInput;
  id: Scalars['ID'];
};


export type MutationUpdateSessionNonceArgs = {
  data: SessionNonceInput;
  id: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  data: UserInput;
  id: Scalars['ID'];
};


export type MutationUpdateVaultArgs = {
  data: VaultInput;
  id: Scalars['ID'];
};


export type MutationUpdateVaultProfileArgs = {
  data: VaultProfileInput;
  id: Scalars['ID'];
};

export type Nft = {
  __typename?: 'NFT';
  NFTCollection?: Maybe<NftCollection>;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  attributes: NftAttributePage;
  tokenId: Scalars['String'];
};


export type NftAttributesArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};

export type NftAttribute = {
  __typename?: 'NFTAttribute';
  NFT?: Maybe<Nft>;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type NftAttributeInput = {
  NFT?: InputMaybe<NftAttributeNftRelation>;
  trait_type: Scalars['String'];
  value: Scalars['String'];
};

export type NftAttributeNftRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type NftAttributePage = {
  __typename?: 'NFTAttributePage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<NftAttribute>>;
};

export type NftAttributesRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<NftAttributeInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type NftCollection = {
  __typename?: 'NFTCollection';
  ERC20MintToken?: Maybe<Scalars['String']>;
  NFTs: NftPage;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  banner?: Maybe<Scalars['String']>;
  baseTokenURI?: Maybe<Scalars['String']>;
  collectionAttributes: CollectionNftAttributePage;
  collectionImage?: Maybe<Scalars['String']>;
  contractAddress?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  mintDecimals: Scalars['Int'];
  mintPrice: Scalars['String'];
  mintToken?: Maybe<Erc20Token>;
  mintTokenName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  owner: Scalars['String'];
  publicMint?: Maybe<Scalars['Boolean']>;
  symbol?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
  type: NftType;
};


export type NftCollectionNfTsArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};


export type NftCollectionCollectionAttributesArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};

export type NftCollectionCollectionAttributesRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<CollectionNftAttributeInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type NftCollectionInput = {
  ERC20MintToken?: InputMaybe<Scalars['String']>;
  NFTs?: InputMaybe<NftCollectionNfTsRelation>;
  banner?: InputMaybe<Scalars['String']>;
  baseTokenURI?: InputMaybe<Scalars['String']>;
  collectionAttributes?: InputMaybe<NftCollectionCollectionAttributesRelation>;
  collectionImage?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  mintDecimals: Scalars['Int'];
  mintPrice: Scalars['String'];
  mintToken?: InputMaybe<NftCollectionMintTokenRelation>;
  mintTokenName: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<NftCollectionOrganizationRelation>;
  owner: Scalars['String'];
  publicMint?: InputMaybe<Scalars['Boolean']>;
  symbol?: InputMaybe<Scalars['String']>;
  tokenType?: InputMaybe<Scalars['String']>;
  type: NftType;
};

export type NftCollectionMintTokenRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<Erc20TokenInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type NftCollectionNfTsRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<NftInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type NftCollectionOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type NftCollectionPage = {
  __typename?: 'NFTCollectionPage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<NftCollection>>;
};

export type NftInput = {
  NFTCollection?: InputMaybe<NftnftCollectionRelation>;
  attributes?: InputMaybe<NftAttributesRelation>;
  tokenId: Scalars['String'];
};

export type NftnftCollectionRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftCollectionInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type NftPage = {
  __typename?: 'NFTPage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<Nft>>;
};

export enum NftType {
  Common = 'COMMON'
}

export type Onboarding = {
  __typename?: 'Onboarding';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  organization?: Maybe<Organization>;
  skipped?: Maybe<Scalars['Boolean']>;
  status?: Maybe<OnboardingStatus>;
};

export type OnboardingInput = {
  organization?: InputMaybe<OnboardingOrganizationRelation>;
  skipped?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<OnboardingStatus>;
};

export type OnboardingOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export enum OnboardingStatus {
  DemoSaasApplicationAuthSuccess = 'DEMO_SAAS_APPLICATION_AUTH_SUCCESS',
  DemoSaasBuyNft = 'DEMO_SAAS_BUY_NFT',
  DemoSaasCheckGas = 'DEMO_SAAS_CHECK_GAS',
  DemoSaasCreateFungibleToken = 'DEMO_SAAS_CREATE_FUNGIBLE_TOKEN',
  DemoSaasCreateMarketplace = 'DEMO_SAAS_CREATE_MARKETPLACE',
  DemoSaasCreateNonFungibleToken = 'DEMO_SAAS_CREATE_NON_FUNGIBLE_TOKEN',
  DemoSaasFundTreasury = 'DEMO_SAAS_FUND_TREASURY',
  DemoSaasGetCoins = 'DEMO_SAAS_GET_COINS',
  DemoSaasInfrastructureDeployed = 'DEMO_SAAS_INFRASTRUCTURE_DEPLOYED',
  DemoSaasTryApplication = 'DEMO_SAAS_TRY_APPLICATION',
  DemoSaasVideoIntro = 'DEMO_SAAS_VIDEO_INTRO',
  Done = 'DONE',
  FirstLogin = 'FIRST_LOGIN',
  NoUserWallets = 'NO_USER_WALLETS'
}

export type Organization = {
  __typename?: 'Organization';
  NFTCollections: NftCollectionPage;
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  gasAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organizationGasAccount?: Maybe<OrganizationGasAccount>;
  secretKey?: Maybe<Scalars['String']>;
  users: UserPage;
};


export type OrganizationNftCollectionsArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};


export type OrganizationUsersArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};

export type OrganizationCustomer = {
  __typename?: 'OrganizationCustomer';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  activeWallet?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars['String']>;
  wallets?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrganizationCustomerInput = {
  activeWallet?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCustomerOrganizationRelation>;
  organizationId?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OrganizationCustomerOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationGasAccount = {
  __typename?: 'OrganizationGasAccount';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  address?: Maybe<Scalars['String']>;
  nmonic?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
  privateKey?: Maybe<Scalars['String']>;
};

export type OrganizationGasAccountInput = {
  address?: InputMaybe<Scalars['String']>;
  nmonic?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationGasAccountOrganizationRelation>;
  privateKey?: InputMaybe<Scalars['String']>;
};

export type OrganizationGasAccountOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationInput = {
  NFTCollections?: InputMaybe<OrganizationNftCollectionsRelation>;
  gasAddress?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organizationGasAccount?: InputMaybe<OrganizationOrganizationGasAccountRelation>;
  secretKey?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<OrganizationUsersRelation>;
};

export type OrganizationNftCollectionsRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<NftCollectionInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type OrganizationOrganizationGasAccountRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationGasAccountInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type OrganizationUsersRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<UserInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PartialUpdateCollectionNftAttributeInput = {
  NFTCollection?: InputMaybe<CollectionNftAttributeNftCollectionRelation>;
  trait_type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateErc20TokenInput = {
  contractAddress?: InputMaybe<Scalars['String']>;
  creator?: InputMaybe<Scalars['String']>;
  decimals?: InputMaybe<Scalars['Int']>;
  imported?: InputMaybe<Scalars['Boolean']>;
  logoURL?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  totalSupply?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Erc20TokenType>;
};

export type PartialUpdateGasEstimateInput = {
  action?: InputMaybe<BlockchainAction>;
  blockchain?: InputMaybe<Scalars['String']>;
  gasRequired?: InputMaybe<Scalars['Int']>;
};

export type PartialUpdateMarketplaceInput = {
  blockchain?: InputMaybe<Scalars['String']>;
  blockchainId?: InputMaybe<Scalars['String']>;
  buildTag?: InputMaybe<Scalars['String']>;
  contractAddres?: InputMaybe<Scalars['String']>;
  customStyleSheetURL?: InputMaybe<Scalars['String']>;
  feeRecipient?: InputMaybe<Scalars['String']>;
  marketplaceFee?: InputMaybe<Scalars['String']>;
  marketplaceNFTCollections?: InputMaybe<MarketplaceMarketplaceNftCollectionsRelation>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<MarketplaceOrganizationRelation>;
  owner?: InputMaybe<Scalars['String']>;
  symbol?: InputMaybe<Scalars['String']>;
  vaults?: InputMaybe<MarketplaceVaultsRelation>;
  webhookURL?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateMarketplaceNftCollectionInput = {
  NFTCollection?: InputMaybe<MarketplaceNftCollectionNftCollectionRelation>;
  marketplace?: InputMaybe<MarketplaceNftCollectionMarketplaceRelation>;
  status?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateMarketplaceVaultInput = {
  marketplace?: InputMaybe<MarketplaceVaultMarketplaceRelation>;
  vault?: InputMaybe<MarketplaceVaultVaultRelation>;
};

export type PartialUpdateNftAttributeInput = {
  NFT?: InputMaybe<NftAttributeNftRelation>;
  trait_type?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateNftCollectionInput = {
  ERC20MintToken?: InputMaybe<Scalars['String']>;
  NFTs?: InputMaybe<NftCollectionNfTsRelation>;
  banner?: InputMaybe<Scalars['String']>;
  baseTokenURI?: InputMaybe<Scalars['String']>;
  collectionAttributes?: InputMaybe<NftCollectionCollectionAttributesRelation>;
  collectionImage?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  mintDecimals?: InputMaybe<Scalars['Int']>;
  mintPrice?: InputMaybe<Scalars['String']>;
  mintToken?: InputMaybe<NftCollectionMintTokenRelation>;
  mintTokenName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<NftCollectionOrganizationRelation>;
  owner?: InputMaybe<Scalars['String']>;
  publicMint?: InputMaybe<Scalars['Boolean']>;
  symbol?: InputMaybe<Scalars['String']>;
  tokenType?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<NftType>;
};

export type PartialUpdateNftInput = {
  NFTCollection?: InputMaybe<NftnftCollectionRelation>;
  attributes?: InputMaybe<NftAttributesRelation>;
  tokenId?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateOnboardingInput = {
  organization?: InputMaybe<OnboardingOrganizationRelation>;
  skipped?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<OnboardingStatus>;
};

export type PartialUpdateOrganizationCustomerInput = {
  activeWallet?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationCustomerOrganizationRelation>;
  organizationId?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type PartialUpdateOrganizationGasAccountInput = {
  address?: InputMaybe<Scalars['String']>;
  nmonic?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<OrganizationGasAccountOrganizationRelation>;
  privateKey?: InputMaybe<Scalars['String']>;
};

export type PartialUpdateOrganizationInput = {
  NFTCollections?: InputMaybe<OrganizationNftCollectionsRelation>;
  gasAddress?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organizationGasAccount?: InputMaybe<OrganizationOrganizationGasAccountRelation>;
  secretKey?: InputMaybe<Scalars['String']>;
  users?: InputMaybe<OrganizationUsersRelation>;
};

export type PartialUpdateSessionNonceInput = {
  customerId?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<SessionNonceOrganizationRelation>;
};

export type PartialUpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<UserOrganizationRelation>;
};

export type PartialUpdateVaultInput = {
  contractAddress?: InputMaybe<Scalars['String']>;
  depositToken?: InputMaybe<VaultDepositTokenRelation>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<VaultOrganizationRelation>;
  owner?: InputMaybe<Scalars['String']>;
  profiles?: InputMaybe<VaultProfilesRelation>;
  proofOfBurnToken?: InputMaybe<VaultProofOfBurnTokenRelation>;
};

export type PartialUpdateVaultProfileInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  vault?: InputMaybe<VaultProfileVaultRelation>;
  vaultId?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  findCollectionNFTAttributeByID?: Maybe<CollectionNftAttribute>;
  findERC20ByAddress: Erc20Token;
  findERC20TokenByID?: Maybe<Erc20Token>;
  findGasEstimateByActionAndBlockchain?: Maybe<GasEstimate>;
  findGasEstimateByID?: Maybe<GasEstimate>;
  findMarketplaceByID?: Maybe<Marketplace>;
  findMarketplaceNFTCollectionByID?: Maybe<MarketplaceNftCollection>;
  findMarketplaceVaultByID?: Maybe<MarketplaceVault>;
  findNFTAttributeByID?: Maybe<NftAttribute>;
  findNFTByID?: Maybe<Nft>;
  findNFTCollectionByAddress: NftCollection;
  findNFTCollectionByID?: Maybe<NftCollection>;
  findOnboardingByID?: Maybe<Onboarding>;
  findOrganizationByID?: Maybe<Organization>;
  findOrganizationBySecretKey: Organization;
  findOrganizationCustomerByCustomerAndOrganizationId?: Maybe<OrganizationCustomer>;
  findOrganizationCustomerByID?: Maybe<OrganizationCustomer>;
  findOrganizationGasAccountByID?: Maybe<OrganizationGasAccount>;
  findSessionNonceByID?: Maybe<SessionNonce>;
  findUserByID?: Maybe<User>;
  findVaultByID?: Maybe<Vault>;
  findVaultProfileByID?: Maybe<VaultProfile>;
  findVaultProfileByOwnerAndVaultId?: Maybe<VaultProfile>;
};


export type QueryFindCollectionNftAttributeByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindErc20ByAddressArgs = {
  contractAddress?: InputMaybe<Scalars['String']>;
};


export type QueryFindErc20TokenByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindGasEstimateByActionAndBlockchainArgs = {
  action: BlockchainAction;
  blockchain: Scalars['String'];
};


export type QueryFindGasEstimateByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindMarketplaceByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindMarketplaceNftCollectionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindMarketplaceVaultByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindNftAttributeByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindNftByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindNftCollectionByAddressArgs = {
  contractAddress?: InputMaybe<Scalars['String']>;
};


export type QueryFindNftCollectionByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindOnboardingByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindOrganizationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindOrganizationBySecretKeyArgs = {
  secretKey?: InputMaybe<Scalars['String']>;
};


export type QueryFindOrganizationCustomerByCustomerAndOrganizationIdArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  organizationId?: InputMaybe<Scalars['String']>;
};


export type QueryFindOrganizationCustomerByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindOrganizationGasAccountByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindSessionNonceByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindUserByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindVaultByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindVaultProfileByIdArgs = {
  id: Scalars['ID'];
};


export type QueryFindVaultProfileByOwnerAndVaultIdArgs = {
  owner?: InputMaybe<Scalars['String']>;
  vaultId?: InputMaybe<Scalars['String']>;
};

export type SessionNonce = {
  __typename?: 'SessionNonce';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  customerId?: Maybe<Scalars['String']>;
  info?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type SessionNonceInput = {
  customerId?: InputMaybe<Scalars['String']>;
  info?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<SessionNonceOrganizationRelation>;
};

export type SessionNonceOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<UserOrganizationRelation>;
};

export type UserOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type UserPage = {
  __typename?: 'UserPage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<User>>;
};

export type Vault = {
  __typename?: 'Vault';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  contractAddress: Scalars['String'];
  depositToken?: Maybe<NftCollection>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organization: Organization;
  owner: Scalars['String'];
  profiles: VaultProfilePage;
  proofOfBurnToken?: Maybe<NftCollection>;
};


export type VaultProfilesArgs = {
  _cursor?: InputMaybe<Scalars['String']>;
  _size?: InputMaybe<Scalars['Int']>;
};

export type VaultDepositTokenRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftCollectionInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type VaultInput = {
  contractAddress: Scalars['String'];
  depositToken?: InputMaybe<VaultDepositTokenRelation>;
  description?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organization?: InputMaybe<VaultOrganizationRelation>;
  owner: Scalars['String'];
  profiles?: InputMaybe<VaultProfilesRelation>;
  proofOfBurnToken?: InputMaybe<VaultProofOfBurnTokenRelation>;
};

export type VaultOrganizationRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<OrganizationInput>;
};

export type VaultProfile = {
  __typename?: 'VaultProfile';
  _id: Scalars['ID'];
  _ts: Scalars['Long'];
  createdBy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  vault?: Maybe<Vault>;
  vaultId?: Maybe<Scalars['String']>;
};

export type VaultProfileInput = {
  createdBy?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Scalars['String']>;
  vault?: InputMaybe<VaultProfileVaultRelation>;
  vaultId?: InputMaybe<Scalars['String']>;
};

export type VaultProfilePage = {
  __typename?: 'VaultProfilePage';
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  data: Array<Maybe<VaultProfile>>;
};

export type VaultProfileVaultRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<VaultInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};

export type VaultProfilesRelation = {
  connect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  create?: InputMaybe<Array<InputMaybe<VaultProfileInput>>>;
  disconnect?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type VaultProofOfBurnTokenRelation = {
  connect?: InputMaybe<Scalars['ID']>;
  create?: InputMaybe<NftCollectionInput>;
  disconnect?: InputMaybe<Scalars['Boolean']>;
};
