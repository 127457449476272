import React, { useEffect, useState, useContext } from "react";
import { useFaunaUtils } from "../../../../../utils/faunaClient";
import { faunaCollections } from "../../../../../utils/faunaCollections";
import { useChain } from "../../../../../hooks/ChainUtils";
import { UserContext } from "../../../../../context/userContext";
import { OnboardingStatus } from "../../../../../types";
export const TrySaaSAuth = ({
  setOnboardingDone,
  setNextStatus,
  onboardingId,
}) => {
  const { listAllForOwner, findDocumentById } = useFaunaUtils();

  const [lastNFTCollection, setLastNFTCollection] = useState();

  const { chainId } = useChain();
  const userContext = useContext(UserContext);

  let organizationId = userContext?.organizationId;

  const [authSuccess, setAuthSuccess] = useState(false);

  const [initialAuthCheck, setInitialAuthCheck] = useState(false);

  useEffect(() => {
    const checkForSuccess = async () => {
      const onboardings = await listAllForOwner(faunaCollections.Onboarding);
      const onboarding = onboardings[0];

      if (
        onboarding.status === OnboardingStatus.DemoSaasApplicationAuthSuccess
      ) {
        setAuthSuccess(true);
      }
    };

    if (!initialAuthCheck) {
      setInitialAuthCheck(true);
      checkForSuccess();
    }
  }, [setAuthSuccess, listAllForOwner, initialAuthCheck]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!authSuccess) {
        const onboarding = await listAllForOwner(
          faunaCollections.Onboarding
        )[0];
        if (
          onboarding.status === OnboardingStatus.DemoSaasApplicationAuthSuccess
        ) {
          setAuthSuccess(true);
        }
      }
    }, 5000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const setLastNFT = async () => {
      listAllForOwner(faunaCollections.NFTCollection).then(async (res) => {
        if (res.length > 0) {
          const lastNFT = res[res.length - 1];
          setLastNFTCollection(lastNFT);
        }
      });
    };
    if (!lastNFTCollection) {
      setLastNFT();
    }
  }, [listAllForOwner, lastNFTCollection, findDocumentById]);

  const renderTestApplication = () => {
    const URL = `${process.env.REACT_APP_DEMO_SAAS_HOST}?organizationId=${organizationId}&nftAddress=${lastNFTCollection?.contractAddress}&chainId=${chainId}&onboardingId=${onboardingId}`;
    if (authSuccess) {
      return (
        <div >
        <div className="flex justify-center">
            You have successfully authenticated to application using your recently bought NFT!.
          </div>
          <div className="flex justify-center">
            Congratulations!
          </div>
          <div className="flex justify-center mt-10">

          <div
            className="
            cursor-pointer bg-blue-400 text-white font-bold	p-2 text-center w-36 rounded-lg  	"
            onClick={async () => {
              await setNextStatus();
              setOnboardingDone();
            }}
          >
            Finish tutorial
          </div>
          </div>

        </div>
      );
    }
    return (
      <div>
        <div className="flex justify-center">
          <a href={URL} target="_blank" rel="noreferrer">
            Visit the application here
          </a>
        </div>
      </div>
    );
  };

  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Try the SaaS application
          </div>
          <div class="text-center">
            You should now be able to access the Demo SaaS (a Note taking app)
            with your email, which is linked with your wallet address
          </div>
        </div>
        <div>{renderTestApplication()}</div>
      </div>
    </div>
  );
};
