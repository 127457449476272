import { useState } from "react";
import { Button, Input } from "reactstrap";
import Loading from "../../components/Loading";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { useCactusChain } from "../../hooks/ChainUtils";
import { PrimaryButton } from "../../components/Buttons";
import { useERC20TokenActions } from "../../hooks/ERC20Actions";

type ERC20Details = {
  name: string;
  symbol: string;
  address: string;
};
const ImportERC20Componenet = () => {
  const { invokeGetEndpoint } = useBlockOneEndpoint();
  const [ERC20Details, setERC20Details] = useState<ERC20Details>();
  const [loading, setLoading] = useState<boolean>(false);
  const { chainId, chain } = useCactusChain();
  const { persistCreatedToken } = useERC20TokenActions();
  const [error, setError] = useState<string>();
  const [tokenAddress, setTokenAddress] = useState<string>();

  return (
    <div >
        <div className="text-xl mb-10 mt-2 flex justify-center">Import ERC20 Token</div>
      <div>Token Address:</div>
      <div className="grid grid-cols-2 w-screen">
        <div>
          <Input
            onChange={(event) => {
              const value = event.target.value?.trim();
              setTokenAddress(value);
            }}
          ></Input>
        </div>
        <div>
          <Button
            onClick={async () => {
              if (loading || !tokenAddress) return;
              setLoading(true);

              setError(undefined);
              try {
                const details = await invokeGetEndpoint
                (`ERC20/details?tokenAddress=${tokenAddress}&chainId=${chainId}`);
                
                setLoading(false);
                setERC20Details(details);
              } catch {
                setError(
                  `no token found with address ${tokenAddress} on  chain ${chain?.name}`
                );
                setLoading(false);
              }
            }}
          >
            Search
          </Button>
        </div>
        {error && <div className="text-red-500"> {error} </div>}
        {loading && <Loading></Loading>}
        <div className="grid grid-cols-2">
          <div>{ERC20Details?.name}</div>
          <div>{ERC20Details?.symbol}</div>
        </div>
      </div>
      <div>
        {ERC20Details && (
          <PrimaryButton
            onClick={() => {
              persistCreatedToken(
                ERC20Details.address,
                ERC20Details.name,
                ERC20Details.symbol
              );
            }}
          >
            Import Token
          </PrimaryButton>
        )}
      </div>
    </div>
  );
};

export { ImportERC20Componenet };
