import React, { useEffect } from "react";
import Loading from "../../../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Steps } from "../../../menu/Steps";
import { CreateFungibleToken } from "./steps/CreateFungibleToken";
import { CreateNonFungibleToken } from "./steps/CreateNonFungibleToken";

import { CreateMarketplace } from "./steps/CreateMarketplace";
import NavBar from "../../../../components/NavBar";

import { OnboardingStatus } from "../../../../types";
import { useOnboardingActions } from "../../../../hooks/OnboardingActions";
import getDemoSaasSteps from "./steps/DemoSaasSteps";
import { InfrastructureDeployed } from "./steps/InfrastructureDeployed";
import { GetCoins } from "./steps/GetCoins";
import Constants from "../../../../utils/constants";
import { CheckForUserGas } from "./steps/CheckForUserGas";
import { BuyNFTInMarketplace } from "./steps/BuyNFTInMarketplace";
import { TrySaaSAuth } from "./steps/TrySaaSAuth";
import { VideoIntro } from "./steps/VideoIntro";
import { FundTreasury } from "./steps/FundTreasury";

import { useMoralis } from "../../../../hooks/ChainUtils";
import Web3Authenticate from "../../../../components/Web3Authenticate";
export const CreateDemoSaaS = ({setOnboardingDone}) => {
  const { updateOnboardingStatus, status, loaded, onboardingId } = useOnboardingActions();
  const {user, isWeb3Enabled, account, chainId} = useMoralis();


  useEffect(() => {
    if (loaded && !status) {
      updateOnboardingStatus(OnboardingStatus.VideoIntro);
    }
  }, [updateOnboardingStatus, loaded, status]);

  let view = <div></div>;

  console.log(`onboarding status1 ${status}`)

  if (status === OnboardingStatus.VideoIntro) {
    view = (
      <div>
        <VideoIntro
          setNextStatus={() =>
            updateOnboardingStatus(
              OnboardingStatus.DemoSaasFundTreasury
            )
          }
        ></VideoIntro>
      </div>
    );
  }

  if (status === OnboardingStatus.DemoSaasFundTreasury) {
    view = (
      <div>
        <FundTreasury
          setNextStatus={() =>
            updateOnboardingStatus(
              OnboardingStatus.DemoSaasCreateFungibleToken
            )
          }
        ></FundTreasury>
      </div>
    );
  }

  if (status === OnboardingStatus.DemoSaasCreateFungibleToken) {
    view = (
      <div>
        <CreateFungibleToken
          setNextStatus={() =>
            updateOnboardingStatus(
              OnboardingStatus.DemoSaasCreateNonFungibleToken
            )
          }
        ></CreateFungibleToken>
      </div>
    );
  }

  if (status === OnboardingStatus.DemoSaasCreateNonFungibleToken) {
    view = (
      <div>
        <CreateNonFungibleToken
          setNextStatus={() =>
            updateOnboardingStatus(OnboardingStatus.DemoSaasCreateMarketplace)
          }
        ></CreateNonFungibleToken>
      </div>
    );
  }

  if (status === OnboardingStatus.DemoSaasCreateMarketplace) {
    view = (
      <div>
        <CreateMarketplace
          setNextStatus={() =>
            updateOnboardingStatus(OnboardingStatus.DemoSaasInfrastructureDeployed)
          }
        ></CreateMarketplace>
      </div>
    );
  }
  if (status === OnboardingStatus.DemoSaasInfrastructureDeployed) {
    view = (
      <div>
        <InfrastructureDeployed
          setNextStatus={() => updateOnboardingStatus(OnboardingStatus.DemoSaasGetCoins)}
        ></InfrastructureDeployed>
      </div>
    );
  }

  const trySaas = Constants.ONBOARDING_SAAS_USER_STEPS.includes(status);

  if(trySaas && (!user || !isWeb3Enabled  || !account || chainId !== Constants.BINANCE_TESTNET_BLOCKCHAIN_ID)){
    view = <Web3Authenticate
    requiredChain={Constants.BINANCE_TESTNET_BLOCKCHAIN_ID}
    ></Web3Authenticate>
  } else {
    console.log(`connectedAddress prev ${account}`)


  if (status === OnboardingStatus.DemoSaasGetCoins) {
    view = (
      <div>
        <GetCoins
                connectedAddress = {account}

          setNextStatus={() => updateOnboardingStatus(OnboardingStatus.DemoSaasCheckGas)}
        ></GetCoins>
      </div>
    );
  }

  if (status === OnboardingStatus.DemoSaasCheckGas) {
    view = (
      <div>
        <CheckForUserGas
                connectedAddress = {account}

          setNextStatus={() => updateOnboardingStatus(OnboardingStatus.DemoSaasBuyNft)}
        ></CheckForUserGas>
      </div>
    );
  }


  if (status === OnboardingStatus.DemoSaasBuyNft) {
    view = (
      <div>
        <BuyNFTInMarketplace
          setNextStatus={() => updateOnboardingStatus(OnboardingStatus.DemoSaasTryApplication)}
        ></BuyNFTInMarketplace>
      </div>
    );
  }
  if (status === OnboardingStatus.DemoSaasTryApplication
     || status === OnboardingStatus.DemoSaasApplicationAuthSuccess
    ) {
    view = (
      <div>
        <TrySaaSAuth
        setOnboardingDone={() => setOnboardingDone()}
        onboardingId={onboardingId}
          setNextStatus={() => updateOnboardingStatus(OnboardingStatus.Done)}
        ></TrySaaSAuth>
      </div>
    );
  }

  }





  
  if (!loaded) {
    return <Loading />
  }
  


  


  let isUserStep = Constants.ONBOARDING_SAAS_USER_STEPS.includes(status) 

  const topLeftTitle = isUserStep ? "Try your crypto Saas" : "Deploying Crypto Infrastructure"
  
  const steps = getDemoSaasSteps(status);
  return (
    <div>
      <NavBar
      topLeftTitle={topLeftTitle}
      ></NavBar>
      <Steps
      steps={steps}></Steps>
      <div className="-mt-28">{view}</div>
    </div>
  );
};

export default withAuthenticationRequired(CreateDemoSaaS, {
  onRedirecting: () => <Loading />,
});
