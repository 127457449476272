import { Chain } from "./types";

export const BSC_TESNET: Chain = {
  name: "Binance Smart Chain Testnet",
  chainId: "0x61",
  shortName: "bnbt",
  networkId: 97,
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "tBNB",
    decimals: 18,
  },
  rpc: [
    "https://data-seed-prebsc-1-s1.binance.org:8545",
    "https://data-seed-prebsc-2-s1.binance.org:8545",
    "https://data-seed-prebsc-1-s2.binance.org:8545",
    "https://data-seed-prebsc-2-s2.binance.org:8545",
    "https://data-seed-prebsc-1-s3.binance.org:8545",
    "https://data-seed-prebsc-2-s3.binance.org:8545",
  ],
  faucets: ["https://testnet.binance.org/faucet-smart"],
  infoURL: "https://testnet.binance.org/",
  blockExplorerUrl: "https://testnet.bscscan.com",
};

export const BSC_MAINNET: Chain = {
  name: "Binance Smart Chain Mainnet",
  chainId: "0x38",
  shortName: "bnb",
  networkId: 56,
  nativeCurrency: {
    name: "Binance Chain Native Token",
    symbol: "BNB",
    decimals: 18,
  },
  rpc: [
    "https://bsc-dataseed1.binance.org",
    "https://bsc-dataseed2.binance.org",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed4.binance.org",
    "https://bsc-dataseed1.defibit.io",
    "https://bsc-dataseed2.defibit.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed4.defibit.io",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed4.ninicoin.io",
    "wss://bsc-ws-node.nariox.org",
  ],
  faucets: ["https://free-online-app.com/faucet-for-eth-evm-chains/"],
  infoURL: "https://www.binance.org",
  blockExplorerUrl: "https://bscscan.com/",
};

export const POLYGON_TESNET: Chain = {
  name: "Polygon Testnet Mumbai",
  chainId: "0x13881",
  shortName: "maticmum",
  networkId: 80001,
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpc: [
    "https://matic-mumbai.chainstacklabs.com",
    "https://rpc-mumbai.maticvigil.com",
    "https://matic-testnet-archive-rpc.bwarelabs.com",
  ],
  faucets: ["https://faucet.polygon.technology/"],
  infoURL: "https://polygon.technology/",
  blockExplorerUrl: "https://mumbai.polygonscan.com",
};

export const POLYGON_MAINNET: Chain = {
  name: "Polygon Mainnet",
  chainId: "0x89",
  shortName: "MATIC",
  networkId: 137,
  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
  rpc: [
    "https://polygon-rpc.com/",
    "https://rpc-mainnet.matic.network",
    "https://matic-mainnet.chainstacklabs.com",
    "https://rpc-mainnet.maticvigil.com",
    "https://rpc-mainnet.matic.quiknode.pro",
    "https://matic-mainnet-full-rpc.bwarelabs.com",
  ],
  faucets: [],
  infoURL: "https://polygon.technology/",
  blockExplorerUrl: "https://explorer-mainnet.maticvigil.com",
};

export const chainMap: Map<string, Chain> = new Map<string, Chain>([

  ["0x61", BSC_TESNET],
  ["0x38", BSC_MAINNET],
  ["0x13881", POLYGON_TESNET],
  ["0x89", POLYGON_MAINNET],
]);

// export const chainMap = {
//   "0x61": BSC_TESNET,
//   "0x38": BSC_MAINNET,
//   "0x13881": POLYGON_TESNET,
//   "0x89": POLYGON_MAINNET,
// };
