import React from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

export const ApiSpecViewComponent = () => {
  return  <SwaggerUI url="https://api.blockonelabs.com/api/doc" />
};

export default withAuthenticationRequired(ApiSpecViewComponent, {
  onRedirecting: () => <Loading />,
});
