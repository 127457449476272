import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { useFaunaUtils } from "../../utils/faunaClient";
import { useEffect, useState } from "react";
import { faunaCollections } from "../../utils/faunaCollections";
import { Vault } from "../../types";
import { useHistory } from "react-router-dom";

export const VaultListComponent = () => {

    const {listAllForOwner} = useFaunaUtils();

    const history = useHistory();
    const [vaults, setVaults] = useState<Vault[]>();
    useEffect( () => {
    const getVaults = async () => {
        const vaults =  await listAllForOwner(faunaCollections.Vault)
        setVaults(vaults);

    }
    if(!vaults) {
        getVaults()
    }
    


    },[vaults, listAllForOwner]
    )

    if(!vaults){
        return <Loading />
    }

    const renderVaults = () => {
      
        return vaults.map(vault => {
            return (
                <div className="grid grid-cols-3 hover:bg-green-100 cursor-pointer"
                onClick={() => {
                    history.push(`/admin/vaults/view/${vault._id}`)
                }}
                >

                                <div  key={vault._id}>{vault.name}</div>
                                <div  key={vault._id}>{vault._id}</div>
                                <div  key={vault._id}>{vault.contractAddress}</div>


</div>
            )
        }
        )
    }
    return (
        <div>
            <div className="grid grid-cols-3">
                <div>
                    Name
                </div>
                <div>
                    Deposit token
                </div>
                <div>
                    Proof of burn token
                </div>
               

            </div>
            {renderVaults()}
            {vaults && vaults.length}
            {/* {JSON.stringify(vaults.map(vault => vault._id))} */}
            </div>
    )
}

export default withAuthenticationRequired(VaultListComponent, {
    onRedirecting: () => <Loading />,
  });
  