import { withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../../components/Loading";
import { useFaunaUtils } from "../../utils/faunaClient";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { faunaCollections } from "../../utils/faunaCollections";

interface RouteParams {
  vaultId: string;
}

export const ViewVaultComponent = () => {
  let { vaultId } = useParams<RouteParams>();

  const [vault, setVault] = useState<any>();
  const [depositToken, setDepositToken] = useState<any>();
  const [proofOfBurnToken, setProofOfBurnToken] = useState<any>();

  const { findDocumentById } = useFaunaUtils();

  useEffect(() => {
    const getVault = async () => {
      const vault = await findDocumentById(faunaCollections.Vault, vaultId);
      const depositTokenId = JSON.parse(JSON.stringify(vault.depositToken))[
        "@ref"
      ].id;
      const proofOfBurnTokenId = JSON.parse(
        JSON.stringify(vault.proofOfBurnToken)
      )["@ref"].id;
      const depositToken = await findDocumentById(
        faunaCollections.NFTCollection,
        depositTokenId
      );
      const proofOfBurnToken = await findDocumentById(
        faunaCollections.NFTCollection,
        proofOfBurnTokenId
      );

      setVault(vault);
      setDepositToken(depositToken);
      setProofOfBurnToken(proofOfBurnToken);
    };
    if (!vault) {
      getVault();
    }
  }, [findDocumentById, vaultId, vault]);

  if (!vault || !depositToken || !proofOfBurnToken) {
    return <Loading></Loading>;
  }

  return (
    <div>
      <div className="grid grid-cols-2">
        <div>Name</div>
        <div>{vault.name}</div>

        <div>Address</div>
        <div>{vault.contractAddress}</div>


        {/* <div>{JSON.stringify(vault)}</div> */}
        <div>Deposit NFT</div>
        <div>
          <div>
            <div className="flex justify-center">{depositToken.name}</div>
            <div className="flex justify-center">
              <img
                className="h-10"
                alt="deposit NFT"
                src={depositToken.collectionImage}
              ></img>
            </div>
          </div>
        </div>
        <div>Proof of burn NFT</div>
        <div>
          <div>
            <div className="flex justify-center">{proofOfBurnToken.name}</div>
            <div className="flex justify-center">
              <img
                className="h-10 "
                alt="deposit NFT"
                src={proofOfBurnToken.collectionImage}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(ViewVaultComponent, {
  onRedirecting: () => <Loading />,
});
