
import React, { useState, useEffect, useContext } from "react";

import { useFaunaUtils } from '../../utils/faunaClient';
import { faunaCollections } from "../../utils/faunaCollections";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import {UserContext} from "../../context/userContext"

export const HomeDetails = () => {

    const {listAllForOwner, findDocumentById} = useFaunaUtils();
    const [ERC20Tokens, setERC20Tokens] = useState([])
    const [NFTCollections, setNFTCollections] = useState([])
    const [marketPlaces, setMarketplaces] = useState([])

    const [organization, setOrganization] = useState()


    const [loading, setLoading] = useState({
        loading: true,
    })

    const userContext = useContext(UserContext)


    useEffect(() => {
        const getMarketPlacesFauna = async() => {
            const organization = await findDocumentById(faunaCollections.Organization, userContext.organizationId)
     
            setOrganization(organization)

            const erc20s = await listAllForOwner(faunaCollections.ERC20Token)
            setERC20Tokens(erc20s)

            const nftCollections = await listAllForOwner(faunaCollections.NFTCollection)
            setNFTCollections(nftCollections)

            const marketplaces = await listAllForOwner(faunaCollections.Marketplace)
            setMarketplaces(marketplaces)


            setLoading(oldLoading => ({
                ...oldLoading,
                loading:false
    
            }))
        }
        if(loading.loading){
            getMarketPlacesFauna()
    
        }
    
       },[listAllForOwner, loading, findDocumentById, userContext.organizationId])

       const renderTokens = (title, tokens, rootURL) => {
        const result = tokens.slice(0,3).map(token => 
            {

            return <div>
                <a href={`${rootURL}/view/${token.id}`}>

                {token.name}
                </a>
                
            </div>
        })
        return (
            <div className=" hover:border-2 mb-4">
                <div className="mb-2 pb-1 text-l cursor-pointer font-semibold text-blue-900 border-b	">
                <a href={`${rootURL}/list`}>
                {title}
                </a>
                

                </div>
                

<div >
{result}

</div>
              
            </div>
        );
       }
    return (
        <div>
            <div className="text-4xl flex justify-center m-4 ">
                {organization?.name}
            </div>

<div>
            {renderTokens("ERC20 Tokens",ERC20Tokens, "admin/erc20")}
        </div>

        <div>
            {renderTokens("NFT Collections",NFTCollections, "admin/nft")}
        </div>


        <div>
            {renderTokens("Marketplaces",marketPlaces,"admin/marketplace")}
        </div>

        
        </div>
       
    )
};

export default withAuthenticationRequired(HomeDetails, {
    onRedirecting: () => <Loading />,
});
