import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "reactstrap";
import CactusLoginImage from "../../assets/cactus-login-logo.jpeg";

const LoginComponent = () => {

    const { loginWithRedirect } = useAuth0();

    return (
        <div className="" >
           <div className="flex justify-center mt-24 mb-4" >
            <img src={CactusLoginImage} className="w-40 h-40" alt="Cactus Login" />

           </div>
           <div className="flex justify-center">

           < Button
          id="qsLoginBtn"
          color="primary"
          className="btn-margin"
          onClick={() => loginWithRedirect()}
        >
          Log in
        </Button>
           </div>

        </div>
    )
}

export default LoginComponent;