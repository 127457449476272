import { OnboardingStatus } from "../../../../../types";
import { StepDefinition } from "../../../../menu/Steps";
// import {Constants} from "../../../../../utils/constants"
import Constants from "../../../../../utils/constants";
const getDemoSaasSteps = (status: OnboardingStatus) => {
  let notDoneUserSteps: Array<StepDefinition> = [
    {
      label: "Get coins",
      done: false,
    },
    {
      label: "Check user's gas",
      done: false,
    },
    {
      label: "Buy NFT",
      done: false,
    },
    {
      label: "Test your SaaS!",
      done: false,
    },
  ];

  let notDoneAdminSteps: Array<StepDefinition> = [
    {
      label: "Video Intro",
      done: false,
    },
    {
      label: "Funding your treasury",
      done: false,
    },
    {
      label: "Create Fungible Token",
      done: false,
    },
    {
      label: "Create NFT Collection",
      done: false,
    },
    {
      label: "Create Marketplace",
      done: false,
    },
    {
      label: "Infrastructure Deployed!",
      done: false,
    },
  ];
  const userSteps = Constants.ONBOARDING_SAAS_USER_STEPS.includes(status);
  let notDoneSteps = userSteps ? notDoneUserSteps : notDoneAdminSteps;

  let doneSteps: Array<StepDefinition> = [];

  switch (status) {
    case OnboardingStatus.DemoSaasVideoIntro:
    case OnboardingStatus.DemoSaasGetCoins:
      // code block
      break;
    case OnboardingStatus.DemoSaasFundTreasury:
    case OnboardingStatus.DemoSaasCheckGas:
      doneSteps = notDoneSteps.splice(0, 1);
      break;
    case OnboardingStatus.DemoSaasCreateFungibleToken:
    case OnboardingStatus.DemoSaasBuyNft:
      doneSteps = notDoneSteps.splice(0, 2);
      break;
    case OnboardingStatus.DemoSaasCreateNonFungibleToken:
    case OnboardingStatus.DemoSaasTryApplication:
      case OnboardingStatus.DemoSaasApplicationAuthSuccess:

      doneSteps = notDoneSteps.splice(0, 3);
      break;

    case OnboardingStatus.DemoSaasCreateMarketplace:
      doneSteps = notDoneSteps.splice(0, 4);
      break;
    case OnboardingStatus.DemoSaasInfrastructureDeployed:
      doneSteps = notDoneSteps.splice(0, 5);
      break;

    default:
  }

  doneSteps.forEach((step) => {
    step.done = true;
  });

  return doneSteps.concat(notDoneSteps);
};

export default getDemoSaasSteps;
