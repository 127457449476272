import React, {  useState } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { PrimaryButton } from "../../components/Buttons";
import { useGenerativeArtEndoints } from "../../utils/blockOneAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
// import { useFaunaUtils } from "../../utils/faunaClient";
// import { faunaCollections } from "../../utils/faunaCollections";
import { useNFTDropDownUtils } from "../../components/NFTsDropdown";
import { useValidateFormValues } from "../../utils/validation";
import { useAuth0 } from "@auth0/auth0-react";

const maxIterations = 3500;


export const GenerateArt = () => {
  const { generateAndUploadArt } = useGenerativeArtEndoints();
  const { NFTCollectionsDropdown, selectedNFTCollection } =
  useNFTDropDownUtils();
  // const { updateDocument } = useFaunaUtils();
  const { user } = useAuth0();

  const {IsJsonString} = useValidateFormValues()

  const [formData, updateFormData] = useState<any>({});
  const [loading] = useState(false);
  const [loadingMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [estimateMessage, setEstimateMessage] = useState("");

  // const [artCreated, setArtCreated] = useState<Array<any>>([]);
  const [generatingArt, setGeneratingArt] = useState(false);
  const [iterations, setIterations] = useState(0);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.trim();
    updateFormData({
      ...formData,
      [e.target.name]: value,
    });
    if (!IsJsonString(value)) {
      setErrorMessage("invalid json");
    } else {
      let calculatedIterations = 1;
      JSON.parse(value).layers?.forEach((layer: any) => {
        calculatedIterations = layer.files?.length * calculatedIterations;
      });
      if (isNaN(calculatedIterations)) {
        setErrorMessage(`Invalid layers format, check that all layers 
                have files`);
      } else {
        setIterations(calculatedIterations)
        setErrorMessage("");
        let appendEstimateMessage = "";
        if(calculatedIterations > maxIterations) {
          appendEstimateMessage = `, ${maxIterations} is the maximum iterations allowed per request, only first ${maxIterations} will be generated`
        }
        const estimatedTime = Math.round(Math.min(calculatedIterations, maxIterations) / 7);
        setEstimateMessage(`Estimated Iterations ${calculatedIterations} ${appendEstimateMessage}. Estimated time: ${estimatedTime} minutes, you will recieve an email when your art is ready`);
      }
    }
  };



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setGeneratingArt(true);
    e.preventDefault();
    await generateAndUploadArt(
      formData.NFTArtJsonConfig,
      selectedNFTCollection?._id!,
      user?.email!
    );
    // setArtCreated(art);

    //possible good idea to update the NFT collection with the new art
    // but disabled for now

    // if (art && art[0]) {
    //   const url = new URL(art[0]);
    //   let path = url.pathname.split("/");
    //   path.pop(); // remove the last
    //   url.pathname = path.join("/");

    //   console.log(url.href);
    //   const baseTokenImageURI = url.href;
    //   updateDocument(
    //     faunaCollections.NFTCollection,
    //     {
    //       baseTokenImageURI,
    //     },
    //     formData.NFTCollectionId
    //   );
    // }


  };

  const renderGenerateArtButton = () => {
    if (generatingArt) {
      const imageRoot = `https://cloud.blockonelabs.com/images/${selectedNFTCollection?._id}`
      return (
        <div>
          <div>
            <div>
            Generating Images, <span className="font-medium">{Math.min(iterations, maxIterations)} </span>  images will be available from  

            </div>
           <div>
           <a href={`${imageRoot}/0.png`}>
            {imageRoot}/0.png
            </a>
           </div>
            <div>
            to 
            </div>
            <div>
            {imageRoot}/{iterations - 1}.png
            </div>
          </div>
          <div>
            { }
          </div>
          <div className="flex justify-center mt-2">     
            You will recieve an email at {user?.email} once the all images are done
          </div>
        </div>
      );
    }

    return (
      <div>

        <div>
        {errorMessage}
      {estimateMessage}

        </div>
        <div
        className="small-text text-gray-600 m-2"
        >
          Any previous art with this NFT reference will be overwritten, make sure you have backed up your previous art before proceeding.
        </div>

        <PrimaryButton
          disabled={
            generatingArt ||
            !selectedNFTCollection ||
            errorMessage !== "" ||
            !formData.NFTArtJsonConfig ||
            formData.NFTArtJsonConfig?.trim() === ""
          }
        >
          Submit
        </PrimaryButton>
      </div>
    );
  };

  // const renderArtCreatedURLs = () => {
  //   const images = artCreated.map((art) => {
  //     return (
  //       <div className="m-2">
  //         <a href={art}>
  //           <img src={art} alt="generative art" />
  //         </a>
  //       </div>
  //     );
  //   });
  //   return <div className="flex ">{images}</div>;
  // };

  if (loading) {
    return <Loading message={loadingMessage}></Loading>;
  }
  return (
    <div className="mb-5">
     
      <form onSubmit={handleSubmit} style={{ width: "100%" }}>
        <div className="grid grid-cols-2 gap-5">
          <div className="col-span-2">
          <NFTCollectionsDropdown></NFTCollectionsDropdown>

          </div>
          <div className="col-span-2">
            <div>
              JSON Definition
              <a
                href="https://blockonelabs.notion.site/Generative-Art-6e8b2efc70e342d9a40bec22f79dac69"
                target="_blank"
                rel="noreferrer"
              >
                <FontAwesomeIcon className="ml-2" icon={faQuestionCircle} />
              </a>
            </div>

            <textarea
              className="h-40 border-2 w-full px-10"
              onChange={handleChange}
              name="NFTArtJsonConfig"
            />
          </div>
          <div className="w-full text-center col-span-2 my-3">
            {renderGenerateArtButton()}
            <div>{errorMessage}</div>
          </div>
          {/* <div>{renderArtCreatedURLs()}</div> */}
        </div>
      </form>
    </div>
  );
};

export default withAuthenticationRequired(GenerateArt, {
  onRedirecting: () => <Loading />,
});


