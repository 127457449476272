
import { useEffect } from "react";
import { useFaunaUtils } from "../utils/faunaClient";
import { faunaCollections } from "../utils/faunaCollections";
import { useState } from "react";
import { Input } from "reactstrap";
import { NftCollection } from "../types";
const useNFTDropDownUtils = () => {
    const [NFTCollections, setNFTCollections] = useState<Array<NftCollection>>();
    const { listAllForOwner } = useFaunaUtils();
    const [selectedNFTCollection, setSelectedNFTCollection] = useState<NftCollection>();

    useEffect(() => {
        const loadNFTCollections = async () => {
          const NFTCollections = await listAllForOwner(
            faunaCollections.NFTCollection
          );
          console.log("NFTCollections " + JSON.stringify(NFTCollections));
          setNFTCollections(NFTCollections);
        };
        if(!NFTCollections){
            loadNFTCollections();

        }
      }, [listAllForOwner, NFTCollections]);

      
      const selectNFTCollection = (event: any) => {
        const value = event.target.value.trim();
        const selected = NFTCollections?.filter(collection => collection._id === value)[0]
        setSelectedNFTCollection(selected)
      }

      
     

      const NFTCollectionsDropdown = () => {
        const renderNFTCollectionOptions = () => {
          if(NFTCollections){
            return NFTCollections.map((collection) => {
              return <option 
              selected={selectedNFTCollection?._id === collection._id}
              value={collection._id}>{collection.name}</option>;
            });
          }
        };
        return (
            <Input
              // invalid={isFieldInvalid("ERC20MintToken")}
              type={"select"}
              name="NFTCollectionId"
              onChange={selectNFTCollection}
            >
              <option selected={!selectedNFTCollection} disabled>
                Select NFT Collection{" "}
              </option>
              {/* {renderERC20Tokens()} */}
              {renderNFTCollectionOptions()}
            </Input>
        )
      }

      return {NFTCollectionsDropdown, selectedNFTCollection}
}

export {useNFTDropDownUtils}