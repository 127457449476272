import React, { useState, useContext, useEffect } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Label, Input } from "reactstrap";
import { useHistory } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons";
import { UserContext } from "../../context/userContext";
import { useERC20TokenActions } from "../../hooks/ERC20Actions";

export const CreateLiquidityTokenComponent = () => {
  const history = useHistory();
  const userContext = useContext(UserContext);

  const { createERC20LiquidityTokenFromTreasury, createdERC20Token } =
    useERC20TokenActions();

  const [formData, updateFormData] = useState({
    marketingAddress: userContext?.gasAddress,
    decimals: 9,
    liquidityFeePercent: 0,
    taxFeePercent: 0,
    charityFeePercent: 0,
    name: "Liquidity Token",
    symbol: "LIQ",
    supply: 1000000000

  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    createERC20LiquidityTokenFromTreasury(
      {
        name: formData.name,
        symbol: formData.symbol,
        supply: formData.supply,
        marketingAddress: formData.marketingAddress,
        taxFeePercent: formData.taxFeePercent,
        liquidityFeePercent: formData.liquidityFeePercent,
        charityFeePercent: formData.charityFeePercent,
        logo: formData.logo
      }
    );
  };

  useEffect(() => {
    if (createdERC20Token?.id) {
      history.push(`/admin/erc20/view/${createdERC20Token.id}`);
    }
  }, [createdERC20Token, createdERC20Token?.id, history]);

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <div className="px-2 ">
      <div className="w-screen">
        <div className="w-full text-center text-2xl pr-80 my-3">
          Create Liquidity Token
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className=" grid grid-cols-2 gap-5 align-items-center profile-header mb-5 text-center text-md-left">
          <div>
            <Label for="tokenName">Token Name</Label>
            <Input
              onChange={handleChange}
              type="text"
              name="name"
              placeholder="Token Name"
            />
          </div>

          <div>
            <Label for="tokenSymbol">Token Symbol</Label>
            <Input
              onChange={handleChange}
              name="symbol"
              type="text"
              placeholder="TKN"
            />
          </div>

          <div className="col-span-2">
            <Label for="tokenSymbol">Token Logo URL</Label>
            <Input
              onChange={handleChange}
              name="logo"
              type="text"
              placeholder="TKN"
            />
          </div>

          <div>
            <Label for="supply">Totaly supply of token</Label>
            <Input
              onChange={handleChange}
              name="supply"
              type="number"
              placeholder="10"
            />
          </div>
          <div></div>

          <div>
            <Label for="taxFeePercent">Relection Fee Percent</Label>
            <Input
              onChange={handleChange}
              name="taxFeePercent"
              type="number"
              placeholder="0"
            />
          </div>

          <div>
            <Label for="liquidityFeePercent">Liquidity Fee Percent</Label>
            <Input
              onChange={handleChange}
              name="liquidityFeePercent"
              type="number"
              placeholder="0"
            />
          </div>

          <div>
            <Label for="addressReciving">Marketing Address</Label>
            <Input
              onChange={handleChange}
              type="text"
              name="marketingAddress"
              placeholder={`${formData.marketingAddress}`}
            />
          </div>

          <div>
            <Label for="charityFeePercent">Marketing Fee Percent</Label>
            <Input
              onChange={handleChange}
              name="charityFeePercent"
              type="number"
              placeholder="0"
            />
          </div>

          <div className="col-span-2 w-full text-center mt-2">
            <PrimaryButton>Submit</PrimaryButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default withAuthenticationRequired(CreateLiquidityTokenComponent, {
  onRedirecting: () => <Loading />,
});
