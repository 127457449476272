import React, { useState, useEffect } from "react";
import { Input } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries";
import { createNftInput } from "./nftActions";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { PrimaryButton } from "../../components/Buttons";
import { useHistory } from 'react-router-dom';

import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export const NFTEditComponent = () => {
  let { NFTCollectionId } = useParams();
  const history = useHistory();

  const { invokeEndpoint } = useBlockOneEndpoint();

  const { findNFTCollectionByID } = useFaunaGQLUtils();

  console.log("NFTCollectionId " + NFTCollectionId);

  const { updateNFTCollection } = useFaunaGQLUtils();

  const [updatePublicMintSC, setUpdatePublicMintSC] = useState(false);

  const [updateMintPrice, setUpdateMintPrice] = useState(false);

  const [blockchainActionsCount, setBlockchainActionCount] = useState(0);

  const [loading, setLoading] = useState({
    loading: false,
    loadingMessage: "",
  });

  const [NFdivetails, setNFdivetails] = useState({});

  const [editorState, setEditorState] = useState(EditorState.createEmpty());


  const onEditorStateChange = (editorState) => {
    const description =   draftToHtml(convertToRaw(editorState.getCurrentContent()))

  
    setNFdivetails({
      ...NFdivetails,
      "description": description
  });
    setEditorState(editorState)
};

  const renderEditor = () => {
      return (

          
          <div>
          <Editor
            editorState={editorState}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor border border-sky-500"
            onEditorStateChange={onEditorStateChange}
            
          />
      
      {/* <textarea
        disabled
        value=
        {draftToHtml(convertToRaw(editorState.getCurrentContent()))}
      /> */}
        </div>

      )
  }

  const handleChange = async (e) => {
    const targetName = e.target.name;
    let targetValue = e.target.value;
    if (targetName === "mintPrice") {
      targetValue = Number(targetValue) * 10 ** NFdivetails.mintToken.decimals;
      targetValue = String(targetValue.toLocaleString('fullwide', {useGrouping:false}))


      if (targetValue !== NFdivetails.mintPrice) {
        setUpdateMintPrice(true);
      } else {
        setUpdateMintPrice(false);
      }
    }

    setNFdivetails({
      ...NFdivetails,
      [targetName]: targetValue,
    });
  };

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      const NFdivetails = await findNFTCollectionByID(NFTCollectionId);
      console.log("NFdivetails2 " + JSON.stringify(NFdivetails));
      setNFdivetails(NFdivetails);
      // NFdivetails.description
      // editorState
      // editorState.
      const blocksFromHTML = convertFromHTML(
       NFdivetails.description
      );

      
const content = ContentState.createFromBlockArray(
  blocksFromHTML.contentBlocks,
  blocksFromHTML.entityMap
);


      setEditorState(EditorState.createWithContent(content))
      setLoading({
        loading: false,
      });
    };
    getMarketPlacesFauna();
  }, [NFTCollectionId, findNFTCollectionByID]);

  const handleSubmit = async () => {
    // contractAddress: string, creator: string, owner: string,
    //  description: string, mindivecimals: number, mintTokenName: string,
    //  publicMint :boolean
    const nft = NFdivetails;
    console.log("NFdivetails " + JSON.stringify(NFdivetails));
    NFdivetails.mintPrice = String(NFdivetails.mintPrice.toLocaleString('fullwide', {useGrouping:false}))

    const nftInput = createNftInput(
      nft.contractAddress,
      nft.owner,
      nft.description.trim(),
      nft.mintDecimals,
      nft.mintTokenName,
      NFdivetails.publicMint,
      (NFdivetails.collectionImage || "").trim(),
      (NFdivetails.banner || "").trim(),
      NFdivetails.mintPrice
    );

    if (updatePublicMintSC) {
      //invoke endpint with NFdivetails.publicMint
      setBlockchainActionCount((prev) => prev + 1);
      console.log("blockchainActionsCount " + blockchainActionsCount);
      const data = {
        contractToExecute: nft.contractAddress,
        smartContractParams: [NFdivetails.publicMint],
        smartContractMethod: "setPublicMint",
      };
      await invokeEndpoint("smartcontract/invokemethod", data, async () => {
        await updateNFTCollection(NFTCollectionId, nftInput);
        setBlockchainActionCount((prev) => prev - 1);
      });
    }

    if (updateMintPrice) {
      console.log("update execute mint price");

      setBlockchainActionCount((prev) => prev + 1);

      console.log("blockchainActionsCount " + blockchainActionsCount);

      const data = {
        contractToExecute: nft.contractAddress,
        smartContractParams: [String(NFdivetails.mintPrice)],
        smartContractMethod: "setNFTMintPrice",
      };
      await invokeEndpoint("smartcontract/invokemethod", data, async () => {
        console.log(`notcalled? updateMintPrice ${blockchainActionsCount}`);
        setBlockchainActionCount((prev) => prev - 1);
        console.log(
          `notcalled?0 updateMintPrice res ${blockchainActionsCount}`
        );

        await updateNFTCollection(NFTCollectionId, nftInput);
      });
    } else {
      setLoading({
        loading: true
      })
      console.log("no smart contract to change");
      await updateNFTCollection(NFTCollectionId, nftInput);

      //   setLoading((oldLoading) => ({
      //     ...oldLoading,
      //     loadingMessage:"Upadting minting price on the blockchain"
      //  }))
    }
    

    setLoading({
      loading: false
    })

    history.push(`/admin/nft/view/${NFTCollectionId}`)


  };

  useEffect(() => {
    console.log("use effect blockchainActionsCount");
    if (blockchainActionsCount > 0) {
      setLoading({
        loading: true,
        loadingMessage:
          "performing blockchain action please wait this could take a few minutes ",
      });
    } else {
      setLoading({
        loading: false,
      });
    }
  }, [blockchainActionsCount, setLoading]);

  if (loading.loading) {
    return <Loading message={loading.loadingMessage}></Loading>;
  }

  return (
    <div>
      <div>
        <div className="text-center mt-4">
          <img
            className="w-40 inline mr-4 rounded-md"
            src={NFdivetails.collectionImage}
            alt="token Logo"
          />
        </div>
        <div className="font-semibold	 text-center my-4	w-full text-lg"></div>
      </div>

      <div className="grid gap-5 grid-cols-4 align-items-center profile-header mb-5 text-center text-md-left">
        {/* <h2> NFT Details</h2>
        {blockchainActionsCount} */}

        <div>Name</div>
        <div className="col-span-3"> {NFdivetails.name}</div>

        <div>Symbol</div>
        <div className="col-span-3">{NFdivetails.symbol}</div>

        <div>ContractAddress</div>
        <div className="col-span-3">{NFdivetails.contractAddress}</div>

        <div>Collection Banner</div>
        <div className="col-span-3">
          <Input
            name="banner"
            onChange={handleChange}
            value={NFdivetails.banner}
          ></Input>
          </div>

        <div>Collection Image</div>
        <div className="col-span-3">
          <Input
            name="collectionImage"
            onChange={handleChange}
            value={NFdivetails.collectionImage}
          ></Input>
        </div>
        <div>Public Mint</div>
        <div className="col-span-3">
          <Input
            onClick={() => {
              setNFdivetails({
                ...NFdivetails,
                publicMint: !NFdivetails.publicMint,
              });
              setUpdatePublicMintSC(!updatePublicMintSC);
            }}
            type="checkbox"
            checked={NFdivetails.publicMint}
          />
        </div>

        <div>Mint Price</div>
        <div className="col-span-3">
          <Input
            value={
              Number(NFdivetails.mintPrice) /
              10 ** NFdivetails.mintToken?.decimals
            }
            onChange={handleChange}
            name="mintPrice"
          ></Input>
        </div>

        <div>Mint using Token</div>
        <div className="col-span-3">{NFdivetails.mintToken?.name}</div>

        <div>NFT Collection Description</div>
        <div className="col-span-3">
          
        {/* <Input
            name="description"
            onChange={handleChange}
            value={NFdivetails.description}
          ></Input> */}

          {renderEditor()}

          </div>


            <div className="col-span-4 text-center">

            <PrimaryButton
          onClick={() => {
            handleSubmit();
          }}
        >
          update NFT Collection
        </PrimaryButton>

            </div>
      
      </div>
    </div>
  );
};

export default withAuthenticationRequired(NFTEditComponent, {
  onRedirecting: () => <Loading />,
});
