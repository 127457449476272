import React, { useEffect, useState } from "react";

import { useBlockOneEndpoint } from "../../../../../utils/blockOneAPI";
import { useCactusChain } from "../../../../..//hooks/ChainUtils";
import { PrimaryButton } from "../../../../../components/Buttons";
import {  useCactusNativeBalance } from "../../../../../hooks/ChainUtils";
import { useGasCheckedComponents } from "../../../../../components/GasCheckedButton";
import { BlockchainAction } from "../../../../../types";
export const CheckForUserGas = ({setNextStatus, connectedAddress}) => {
  const { invokeEndpoint } = useBlockOneEndpoint();
  const { chain } = useCactusChain();

  console.log(`connectedAddress ${connectedAddress}`)
  const { balance, getBalance } = useCactusNativeBalance({
    chain: chain?.chainId,
    address:connectedAddress
  });
  const [gettingGas, setGettingGas] = useState(false);
  const minGasForBuy = 0.02;

  const { GasCheckedButton } = useGasCheckedComponents(
    BlockchainAction.TransferTokens
  );

  useEffect(() => {});

  const renderNFTStatus = () => {

    if ((Number(balance) || 0)  < minGasForBuy) {
     
      return (
        <div className="mt-4" >
          <div className="flex justify-center">
          You do not have enough gas in your connected wallet to buy the NFT but you can get it from your treasury

          </div>

          <div className="flex justify-center mt-4">
            <GasCheckedButton
            loading={gettingGas}
              onClick={() => {
                setGettingGas(true);
                invokeEndpoint(
                  "gas/transfer",
                  {
                    amount: String(minGasForBuy),
                    to: connectedAddress,
                  },
                  async () => {
                    await getBalance();
                    setGettingGas(false);
                  }
                );
              }}
              buttonText={`Get ${minGasForBuy} ${chain?.nativeCurrency?.symbol} from the treasury`}
            >
          
            </GasCheckedButton>
          </div>
        </div>
      );
    } else {
      return (
        <div className="mt-4" >
          <div className="flex justify-center">
            You have enough gas in your connected wallet to buy the NFT
            </div>
            <div className="flex justify-center mt-4">
              <PrimaryButton
              onClick={() => {
                setNextStatus()
              }}>
                Continue
              </PrimaryButton>

            </div>
        </div>
      )
    }
  };
  return (
    <div class="flex flex-row min-h-screen justify-center items-center ">
      <div class="h-96 rounded overflow-hidden shadow-lg w-8/12">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">
            Gas balance
          </div>
          <div>
            Blockchain transactions require gas, paid in native currency (ETH, BNB, etc)
            buying the NFT in the marketplace needs {minGasForBuy} {chain?.nativeCurrency?.symbol} in your wallet 
          </div>
          <span class="text-gray-700 text-base"></span>

          <div>{renderNFTStatus()}</div>
        </div>
      </div>
    </div>
  );
};
