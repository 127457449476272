import React from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useCactusChain } from "../../hooks/ChainUtils";

import Constants from "../../utils/constants";

export const OnboardingChooseNetwork = () => {

  const { switchNetwork, chain } = useCactusChain();

  const renderActionPanel = () => {
    if (
      !chain || 
      (
        chain.chainId !== Constants.BINANCE_TESTNET_BLOCKCHAIN_ID &&
      chain.chainId !== Constants.POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID
      )
      
    ) {
      return renderSelectChain();
    }
  };

  const renderSelectChain = () => {
    return (
      <div>
        <div>
          Next we will select a testnet blockchain to build our first
          application
        </div>
        <div>Select One Testnet blockchain</div>

        <div class="grid grid-cols-2 gap-4 mt-4">
          <div
            className=" cursor-pointer hover:shadow-lg"
            onClick={() => {
              switchNetwork(Constants.BINANCE_TESTNET_BLOCKCHAIN_ID);
            }}
          >
            <div className="flex justify-center h-32 ">
              <img src={Constants.BSC_LOGO_IMG_URL} alt="Binance Logo"></img>
            </div>
            <div>Binance</div>
          </div>

          <div
            className=" cursor-pointer hover:shadow-lg"
            onClick={() => {
              switchNetwork(Constants.POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID);
            }}
          >
            <div className="flex justify-center h-32 ">
              <img
                src={Constants.POLYGON_LOGO_IMG_URL}
                alt="Binance Logo"
              ></img>
            </div>
            <div>Polygon</div>
          </div>
        </div>
      </div>
    );
  };

  return <div>{renderActionPanel()}</div>;
};

export default withAuthenticationRequired(OnboardingChooseNetwork, {
  onRedirecting: () => <Loading />,
});
