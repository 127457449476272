import { useWeb3ExecuteFunction, useCactusChain } from "../hooks/ChainUtils";
import Constants from "../utils/constants";
import { useMultiChainUtils } from "../utils/multiChainUtils";
import { useFaunaUtils } from "../utils/faunaClient";
import { faunaCollections } from "../utils/faunaCollections";
import { useState } from "react";
// import { LiquidityTokenFactoryABI } from "./../contracts/abis/LiquidityFactory";
import { ERC20MinterPauserABI } from "./../contracts/abis/ERC20MinterPauser";
import { useBlockOneEndpoint } from "./../utils/blockOneAPI";


const useERC20TokenActions = () => {
  const { chainId } = useCactusChain();
  const { getLiquidityTokenRouter } =
    useMultiChainUtils();
  const { createDocument } = useFaunaUtils();
  const [createdERC20Token, setCreatedERC20Token] = useState();
  const [creatingToken, setCreatingToken] = useState(false);
  const { invokeEndpoint } = useBlockOneEndpoint();


  const contractProcessor = useWeb3ExecuteFunction();

  const mintTokens = async (
    mintAmount: number,
    tokenDecimals: number,
    contractAddress: string,
    mintToAddress: string
  ) => {
    const amount = Number(mintAmount) * 10 ** tokenDecimals;
    const amountFormated = amount.toLocaleString("fullwide", {
      useGrouping: false,
    });
    // console.log("amount " + amount.to)
    const options = {
      chain: chainId,
      contractAddress: contractAddress,
      functionName: "mint",
      abi: ERC20MinterPauserABI,
      params: {
        to: mintToAddress,
        amount: amountFormated,
      },
    };

    await contractProcessor.fetch({
      params: options,
      onSuccess: async (success) => {
        console.log("success " + JSON.stringify(success));
      },
      onError: (error) => {
        // setLoading(false)

        alert("error " + error);
      },
    });
  };

  // const createERC20LiquidityToken = async (
  //   name: string,
  //   symbol: string,
  //   supply: number,
  //   marketingAddress: string,
  //   taxFeePercent: number,
  //   liquidityFeePercent: number,
  //   charityFeePercent: number,
  //   logo: string
  // ) => {
  //   enableWeb3();

  //   const options = {
  //     chain: chainId,
  //     contractAddress: getLiquidityFactory(),
  //     functionName: "create",
  //     abi: LiquidityTokenFactoryABI,
  //     params: {
  //       name,
  //       symbol,
  //       totalSupply: String(supply * 10 ** 9),
  //       router: getLiquidityTokenRouter(),
  //       charity: marketingAddress,
  //       taxFeeBps: taxFeePercent * 100,
  //       liquidityFeeBps: liquidityFeePercent * 100,
  //       charityBps: charityFeePercent * 100,
  //     },
  //   };

  //   setCreatingToken(true);
  //   await contractProcessor.fetch({
  //     params: options,
  //     onSuccess: async (success: any) => {
  //       const createEvent = success.events["0"];
  //       if (createEvent) {
  //         const contractCreatedAddress = createEvent.address;

  //         const data = {
  //           contractAddress: contractCreatedAddress,
  //           type: Constants.ERC20TokenTye.LIQUIDITY,
  //           creator: account,
  //           name: name,
  //           symbol: symbol,
  //           decimals: Constants.ERC20_LIQUIDITY_TOKEN_DECIMALS,
  //           totalSupply: options.params.totalSupply,
  //         };
  //         const created = await createDocument(faunaCollections.ERC20Token, {
  //           ...data,
  //           logo: logo,
  //         });
  //         setCreatedERC20Token(created);
  //         setCreatingToken(false);
  //       }
  //     },
  //     onError: (error) => {
  //       alert("error " + error);
  //       setCreatingToken(false);
  //     },
  //   });
  // };

  // const createERC20Token = async (
  //   tokenName: string,
  //   tokenSymbol: string,
  //   logoURL: string
  // ) => {
  //   enableWeb3();

  //   const options = {
  //     chain: chainId,
  //     contractAddress: getBasicERC20Factory(),
  //     functionName: "deployERC20Contract",
  //     abi: BasicERC20FactoryABI,
  //     params: {
  //       name: tokenName,
  //       symbol: tokenSymbol,
  //     },
  //   };

  //   setCreatingToken(true);
  //   await contractProcessor.fetch({
  //     params: options,
  //     onSuccess: async (success: any) => {
  //       const createEvent = success.events["0"];
  //       if (createEvent) {
  //         const contractCreatedAddress = createEvent.address;

        
  //         persistCreatedToken(contractCreatedAddress, tokenName, tokenSymbol, logoURL);
  //       }
  //     },
  //     onError: (error) => {
  //       setCreatingToken(false);

  //       alert("error " + error);
  //     },
  //   });
  // };


  
   const persistCreatedToken =  async (contractCreatedAddress: string,
    tokenName: string, tokenSymbol: string, logoURL:string = "") => {

  const data = {
    contractAddress: contractCreatedAddress,
    type: Constants.ERC20TokenTye.BASIC,
    decimals: Constants.ERC20_DEFAULT_DECIMALS,
    name: tokenName,
    symbol: tokenSymbol,
    logoURL: logoURL,
    totalSupply: 0,
  };

  const created = await createDocument(faunaCollections.ERC20Token, {
    ...data,
    logo: logoURL,
  });
  setCreatedERC20Token(created);
  setCreatingToken(false);

  }


  type ERC20Definition = {
    name: string;
    symbol: string;
    logoURL: string;
  };

  type LiquidityERC20Definition = {
    name: string,
    symbol: string,
    supply: number,
    marketingAddress: string,
    taxFeePercent: number,
    liquidityFeePercent: number,
    charityFeePercent: number,
    logo: string

  }

  
  

  const createERC20LiquidityTokenFromTreasury = (definition: LiquidityERC20Definition) => {
    setCreatingToken(true);

    const extraCreateParams = [definition.supply,getLiquidityTokenRouter(), definition.marketingAddress, definition.taxFeePercent * 100, definition.liquidityFeePercent * 100 , definition.charityFeePercent * 100];

    const createRequestBody  = {
      name: definition.name,
      symbol: definition.symbol,
      extraParams: extraCreateParams,
      type: `LiquidityToken`,
    }

    invokeEndpoint(
      "ERC20/create",
      createRequestBody,
      async (result: { contractAddress: string; error: string }) => {
        console.log("contractAddress setlaoding " + result.contractAddress);
        if (result.contractAddress) {
            persistCreatedToken(result.contractAddress, definition.name,
                 definition.symbol, definition.logo);
        } else {
          console.log(`${JSON.stringify(result)}`)
          alert(result.error);
        }
      }
    );


  }
  const createERC20FromTreasury = (definition: ERC20Definition) => {
    setCreatingToken(true);

    const createRequestBody  = {
      name: definition.name,
      symbol: definition.symbol,
  }

  console.log(`createRequestBody ${JSON.stringify(createRequestBody)}`)
    invokeEndpoint(
      "ERC20/create",
      createRequestBody,
      async (result: { contractAddress: string; error: string }) => {
        console.log("contractAddress setlaoding " + result.contractAddress);
        if (result.contractAddress) {
            persistCreatedToken(result.contractAddress, definition.name,
                 definition.symbol, definition.logoURL);
        } else {
          console.log(`${JSON.stringify(result)}`)
          alert(result.error);
        }
      }
    );
  };

  return {
    // createERC20Token,
    // createERC20LiquidityToken,
    createERC20LiquidityTokenFromTreasury,
    mintTokens,
    createERC20FromTreasury,
    createdERC20Token,
    creatingToken,
    persistCreatedToken
  };
};

export { useERC20TokenActions };
