import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import { useFaunaUtils } from '../../utils/faunaClient';
import { faunaCollections } from "../../utils/faunaCollections";
import { Link } from 'react-router-dom';

export const ListNFTsComponent = () => {

    const { listAllForOwner, findDocumentById} = useFaunaUtils();

    const [loading, setLoading] = useState(true)
    const [marketplaces, setMarketplaces] = useState([
  
    ])

    const [ERC20Tokens, setERC20Tokens] = useState({})

   useEffect(() => {
    const getMarketPlacesFauna = async() => {
        const nftCollections = await listAllForOwner(faunaCollections.NFTCollection)
        console.log("nftCollections " + JSON.stringify(nftCollections))
        nftCollections.forEach(async (collection) => {
            if(collection.mintToken){
                const erc20MintId = collection.mintToken["@ref"].id
            const erc20Document = await findDocumentById(faunaCollections.ERC20Token,erc20MintId)

            console.log("mintmintToken " + JSON.stringify(erc20Document))
            setERC20Tokens(tokens => ({
                ...tokens,
                [erc20MintId]:erc20Document,
            }))

            }
            

        })
        setMarketplaces(nftCollections)
        setLoading(false)
    }
    getMarketPlacesFauna()

   },[listAllForOwner, findDocumentById])


    const renderNFTList = () => {
        
        const result = marketplaces.map( (marketplace, idx) => {
            console.log("NFTAA " + JSON.stringify(marketplace))
            const link = `/admin/nft/view/${marketplace.id}`
            return (
                <tr key={idx} onClick={() => {
                    console.log("lets go to id")
                }}>
      <td className="break-words w-20" >
      <Link to={link} >

      {marketplace.name} 

                </Link>

      </td>
      <td>
          <img
          className="h-36"
          src={marketplace.collectionImage} alt="NFT Collection">
          </img>
      </td>
      <td>
{marketplace.mintPrice / 10** marketplace.mintDecimals} {' '}
{        

marketplace.mintToken && ERC20Tokens[marketplace.mintToken["@ref"].id]?.name

}
      </td>
    </tr>
                
            )
        })
        return result;
    }

    if(loading){
        return <Loading></Loading>
    }

    return (
        <Container className="mb-5">

            <Row className="align-items-center profile-header mb-5 text-center text-md-left">


                <Table responsive className="table-fixed">
  <thead className="bg-gray-50 text-gray-500	">
    <tr>
      <th>
        Name
      </th>
      <th>
        
      </th>
      <th>
          Mint Price
      </th>
    </tr>
  </thead>
  <tbody>
  {renderNFTList()}
  </tbody>
</Table>


            </Row>
        </Container>
    );
};

export default withAuthenticationRequired(ListNFTsComponent, {
    onRedirecting: () => <Loading />,
});
