import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from "reactstrap";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { useParams } from "react-router-dom";

import { useHistory } from "react-router-dom";

import { PrimaryButton } from "../../components/Buttons";

import { useMultiChainUtils } from "../../utils/multiChainUtils";
//
export const ERC20ViewComponent = () => {
  let { tokenId } = useParams();

  const history = useHistory();

  const { findDocumentById } = useFaunaUtils();

  let { getExplorerURLForContract } = useMultiChainUtils();

  const [loading, setLoading] = useState(true);
  const [TokenDetails, setTokenDetails] = useState([]);

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      console.log("getMarketPlacesFauna start");
      const NFTDetails = await findDocumentById(
        faunaCollections.ERC20Token,
        tokenId
      );
      console.log("ERC20Token " + JSON.stringify(NFTDetails));
      setTokenDetails(NFTDetails);
      setLoading(false);
    };
    getMarketPlacesFauna();
  }, [tokenId, findDocumentById]);

  if (loading) {
    return <Loading></Loading>;
  }
  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <div className="w-screen">
          <div className="font-semibold	 text-center my-4	w-full text-lg">
            <img
              className="w-20 inline mr-4"
              src={TokenDetails.logoURL}
              alt="token Logo"
            />{" "}
            {TokenDetails.name}
          </div>

          <PrimaryButton
            className="                float-right"
            onClick={() => {
              history.push(`/admin/erc20/actions/${tokenId}`);
            }}
          >
            Edit Token
          </PrimaryButton>
        </div>

        <Table responsive>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{TokenDetails.name}</td>
            </tr>
            <tr>
              <td>Symbol</td>
              <td>{TokenDetails.symbol}</td>
            </tr>
            <tr>
              <td>Contract Address</td>
              <td>{TokenDetails.contractAddress}</td>
            </tr>
            <tr>
              <td>Total Supply</td>
              <td>
                {parseInt(TokenDetails.totalSupply) /
                  10 ** TokenDetails.decimals}
              </td>
            </tr>
            <tr>
              <td>Decimals </td>
              <td>{TokenDetails.decimals}</td>
            </tr>

            <tr>
              <td>Explorer View </td>
              <td>
                <a
                  href={`${getExplorerURLForContract(
                    TokenDetails.contractAddress
                  )}`}
                >
                  {getExplorerURLForContract(TokenDetails.contractAddress)}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ERC20ViewComponent, {
  onRedirecting: () => <Loading />,
});
