import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from "reactstrap";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { useParams } from "react-router-dom";

import { useMultiChainUtils } from "../../utils/multiChainUtils";
import { PrimaryButton } from "../../components/Buttons";
import Input from "../../components/Input";
import { useERC20TokenActions } from "../../hooks/ERC20Actions";
export const ERC20ActionsComponent = () => {
  let { tokenId } = useParams();

  const { mintTokens } = useERC20TokenActions();

  const { findDocumentById, updateDocument } = useFaunaUtils();

  let { getExplorerURLForContract } = useMultiChainUtils();

  const [loading, setLoading] = useState(true);
  const [TokenDetails, setTokenDetails] = useState([]);

  const [displayMintTokens, setDisplayTokens] = useState(false);

  const [mintData, setMintData] = useState({
    to: "",
    amount: 0,
  });

  const handleMintChange = (e) => {
    setMintData({
      ...mintData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleChange = (e) => {
    setTokenDetails({
      ...TokenDetails,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const handleSubmit = () => {
    updateDocument(faunaCollections.ERC20Token, TokenDetails, tokenId);
  };

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      const erc20Details = await findDocumentById(
        faunaCollections.ERC20Token,
        tokenId
      );
      setTokenDetails(erc20Details);
      setLoading(false);
    };
    getMarketPlacesFauna();
  }, [tokenId, findDocumentById]);

  const renderMintTokensAction = () => {
    if (displayMintTokens) {
      return (
        <div className="p-2">
          <div className="flex my-3">
            <div className="w-28">Mint Amount</div>

            <Input
              name="amount"
              value={mintData.amount}
              onChange={handleMintChange}
            />
          </div>

          <div className="flex">
            <div className="w-28">To Address</div>

            <Input name="to" value={mintData.to} onChange={handleMintChange} />
          </div>

          <PrimaryButton
            className="mt-4"
            onClick={() => {
              mintTokens(
                mintData.amount,
                TokenDetails.decimals,
                TokenDetails.contractAddress,
                mintData.to
              );
            }}
          >
            Mint!
          </PrimaryButton>
        </div>
      );
    }
  };

  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <Container className="mb-5">
      <Row className="align-items-center profile-header mb-5 text-center text-md-left">
        <div>
          <h2> ERC20 Token Actions</h2>
        </div>

        <Table responsive>
          <tbody>
            <tr>
              <td>Name</td>
              <td>{TokenDetails.name}</td>
            </tr>
            <tr>
              <td>Symbol</td>
              <td>{TokenDetails.symbol}</td>
            </tr>
            <tr>
              <td>Contract Address</td>
              <td>{TokenDetails.contractAddress}</td>
            </tr>

            <tr>
              <td>Token Logo</td>
              <td>
                <Input
                  name="logoURL"
                  value={TokenDetails.logoURL}
                  onChange={handleChange}
                />
              </td>
            </tr>

            <tr>
              <td>Total Supply</td>
              <td>
                {parseInt(TokenDetails.totalSupply) /
                  10 ** TokenDetails.decimals}
              </td>
            </tr>

            <tr>
              <td>Decimals </td>
              <td>{TokenDetails.decimals}</td>
            </tr>

            <tr>
              <td>Explorer View </td>
              <td>
                <a
                  href={`${getExplorerURLForContract(
                    TokenDetails.blockchainId,
                    TokenDetails.contractAddress
                  )}`}
                >
                  {getExplorerURLForContract(
                    TokenDetails.blockchainId,
                    TokenDetails.contractAddress
                  )}
                </a>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>

      <div className="flex justify-center mb-5">
        <PrimaryButton
          onClick={() => {
            handleSubmit();
          }}
        >
          Update ERC20 Token Details{" "}
        </PrimaryButton>
      </div>
      <Row>
        <Col className="bg-light border">
          <h2>Actions</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <PrimaryButton
            onClick={() => {
              setDisplayTokens(!displayMintTokens);
            }}
          >
            Mint Tokens
          </PrimaryButton>
          {renderMintTokensAction()}
        </Col>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(ERC20ActionsComponent, {
  onRedirecting: () => <Loading />,
});
