import { BlockchainAction } from './../types/index';
import { useFaunaGQLUtils } from "./faunaGraphQLQueries";
import { useCactusChain } from "../hooks/ChainUtils";
import { useBlockOneEndpoint } from "./blockOneAPI";
import { UserContext } from "../context/userContext";
import { useContext } from "react";
import { useCactusNativeBalance } from "../hooks/ChainUtils";
import Constants from "./constants";
import { useState, useEffect } from "react";

// let GAS_PRICE_BUFFER = 2;

const useGasStationUtils = (action: BlockchainAction) => {
  const userContext = useContext(UserContext);

  const { findGasEstimateByActionAndBlockchain } = useFaunaGQLUtils();
  const { chain,chainId } = useCactusChain();
  const { invokeEndpoint } = useBlockOneEndpoint();
  const [loadingData, setLoadingData] = useState(false)

  const GAS_PRICE_BUFFER = Constants.POLYGON_TESTNET_MUMBAI_BLOCKCHAIN_ID === chainId ? 0.7 : 2

  console.log(`GAS_PRICE_BUFFER ${GAS_PRICE_BUFFER}`)

  const [gasStationStatus, setGasStationStatus] = useState<any>()

  const chainIds = ["eth" , "0x1" , "ropsten" , "0x3" , "rinkeby" , "0x4" , "goerli" , "0x5" , "kovan" , "0x2a" , "polygon" , "0x89" , "mumbai" , "0x13881" , "bsc" , "0x38" , "bsc testnet" , "0x61" , "avalanche" , "0xa86a" , "avalanche testnet" , "0xa869" , "fantom" , "0xfa"] as const;
type ChainIds = typeof chainIds[number];

  const asd: ChainIds = chainId as ChainIds

  const gasAddress = userContext.gasAddress;

    const { balance } = useCactusNativeBalance({
      chain:asd,
      address: gasAddress,
    });

    
    useEffect(() => {
      const fetchData = async () => {

        if(!chain || !chainId){
          throw Error("Chain is not set")
        }
    const gasEstimateForAction = await findGasEstimateByActionAndBlockchain(
      chain.name,
      action
    );
    if(!invokeEndpoint) return;
    const gasPrice = await invokeEndpoint("info/transaction/gasprice",
    {chainId}
    );
    console.log("gasPrice " + JSON.stringify(gasPrice))

    const WeiGasPrice = Number(gasPrice.gasPrice)
    const adjustedGasPrice = WeiGasPrice * GAS_PRICE_BUFFER

    console.log("GWeiGasPrice " + adjustedGasPrice)
    console.log("debug222 "  + JSON.stringify(gasEstimateForAction))
    if(!gasEstimateForAction){
      console.log("could not find gas price setGasStationStatus")
      setGasStationStatus({
        hasEnoughGas: false,
        gasAddress

      })
      return;
     
    }

    const gasRequired = gasEstimateForAction.gasRequired * adjustedGasPrice;
    const gasStationBalance = Number(balance) * Math.pow(10, chain.nativeCurrency.decimals)

    console.log("balancebalance " + JSON.stringify(balance))

    console.log("gastouse " + gasRequired+ " vs gas station balance " + balance)

    console.log("ASD11" + JSON.stringify(balance))
    const hasEnoughGas = gasStationBalance > gasRequired
    console.log("has enough balance "  + String(
      hasEnoughGas
    ))


    
    console.log("gasRequired " + gasEstimateForAction.gasRequired )
    const       formatedGasRequired = (gasRequired/10**18);


    console.log("formatedGasRequired " + (gasRequired/10**18) )

    // console.log("nativeToken22 " + JSON.stringify(nativeToken))
    const result = {
      hasEnoughGas,
      gasRequired,
      formatedGasRequired,
      gasStationBalance,
      nativeToken: chain?.nativeCurrency?.symbol,
      gasAddress,
      errorMessage: ""
    }

    if(!hasEnoughGas){
      result.errorMessage = `
      You treasury account does not have enough gas for this 
      transaction, please deposit ${formatedGasRequired} 
      ${chain?.nativeCurrency?.symbol}
      `;
    }
    console.log("result22  setGasStationStatus " +JSON.stringify(result))
    setGasStationStatus(result) ;

      }

      console.log("balance && !gasStationStatus " + JSON.stringify(balance))
      if(balance && !gasStationStatus && !loadingData && chain && chainId){
        setLoadingData(true)
        fetchData()
      }

      

    },[balance, loadingData,
       setLoadingData, gasAddress, gasStationStatus, action,
        chainId, chain?.name, chain,  findGasEstimateByActionAndBlockchain, invokeEndpoint, GAS_PRICE_BUFFER])



  // const hasEnoughGasForAction = async (action) => {

    
    
  return {gasStationStatus}

  // };

  // return { hasEnoughGasForAction };
};

export { useGasStationUtils };
