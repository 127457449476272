import React from "react";

const Input = ({name, onChange, value, className}) => (
  
    <input 
        name={name}
        value={value}
        onChange={onChange}
        className=
        {`appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline ${className}`}>
        </input>
);

export default Input;
