import React, { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Table } from 'reactstrap';
import { useFaunaUtils } from '../../utils/faunaClient';
import { faunaCollections } from "../../utils/faunaCollections";
import { Link } from 'react-router-dom';
import { useCactusChain } from "../../hooks/ChainUtils";
import { useFaunaGQLUtils } from "../../utils/faunaGraphQLQueries"

export const ListMarketplacesComponent = () => {

    const [loading, setLoading] = useState(true)
    const { chain } = useCactusChain();
    const { findMarketplaceByID } = useFaunaGQLUtils()


    const [marketplaces, setMarketplaces] = useState([])

    const { listAllForOwner } = useFaunaUtils();

    useEffect(() => {

        const getMarketPlacesFauna = async () => {
            const mkts = await listAllForOwner(faunaCollections.Marketplace)
            const promises = mkts.map(async marketplace => {
                const marketplaceDoc = await findMarketplaceByID(marketplace.id)
                return marketplaceDoc
            })
            const markplacesDetails = await Promise.all(promises)
            console.log("markplacesDetails2 " + JSON.stringify(markplacesDetails))
            setLoading(false)
            setMarketplaces(markplacesDetails)

        }
        getMarketPlacesFauna()

    }, [listAllForOwner, chain?.name, findMarketplaceByID])

    const renderNFTList = () => {
        const result = marketplaces.map((marketplace, idx) => {
            console.log("NFTAA " + JSON.stringify(marketplace))
            const marketplaceNFTs =  marketplace.marketplaceNFTCollections.data.map(x => x.NFTCollection).filter(x => x != null);
            console.log("marketplaceNFT2 " + JSON.stringify(marketplaceNFTs))
            const link = `/admin/marketplace/view/${marketplace._id}`
            return (
                <tr key={idx} onClick={() => {
                    console.log("lets go to id")
                }}>
                    <td >
                        <Link to={link} >

                            {marketplace.name}
                        </Link>

                    </td>
                    <td className="w-80" >
                        <div className="grid gap grid-cols-3">
                        {marketplaceNFTs.map(nftCollection => {
                            return(
                                <div className="h-24 w-24 m-2">
                                <div>
                                    <img src={nftCollection.collectionImage} alt="NFT"/>
</div>
                                </div>
                            )
                        })}

                        </div>
                          


                    </td>
                    <td>
                        <a
                        href={`https://marketplace.cactusfam.com/${marketplace._id}`}>
                            link
                        </a>
                    </td>
                </tr>

            )
        })
        return result;
    }



    if (loading) {
        return <Loading></Loading>

    }

    return (
        <Container className="mb-5">

            <Row className="align-items-center profile-header mb-5 text-center text-md-left">


                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                Name
                            </th>
                            <th>
                                NFTs In Marketplace
                            </th>
                            <th>
                                Marketplace Link
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderNFTList()}
                    </tbody>
                </Table>


            </Row>
        </Container>
    );
};

export default withAuthenticationRequired(ListMarketplacesComponent, {
    onRedirecting: () => <Loading />,
});
