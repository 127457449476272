import faunadb from "faunadb";
import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useCactusChain } from "../hooks/ChainUtils";
import { UserContext } from "../context/userContext";
import { useContext } from "react";
import { faunaCollections } from "./faunaCollections";

const useFaunaUtils = () => {
  const userContext = useContext(UserContext);

  let organizationId = userContext?.organizationId;

  const { user, getAccessTokenSilently } = useAuth0();

  let { chain, chainId } = useCactusChain();

  const getFaunaClient = useCallback(async () => {
    const maybeToken = await getAccessTokenSilently();

    const client = new faunadb.Client({
      secret: maybeToken,
      domain: "db.us.fauna.com",
    });

    return client;
  }, [getAccessTokenSilently]);

  const findDocumentById = useCallback(
    async (collection: string, id: string) => {
      const q = faunadb.query;

      const serverClient = await getFaunaClient();
      const document:any = await serverClient.query(
        q.Get(q.Ref(q.Collection(collection), id))
      );
      return document.data;
    },
    [getFaunaClient]
  );

  const listAllForOwner = useCallback(
    async (collection: string) => {
      const blockchainCollections = [
        "ERC20Token",
        "NFTCollection",
        "Marketplace",
      ];

      const q = faunadb.query;

      const serverClient = await getFaunaClient();

      let faunaResponse = await serverClient.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection(collection))),
          q.Lambda((x) => q.Get(x))
        )
      );
      const cleanRef = JSON.parse(JSON.stringify(faunaResponse));
      let result = cleanRef.data.map((x: any) => {
        const id = x.ref["@ref"].id;

        const data = {
          ...x.data,
          _id:id,
          id,
        };
        return data;
      });
      const blockchain = chain?.name;
      if (blockchain && blockchainCollections.indexOf(collection) > -1) {
        console.log(`filtering results for blockchain ${blockchain}`);
        result = result.filter((res:any) => {
          return res.blockchain === blockchain;
        });
      }
      console.log(JSON.stringify(result));
      return result;
    },
    [getFaunaClient, chain?.name]
  );

  const createRefArrayForDocuments = (collection:string, ids: Array<string>) => {
    const q = faunadb.query;
    return ids.map((id) => q.Ref(q.Collection(collection), id));
  };

  const createRefForDocument = (collection: string, id: string) => {
    const q = faunadb.query;
    return q.Ref(q.Collection(collection), id);
  };

  const createDocument = useCallback(
    async (collection: string, insertData: any) => {
      const q = faunadb.query;

      const client = await getFaunaClient();

      let data = {
        ...insertData,
        owner: user?.sub,
        blockchain: chain?.name,
        blockchainId: chainId,
      };
      if (collection !== faunaCollections.Organization) {
        data = {
          ...data,
          organization: createRefForDocument(
            faunaCollections.Organization,
            insertData.organizationId || organizationId
          ),
        };
      }
      console.log("insert data " + JSON.stringify(data));
      const result:any = await client.query(
        q.Create(q.Collection(collection), { data })
      );

      const cleanRef = JSON.parse(JSON.stringify(result));
      const insertedId = cleanRef.ref["@ref"].id;

      const insertedData = {
        ...result.data,
        id: insertedId,
        _id: insertedId,
      };

      if (collection === faunaCollections.Organization) {
        // eslint-disable-next-line
        organizationId = insertedId;
      }

      console.log("fauna create " + JSON.stringify(result));
      return insertedData;
    },
    [getFaunaClient, user?.sub, chain?.name, chainId, organizationId]
  );

  const updateDocument = useCallback(
    async (collection: string, insertData: any, docId: string) => {
      const q = faunadb.query;

      const client = await getFaunaClient();
      const updateData = {
        ...insertData,
        owner: user?.sub,
        organization: createRefForDocument(
          faunaCollections.Organization,
          organizationId
        ),
        blockchain: chain?.name,
      };
      console.log("update data " + JSON.stringify(updateData));
      const result: any = await client.query(
        q.Update(q.Ref(q.Collection(collection), docId), {
          data: updateData,
        })
      );

      const cleanRef = JSON.parse(JSON.stringify(result));
      const id = cleanRef.ref["@ref"].id;

      const data = {
        ...result.data,
        id,
      };

      console.log("fauna create " + JSON.stringify(result));
      return data;
    },
    [getFaunaClient, user?.sub, chain?.name, organizationId]
  );



  const deleteAllDocuments = (
    async (collection:string) => {
      const q = faunadb.query;

      const client = await getFaunaClient();

      let faunaResponse:any = await client.query(
        q.Map(
          q.Paginate(q.Documents(q.Collection(collection))),
          q.Lambda((x) => q.Get(x))
        )
      );

      const ids = faunaResponse.data.map((x:any) => 
      {
        const cleanRef = JSON.parse(JSON.stringify(x));
        return cleanRef.ref["@ref"].id

      }
     );
      // console.log(`DELETe faunaResponse ${JSON.stringify(ids)}`);
      ids.forEach((id: string) => {
        client.query(
          q.Delete(q.Ref(q.Collection(collection), id))
        )
        .then((ret) => console.log(ret))
        .catch((err) => console.error(
          'Error: [%s] %s: %s',
          err.name,
          err.message,
          err.errors()[0].description,
        ))

      })


      

    })



  return {
    findDocumentById,
    listAllForOwner,
    createDocument,
    updateDocument,
    createRefForDocument,
    createRefArrayForDocuments,
    deleteAllDocuments,
  };
};

export { useFaunaUtils };
