

const CreateNFTAccessComponent = () => {
    return (
        <div className="flex flex-row min-h-screen justify-center items-center ">
        <div className="h-96 rounded overflow-hidden shadow-lg w-8/12">
          <div className="px-6 py-4">
            <div className="text-center">
              
              hello world

              <input
                    // onChange={updateTokenDetails}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="name"
                    type="text"
                    placeholder=""
                  />
  
            </div>
          </div>
        </div>
      </div>
    )
}

export {CreateNFTAccessComponent}