import { useCallback } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { useState } from 'react';
import {
  useChain,// ok only onboarding
  useMoralis,
  useWeb3ExecuteFunction,
  useERC20Balances, // ok only onboarding
  useNFTBalances, // ok only onboarding
} from "react-moralis";

import { UserContext } from '../context/userContext';
import { Chain } from "./ChainContextUtils/types";
import { chainMap, BSC_TESNET } from './ChainContextUtils/BlockchainsSupported';

import { useBlockOneEndpoint } from '../utils/blockOneAPI';
const useCactusChain = () => {
    const userContext = useContext(UserContext);
    const currentChain = userContext?.currentChain

    const [chain, setChain]= useState<Chain>();
    const [chainId, setChainId]= useState<string>();

    useEffect(() => {
      if(currentChain){
        setChain(chainMap.get(currentChain.chainId));
        setChainId(currentChain.chainId);
      } else {
        setChain(BSC_TESNET);
        setChainId(BSC_TESNET.chainId);
        userContext?.setCurrentChain(BSC_TESNET);
      }

    },[currentChain, userContext])


    const switchNetwork = (providedChainId: string) => {
        const chain: Chain | undefined = chainMap.get(providedChainId);
        if (chain) {
          console.log(`cactus Switching to ${chain.name} chainId: ${chain.chainId}`);
            setChain(chain);
            setChainId(chain.chainId);
            userContext.setCurrentChain(chain);
        }
    }

    
    return {chain, switchNetwork, chainId};
    

}

const useCactusNativeBalance = (options: { chain: string; address: string }) => {
  const { invokeEndpoint } = useBlockOneEndpoint();

  console.log(`useCactusNativeBalance ${JSON.stringify(options)}`)
  const userContext = useContext(UserContext);
  
  const currentChain = userContext?.currentChain

  const chainId = options?.chain || currentChain?.chainId

  const [balance, setBalance] = useState<string>();
  const getBalance = useCallback(async () => {
    if(options.address && chainId){

      const balance =  await invokeEndpoint(
        `gas/balance?address=${options.address}&chainId=${chainId}`,
        {},
        () => {},
        "GET"
      );
      
    
      return balance.balance;
    }

  


  },[chainId, invokeEndpoint, options.address])


  useEffect(() => {

    const loadBalance = async () => {
      const balance  = await getBalance();
      if(balance) {
        console.log(`got balance useCactusNativeBalance ${(balance)}`)
    
        setBalance(balance);
      }

    }

    loadBalance();

    
   


  },[getBalance, invokeEndpoint, options.address, options.chain])


  return { balance, getBalance };

}


export {
  useChain,
  useMoralis,
  useCactusChain,
  useCactusNativeBalance,
  useWeb3ExecuteFunction,
  useNFTBalances,
  useERC20Balances,
};
