import React from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useNFTDropDownUtils } from "../../components/NFTsDropdown";
import { PrimaryButton } from "../../components/Buttons";
import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { useValidateFormValues } from "../../utils/validation";
export const ImagesToNFTsComponent = () => {
  const { NFTCollectionsDropdown, selectedNFTCollection } =
    useNFTDropDownUtils();

    const placeholder = `
     
    
    [
      {
          "traits": [
              {
                  "trait_type": "image",
                  "value": "https://image1"
              },
              {
                  "trait_type": "backgroundColor",
                  "value": "red"
              }
          ]
      },
      {
          "traits": [
              {
                  "trait_type": "image",
                  "value": "https://image2"
              },
              {
                  "trait_type": "backgroundColor",
                  "value": "red"
              }
          ]
      }
  ]

  `
  const [images, setImages] = React.useState<string>("");
  const [assigningImages, setAssigningImages] = React.useState<boolean>(false);
  const { invokeEndpoint } = useBlockOneEndpoint();

  const {IsJsonString} = useValidateFormValues()

  const assignImages = () => {

    
    let propertyUpdates: any = []
    // alert(IsJsonString(images.trim()))
    if(IsJsonString(images)){
      const parsedTraits = JSON.parse(images)
      console.log(`parsedTraits \n ${JSON.stringify(parsedTraits)}`)
      parsedTraits.forEach((traitObject: any, idx: Number) => {
        traitObject["tokenId"] = idx
       })
       propertyUpdates = parsedTraits

        
    } else {
      const imagesArray = images.split(",").map((x) => x.trim())
      propertyUpdates = imagesArray.map((image, idx) => {


        return {
          tokenId: idx,
          traits: [
            {
              trait_type: "image",
              value: image,
            },
          ],
        };
      });

    }
    
    const endpointData = {
      NFTCollectionAddress: selectedNFTCollection?.contractAddress,
      propertyUpdates,
    };
    invokeEndpoint(
      "nft/batch/update/properties",
      {
        ...endpointData,
      },
      () => {
        setAssigningImages(false);
      }
    );
  };
  return (
    <div>
      <NFTCollectionsDropdown></NFTCollectionsDropdown>
      <div>
        <div>List of traits</div>
        <textarea
          className="h-80 border-2 w-full px-10"
          name="imageList"
          placeholder={placeholder}
          onChange={(event) => {
            const value = event.target.value?.trim();
            setImages(value.trim());
          }}
        />
      </div>
      <div className="flex justify-center mt-10">
        {!assigningImages && (
          <PrimaryButton
            disabled={images.length === 0 || !selectedNFTCollection}
            onClick={() => {
              setAssigningImages(true);
              assignImages();
            }}
          >
            Assign Images to NFT Collection
          </PrimaryButton>
        )}
      </div>
      <div>{assigningImages && <Loading className="h-10 w-10" />}</div>
    </div>
  );
};

export default withAuthenticationRequired(ImagesToNFTsComponent, {
  onRedirecting: () => <Loading />,
});
