import React, { useEffect, useState } from "react";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Form, Input } from "reactstrap";
// import { useBlockOneEndpoint } from "../../utils/blockOneAPI";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";
import { useValidateFormValues } from "../../utils/validation";
import { useHistory } from "react-router-dom";
import { useGasStationUtils } from "../../utils/gasStation";
import { BlockchainAction, NftCollection, Vault } from "../../types/index";
import { PrimaryButton } from "../../components/Buttons";
import Constants from "../../utils/constants";
import { useCreateMarketplace } from "./marketplaceActions";
export const CreateMarketplaceComponent = () => {
  const { listAllForOwner } = useFaunaUtils();
  const { validateFormValues } = useValidateFormValues();

  const history = useHistory();

  const { gasStationStatus } = useGasStationUtils(
    BlockchainAction.CreateMarketplace
  );

  type FormValues = {
    name: string | undefined;
    bannerImageURL: string;
    marketplaceFee: number;
    feeRecipientAddress: string | undefined;
    marketplaceNFTCollections: NftCollection[];
    vaults: Vault[];
    buildTag: string;
  };

  const [formData, updateFormData] = useState<FormValues>({
    name: undefined,
    bannerImageURL: "",
    marketplaceFee: 1,
    feeRecipientAddress: undefined,
    marketplaceNFTCollections: [],
    buildTag: Constants.MARKETPLACE_BUILD_TAG,
    vaults: [],
  });
  const [loading, setLoading] = useState(false);
  const [NFTCollections, setNFTCollections] = useState([]);
  const [vaults, setVaults] = useState<Array<Vault>>();
  const [errors, setErrors] = useState<any>({});
  const { createMarketplace } = useCreateMarketplace(setLoading);

  useEffect(() => {
    const fetchVaults = async () => {
      const vaults = await listAllForOwner(faunaCollections.Vault);
      setVaults(vaults);
    };
    if (!vaults) {
      fetchVaults();
    }
  });

  // const { invokeEndpoint } = useBlockOneEndpoint();

  const isFieldInvalid = (fieldName: string) => {
    return errors[fieldName] != null;
  };

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      const mkts = await listAllForOwner(faunaCollections.NFTCollection);
      setNFTCollections(mkts);
      setLoading(false);
    };
    getMarketPlacesFauna();
  }, [listAllForOwner]);

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    updateFormData({
      ...formData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    formData.feeRecipientAddress = gasStationStatus?.gasAddress;

    const errors = validateFormValues(formData);

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      console.log("error " + JSON.stringify(errors));
      return;
    }

    setLoading(true);

    const data = {
      feeRecipient: formData.feeRecipientAddress,
      marketplaceFee: formData.marketplaceFee,
    };

    const callback = (createdDoc: any) => {
      console.log(`createdDoc ${JSON.stringify(createdDoc)}`);

      setLoading(false);

      history.push(`/admin/marketplace/view/${createdDoc.id}`);
    };
    await createMarketplace(data, formData, callback);
  };

  const renderVaults = () => {
    if (!vaults) {
      return null;
    }

    const dataVaults = formData.vaults;
    const vaultIds = dataVaults.map((x) => x._id);
    //
    const vaultDivs = vaults.map((vault: Vault) => {
      const index = vaultIds.indexOf(vault._id);
      let isChecked = index > -1;

      return (
        <div
          className=	""
          onClick={() => {
            if (isChecked) {
              dataVaults.splice(index, 1);
            } else {
              dataVaults.push(vault);
            }
            updateFormData({
              ...formData,
              vaults: dataVaults,
            });
          }}
        >
          <div>{vault.name}</div>
          <div className="h-44 w-44">
            <img
              src={
                vault.image || ""
              }
              alt="Collection"
            ></img>
          </div>
          <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
            <input
              checked={isChecked}
              type="checkbox"
              name="toggle"
              id="toggle"
              className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
            />
            <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
          </div>
        </div>
      );
    });
    return (
      <div className="grid grid-cols-5"
      
      >
        {vaultDivs}
      </div>
    );
  };
  const renderNFTItems = () => {
    const items = NFTCollections.map((NFTCollection: NftCollection) => {
      const formNFTCollections = formData.marketplaceNFTCollections;
      const index = formNFTCollections.indexOf(NFTCollection);
      let isChecked = index > -1;
      return (
        <div
          className="d-flex justify-content-between align-items-start cursor-pointer	"
          onClick={() => {
            if (isChecked) {
              formNFTCollections.splice(index, 1);
            } else {
              formNFTCollections.push(NFTCollection);
            }
            updateFormData({
              ...formData,
              marketplaceNFTCollections: formNFTCollections,
            });
          }}
        >
          <div>
            <div className="h-44 w-44">
              <img
                src={NFTCollection.collectionImage || ""}
                alt="Collection"
              ></img>
            </div>
            <div className="fw-bold w-44 truncate">{NFTCollection.name}</div>

            <div>
              <div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
                <input
                  checked={isChecked}
                  type="checkbox"
                  name="toggle"
                  id="toggle"
                  className="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
                />
                <label className="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"></label>
              </div>
            </div>
          </div>
        </div>
      );
    });
    return items;
  };

  // const persistCreatedMarketplace = async (contractAddress) => {

  //   setLoading(false);

  //   history.push(`/admin/marketplace/view/${createdDoc.id}`);
  // };

  const renderSubmitButton = () => {
    if (!gasStationStatus) {
      return (
        <div>
          <Loading
            className={"h-3 w-12 -mt-10"}
            message="Estimating blockchain gas usage"
          ></Loading>
        </div>
      );
    }

    return (
      <div className="">
        <div className="flex justify-center mt-2">
          <PrimaryButton disabled={!gasStationStatus?.hasEnoughGas}>
            Create
          </PrimaryButton>
        </div>

        <div className="flex justify-center my-2">
          Estimated Gas
          {" " +
            String(gasStationStatus?.formatedGasRequired) +
            " " +
            gasStationStatus?.nativeToken}
        </div>
      </div>
    );
  };
  if (loading) {
    return <Loading></Loading>;
  }

  return (
    <div className="">
      <div className=" text-center	w-full text-lg">Create Marketplace</div>

      <Form
        onSubmit={(event) => {
          handleSubmit(event);
        }}
      >
        <div className="grid gap-4 grid-cols-2">
          <div>
            <div>Marketplace Name</div>
            <Input
              invalid={isFieldInvalid("name")}
              onChange={handleChange}
              type="text"
              name="name"
              placeholder="Marketplace Name"
            />
          </div>

          <div>
            <div>Banner URL</div>
            <Input
              invalid={isFieldInvalid("bannerImageURL")}
              onChange={handleChange}
              name="bannerImageURL"
              type="text"
              placeholder="TKN"
            />
          </div>
        </div>

        <div className="grid gap-4 grid-cols-4">
          <div className="col-span-4">
            <div>Custom Stylesheet URL</div>
            <Input
              onChange={handleChange}
              name="customStyleSheetURL"
              type="text"
              placeholder="TKN"
            />
          </div>

          <div className="col-span-2 hidden">
            <div>Fee Recipient Address</div>

            <Input
              invalid={isFieldInvalid("feeRecipientAddress")}
              onChange={handleChange}
              name="feeRecipientAddress"
              value={gasStationStatus?.gasAddress}
              type="text"
              placeholder="10"
            />
          </div>

          <div className="hidden">
            <div>Marketplace fee % (can't be zero)</div>
            <Input
              invalid={isFieldInvalid("marketplaceFee")}
              onChange={handleChange}
              name="marketplaceFee"
              type="number"
              value={1}
              placeholder="10"
            />
          </div>
        </div>

        <div>
          <div>Webhook URL</div>
          <Input onChange={handleChange} name="webhookURL" type="text" />
        </div>

        <div>
          <div className=" text-center	w-full text-lg my-4">
            NFT Collections In Market
          </div>

          <div className="grid grid-cols-5">{renderNFTItems()}</div>
        </div>

        <div>
          <div className=" text-center	w-full text-lg my-4">Vaults</div>

          <div className="">{renderVaults()}</div>
        </div>

        {renderSubmitButton()}
      </Form>

      <hr />
    </div>
  );
};

export default withAuthenticationRequired(CreateMarketplaceComponent, {
  onRedirecting: () => <Loading />,
});
