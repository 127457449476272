import React, { useState, useEffect, useContext } from "react";
import { Container, Row } from "reactstrap";

import Loading from "../../components/Loading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useFaunaUtils } from "../../utils/faunaClient";
import { faunaCollections } from "../../utils/faunaCollections";

import { UserContext } from "../../context/userContext";

export const OrganizationViewComponent = () => {
  const userContext = useContext(UserContext);

  const organizationId = userContext.organizationId;

  const { findDocumentById } = useFaunaUtils();

  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState();
  const [showKey, setShowKey] = useState(false);

  useEffect(() => {
    const getMarketPlacesFauna = async () => {
      console.log("getMarketPlacesFauna start");
      const organization = await findDocumentById(
        faunaCollections.Organization,
        organizationId
      );

      setOrganization(organization);
      setLoading(false);
    };
    getMarketPlacesFauna();
  }, [organizationId, findDocumentById]);

  if (loading) {
    return <Loading></Loading>;
  }

  const renderKey = () => {
    if (showKey) {
      return organization.secretKey;
    } else {
      return (
        <button type="button" class="btn btn-primary">
          <div
            onClick={() => {
              setShowKey(!showKey);
            }}
          >
            click to show / hide
          </div>
        </button>
      );
    }
  };
  return (
    <Container>
      <Row>
        {/* <h2> Organization</h2> */}
        <div className="grid grid-cols-5 w-full">
          <div className="col-span-3">Name</div>
          <div className="col-span-2" >{organization.name}</div>
          <div className="col-span-3">Secret Key</div>
          <div className="col-span-2">{renderKey()}</div>


        </div>

      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(OrganizationViewComponent, {
  onRedirecting: () => <Loading />,
});
