import { useContext, useEffect, useState } from "react";

import { faunaCollections } from "../utils/faunaCollections";
import { useFaunaUtils } from "../utils/faunaClient";
import { UserContext } from "../context/userContext";

const useOnboardingActions = () => {
  const userContext = useContext(UserContext);

  const { updateDocument, listAllForOwner } = useFaunaUtils();
  const [onboardingId, setOnboardingId] = useState();
  const [status, setStatus] = useState<String>();
  const [loaded, setLoaded] = useState<Boolean>(false);

  useEffect(() => {
    const retrieveOnboardingId = async () => {
      const onboardings = await listAllForOwner(faunaCollections.Onboarding);
      const onboarding = onboardings[0];
      console.log(`onboarding1 ${JSON.stringify(onboarding)}`);
      setOnboardingId(onboarding.id);
      setStatus(userContext.onboarding.status);
      setLoaded(true);
    };

    if (!onboardingId) {
      retrieveOnboardingId();
    }
  }, [onboardingId, userContext.onboarding.status, listAllForOwner]);

  const updateOnboardingStatus = async (nextStatus: String) => {
    if(!onboardingId){
      console.warn(` updateOnboardingStatus not onboardingId`);
      return;
    }
    await updateDocument(
      faunaCollections.Onboarding,
      {
        status: nextStatus,
        // status:OnboardingStatus.DemoSaasCreateNonFungibleToken
      },
      onboardingId
    );
    setStatus(nextStatus);
  };

  return { updateOnboardingStatus, status, onboardingId, loaded };
};

export { useOnboardingActions };
