import React, { useContext, useState } from "react";
import NavBar from "../components/NavBar";

import { Route } from "react-router-dom";
import Profile from "./Profile";

import { CreateBasicNFTComponent } from "./NFTs/CreateCommonNFT";
import { CreateLiquidityTokenComponent } from "./ERC20/CreateLiquidityToken";

import { CreateBasicERC20TokenComponent } from "./ERC20/CreateBasicERC20Token";

import history from "../utils/history";

import { SideMenu } from "./menu/SideMenu";
import { CreateMarketplaceComponent } from "./marketplace/CreateMarketplace";
import { ListMarketplacesComponent } from "./marketplace/ListMarketplaces";
import { MarketplaceViewComponent } from "./marketplace/MarketplaceView";
import { MarketplaceEditComponent } from "./marketplace/MarketplaceEdit";

// import { CreateBasicERC20Component } from "./../views/ERC20/CreateBasicToken";
import { Router, Switch } from "react-router-dom";
import { HomeDetails } from "./home/HomeDetails";
import { ListNFTsComponent } from "./NFTs/ListNFTs";
import { NFTViewComponent } from "./NFTs/NFTView";
import { NFTEditComponent } from "./NFTs/NFTEdit";

import { GenerateArt } from "./NFTs/GenerateArt";

import { ERC20ViewComponent } from "./ERC20/ERC20View";

import { ERC20ActionsComponent } from "./ERC20/ERC20Actions";

import { ListERC20TokensComponent } from "./ERC20/ListERC20Tokens";
import { ApiSpecViewComponent } from "./apiSpec/ApiSpecView";
import { OrganizationViewComponent } from "./organization/OrganizationView";
import OnboardingModal from "../components/OnboardingModal";
import { UserContext } from "../context/userContext";
import { OnboardingStatus } from "../types";
import Onboarding from "./onboarding/Onboarding";
import CreateDemoSaas from "./onboarding/demo/saas/CreateDemoSaas";
import Backoffice from "./backoffice/Backoffice";
import { ImagesToNFTsComponent } from "./NFTs/ImagesToNFTs";
import { NFTMintComponent } from "./NFTs/NFTMint";
import { CreateVaultComponent } from "./vaults/CreateVault";
import { ViewVaultComponent } from "./vaults/ViewVault";
import {VaultListComponent} from "./vaults/VaultList";
import { CreateNFTAccessComponent } from "./onboarding/demo/access/CreateNFTAccess";
import { ImportERC20Componenet } from "./ERC20/importERC20";

const Home = () => {
  const userContext = useContext(UserContext);

  const [isOnboardingDone, setIsOnboardingDone] = useState(false);

  const setDoneOnboarding = () => {
    setIsOnboardingDone(true);
  };

  if (
    !isOnboardingDone &&
    userContext.onboarding.status !== OnboardingStatus.Done
  ) {
    return (
      <div>
        <Router history={history}>
          {/* <Onboarding/> */}
          <Switch>
            <Route
              path="/"
              exact
              render={(props) => (
                <Onboarding setOnboardingDone={setDoneOnboarding} {...props} />
              )}
            />
            <Route
              path="/demosaas"
              render={(props) => (
                <CreateDemoSaas
                  setOnboardingDone={setDoneOnboarding}
                  {...props}
                />
              )}
            />
            <Route
              path="/nftAccess"
              render={(props) => (
                <CreateNFTAccessComponent
                />
              )}
            />

            <Route path="/backoffice" component={Backoffice} />
          </Switch>
        </Router>
      </div>
    );
  }

  return (
    <Router history={history}>
      <div id="app" className="">
        <div className="h-screen grid grid-cols-12">
          <div className="w-40 col-span-2">
            <SideMenu></SideMenu>
          </div>
          <div className="col-span-10 ml-4">
            <NavBar />

            <OnboardingModal></OnboardingModal>

            <div className="px-4">
              <Switch>
                <Route path="/" exact component={HomeDetails} />
                <Route path="/backoffice" component={Backoffice} />

                <Route path="/profile" component={Profile} />

                <Route
                  path="/admin/createNFT"
                  component={CreateBasicNFTComponent}
                />
                <Route
                  path="/admin/createLiquidityToken"
                  component={CreateLiquidityTokenComponent}
                />

                <Route
                  path="/admin/createBasicERC20Token"
                  component={CreateBasicERC20TokenComponent}
                />

                <Route
                  path="/admin/createMarketplace"
                  component={CreateMarketplaceComponent}
                />

                <Route
                  path="/admin/marketplace/list"
                  component={ListMarketplacesComponent}
                />

                <Route
                  path="/admin/nft/list"
                  component={ListNFTsComponent}
                />

                <Route
                  path="/admin/erc20/list"
                  component={ListERC20TokensComponent}
                />

                <Route
                  path="/admin/erc20/import"
                  component={ImportERC20Componenet}
                />

                <Route
                  path="/admin/nft/view/:NFTCollectionId"
                  component={NFTViewComponent}
                />

                <Route
                  path="/admin/nft/edit/:NFTCollectionId"
                  component={NFTEditComponent}
                />

                <Route
                  path="/admin/erc20/view/:tokenId"
                  component={ERC20ViewComponent}
                />

                <Route
                  path="/admin/erc20/actions/:tokenId"
                  component={ERC20ActionsComponent}
                />

                <Route
                  path="/admin/marketplace/view/:marketplaceId"
                  component={MarketplaceViewComponent}
                />

                <Route
                  path="/admin/marketplace/edit/:marketplaceId"
                  component={MarketplaceEditComponent}
                />

                <Route
                  path="/admin/apiSpec"
                  component={ApiSpecViewComponent}
                />

                <Route
                  path="/admin/organization"
                  component={OrganizationViewComponent}
                />

                <Route
                  path="/admin/GenerateArt"
                  component={GenerateArt}
                />

                <Route
                  path="/admin/nft/addImages"
                  component={ImagesToNFTsComponent}
                />

                <Route
                  path="/admin/nft/mint"
                  component={NFTMintComponent}
                />
                    <Route
                  path="/admin/vaults/create"
                  component={CreateVaultComponent}
                />
                 <Route
                  path="/admin/vaults/view/:vaultId"
                  component={ViewVaultComponent}
                />


<Route
                  path="/admin/vaults/list"
                  component={VaultListComponent}
                />

{/* CreateVaultComponent */}
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Home;
