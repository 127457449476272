import React, { useEffect, useState } from "react";

import { useERC20TokenActions } from "../../../../../hooks/ERC20Actions";
import { useFaunaUtils } from "../../../../../utils/faunaClient";
import { useGasCheckedComponents } from "../../../../../components/GasCheckedButton";
import { BlockchainAction } from "../../../../../types";
import TreasurySetup from "../../../TreasurySetup";

export const CreateFungibleToken = ({ setNextStatus }) => {
  const { createERC20FromTreasury, createdERC20Token, creatingToken } =
    useERC20TokenActions();
    const { GasCheckedButton } = useGasCheckedComponents(BlockchainAction.CreateMarketplace)

  const [showFundTreasury, setShowFundTreasury] = useState(false);

  const { updateDocument } = useFaunaUtils();
  const [tokenData, setTokenData] = useState({
    name: "Note",
    symbol: "NOTE",
  });

  useEffect(() => {
    if (createdERC20Token) {
      setNextStatus();
    }
  }, [createdERC20Token, updateDocument, setNextStatus]);

  const updateTokenDetails = async (e) => {
    console.log(`e.target.name ${e.target.name}`);
    setTokenData({
      ...tokenData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  const renderCreateButton = () => {

    return (
      <div className="m-3 grid justify-center">
        <GasCheckedButton
          loading={creatingToken}
          onClick={() => {
            createERC20FromTreasury({
              name: tokenData.name,
              symbol: tokenData.symbol,
            });
          }}
          buttonText={`Create ${tokenData.symbol} fungible token`}
          noGasCallToAction={() => {
            return (
              <div
                className="flex justify-center cursor-pointer"
                onClick={() => {
                  setShowFundTreasury(true);
                }}
              >
                Fund your treasury
              </div>
            );
          }}
        />
      </div>
    );
  };

  if (showFundTreasury) {
    return (
      <div>
        <div class="w-full py-6 mt-14"></div>
        <div className=" flex place-content-center">
          <div className="border w-1/2 rounded-lg h-96 text-center p-2">
            <TreasurySetup></TreasurySetup>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-row min-h-screen justify-center items-center">
        <div className="h-96 rounded overflow-hidden shadow-lg w-8/12 ">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2 text-center">
              Create Fungible Token
            </div>
            <p className="text-gray-700 text-base text-center">
              Fungible tokens are used as <span className="font-bold">currencies</span> on the blockchain
            </p>
            <div>
              <div className=" mt-3 grid grid-flow-col auto-cols-max gap-20 justify-center">
                <div class="mb-4">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="name"
                  >
                    Name
                  </label>
                  <input
                    onChange={updateTokenDetails}
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="name"
                    type="text"
                    placeholder="Note"
                  />
                </div>
                <div class="mb-6">
                  <label
                    class="block text-gray-700 text-sm font-bold mb-2"
                    for="password"
                  >
                    Symbol
                  </label>
                  <input
                    onChange={updateTokenDetails}
                    class="shadow appearance-none border border-red-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                    name="symbol"
                    placeholder="NOTE"
                  />
                </div>
              </div>

              <div class="flex items-center justify-between"></div>

              <div>{/* {JSON.stringify(createdERC20Token)} */}</div>
            </div>
            {renderCreateButton()}
          </div>
        </div>
      </div>
    </div>
  );
};
